import React, { useEffect, useState } from 'react';
import './App.css';
import DashboardHome from './components/home/index';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Projectview from './components/Projectview/index';
import NewSiteForm from './components/NewSiteForm/index';
import NewProjectForm from './components/NewProjectForm/index';
import Scenarios from './components/Scenarios/index';
import ScenariosForm from './components/ScenariosForm/index';
import Optimization from './components/Optimization/index';
import Library from './components/Library/index';
import Contacts from './components/Contacts/index';
import ViewContact from './components/Contacts/ViewContact';
import ContactsForm from './components/ContactsForm/index';
import Userprofile from './components/Userprofile/index';
import WebsiteHome from './Website/Home/Index';
import AuthHome from './Website/Auth/Index';

// import Login from './Website/Login/Login';
// import Signup from './Website/Signup/Signup';
import Join from './Website/Join/Join';
// import Forgotpassword from './Website/Forgotpassword/Forgotpassword';
// import Emailsent from './Website/ForgotpasswordEmailsent/Emailsent';
// import Resetpassword from './Website/Resetpassword/Resetpassword';

import Deliverables from './components/Deliverables/index';
import BasicDesign from './components/BasicDesign/index';
import BasicDesignNew from './components/BasicDesignNew';

import BasisOfDesignFrame from './components/BasisOfDesign';

import DocumentOverview from './components/BasisOfDesign/DocumentOverview';
import SiteDiscussion from './components/BasisOfDesign/SiteDiscussion';
import Setbackasumption from './components/BasisOfDesign/SetbackAsumption';
import ElectricSizing from './components/BasisOfDesign/ElectricSizing';
import DesignAssupmtion from './components/BasisOfDesign/DesignAssupmtion';
import CivilDesign from './components/BasisOfDesign/CivilDesign';
import TemperatureDesign from './components/BasisOfDesign/TemperatureDesign';
import DesignDeviations from './components/BasisOfDesign/DesignDeviations';
import MonitoringOverview from './components/BasisOfDesign/MonitoringOverview';
import DesignLife from './components/BasisOfDesign/DesignLife';
import ShadingAnalysis from './components/BasisOfDesign/ShadingAnalysis';

import Preview from './components/Preview/Index';
import EditDocuments from './components/EditDoc/Index';

import ExportData from './components/ExportData/index';

import ViewBatteryProducts from './components/Library/ViewBatteryProducts';
import ViewChargecontrollerProducts from './components/Library/ViewChargecontrollerProducts';
import ViewGeneratorProducts from './components/Library/ViewGeneratorProducts';
import ViewInverterProducts from './components/Library/ViewInverterProducts';
import ViewMountingProducts from './components/Library/ViewMountingProducts';
import ViewSolarProducts from './components/Library/ViewSolarProducts';

import ViewUtilities from './components/Library/ViewUtilities';

import PreviewBatterySingleProduct from './components/Library/PreviewBatterySingleProduct';
import PreviewChargecontrollerSingleProduct from './components/Library/PreviewChargecontrollerSingleProduct';
import PreviewGeneratorSingleProduct from './components/Library/PreviewGeneratorSingleProduct';
import PreviewInverterSingleProduct from './components/Library/PreviewInverterSingleProduct';
import PreviewMountingSingleProduct from './components/Library/PreviewMountingSingleProduct';
import PreviewSolarSingleProduct from './components/Library/PreviewSolarSingleProduct';

import { useSelector, useDispatch } from 'react-redux';
import { setUserMiniProfile } from "./redux/slices/userSlice";

import { ScenarioContext } from "./utils/Context";
import Config from './utils/Config';

import swal from "sweetalert";


import { setUploading, setUploadProgress, setUploadReport, setHomer } from "./redux/slices/helperSlice";

function App () {
  const dispatch = useDispatch();
  const user     = useSelector((state) => state.user.profile);
  // const { uploading, uploadProgress, uploadReport } = useSelector((state) => state.helper);
  
  const [ loaded, setLoaded ] = useState(false);
  
  // const [ uploading, setUploading ] = React.useState(false);
  // const [ uploadProgress, setUploadProgress ] = React.useState(0);
  // const [ uploadReport, setUploadReport ] = React.useState("");

  useEffect(() => {
    if (!user.id && localStorage.getItem('user')) {

      const data = JSON.parse(localStorage.getItem('user'));

      if (data.first_name?.length && data?.last_name?.length) {
        dispatch(setUserMiniProfile(data));
      }
    }

    setTimeout(() => setLoaded(true), 500); 
  }, [ user.id, dispatch ]);

  const scenarioContext = React.useMemo(() => ({
    uploadHomerAsyn: async (formData, user) => {
      console.log("Uploading your HOMER file...");
      
      dispatch(setUploading(true));
      dispatch(setUploadReport("Uploading your HOMER file..."));

      // Create a new XMLHttpRequest
      const xhr = new XMLHttpRequest();

      // Event listener for upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round(
            (event.loaded / event.total) * 100
          );
          console.log(`Upload progress: ${percentComplete}%`);
          // Update progress state if you want to display it in the UI
          dispatch(setUploadProgress(percentComplete));

          // 0%: Uploading your HOMER file...
          // 30%: Extracting relevant data from the microgrid simulation results...
          // 60%: Aggregating data into hourly and monthly charts...
          // 95%: Almost done, finalizing the process.. (edited)

          if (percentComplete >= 0 && percentComplete < 30) {
            dispatch(setUploadReport("Uploading your HOMER file..."));
          } else if (percentComplete >= 30 && percentComplete < 60) {
            dispatch(setUploadReport("Extracting relevant data from the microgrid simulation results..."));
          } else if (percentComplete >= 60 && percentComplete < 95) {
            dispatch(setUploadReport("Aggregating data into hourly and monthly charts..."));
          } else {
            dispatch(setUploadReport("Upload Complete! You may proceed now..."));
          }
        }
      };

      // Event listener for upload completion
      xhr.onload = () => {
        if (xhr.status === 200) {
          const res = JSON.parse(xhr.responseText);
          console.log("upload-res:", res);
          if (res.status) {
            dispatch(setHomer(res.data));
            // props.onReceiveHomer(res.data);
            // navigate(`/scenarios/view/${site_id}`);
          } else {
            swal("Oh no!", res.message, "error");
          }
        } else if (xhr.status === 401) {
          console.log("401 Error: Unauthenticated");
          // localStorage.removeItem("user");
          // navigate("/");
        } else {
          swal("Oh no!", "An error occurred during the upload.", "error");
        }
        dispatch(setUploading(false));
      };

      // Event listener for upload error
      xhr.onerror = () => {
        console.log("Error: ", xhr.statusText);
        dispatch(setUploading(false));
        swal("Oh no!", "An error occurred while connecting to the server. Please try again", "error");
      };

      // Open the connection and send the formData
      xhr.open("POST", `${Config.API.URL}/system-optimization/upload-homer-file`, true);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.setRequestHeader("Authorization", `Bearer ${user.token}`);
      xhr.send(formData);
    }
  }), []);


  if (!loaded) {
    return <p>Loading...</p>
  }

  return (
    <ScenarioContext.Provider value={scenarioContext}>
      { /*uploading ?
        <div 
          style={{ 
            position: "absolute", 
            top: 5, 
            right: 10, 
            minWidth: 100, 
            height: 50, 
            backgroundColor: 'white', 
            zIndex: 2, 
            borderRadius: 10, 
            display: "flex", 
            alignItems: "center", 
            paddingLeft: 15, 
            paddingRight: 15,
            borderWidth: 2,
            borderColor: 'green',
            fontSize: 12,
            fontWeight: "bold"
          }}
        >
          {`${uploadProgress}% - ${uploadReport}`}
        </div>
      : null*/ }
      <Router>
        <Routes>
          {/* Website page routing started */}
          <Route exact path="/" element={<WebsiteHome />} />
          <Route exact path="/auth" element={<AuthHome />} />
          <Route exact path="/join" element={<Join />} />
          {/* 
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} /> 
          */}
          {/* <Route exact path="/emailsent" element={<Emailsent />} /> */}
					{/* 
            <Route path="/forgot/password" element={<Forgotpassword />} />
            <Route path="/reset/password" element={<Resetpassword />} /> 
          */}
          {/* Website page routing ended */}

          {/* Dashboard pages rounting started */}
          <Route exact path="/dashboard" element={<DashboardHome />} />

          <Route path="/project/create" element={<NewProjectForm/>} />
          <Route path="/project/view/:id" element={<Projectview/>} />
          <Route path="/site/create/:project_id" element={<NewSiteForm/>} />
          <Route path="/scenarios/view/:site_id" element={<Scenarios/>} />
          <Route path="/scenarios/create/:site_id" element={<ScenariosForm/>} />
          <Route path="/scenarios/optimization/:project_id/:scenario_id" element={<Optimization />} />

          <Route path="/library" element={<Library />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/contact/create" element={<ContactsForm />} />
          <Route path="/contact/view/:contact_id" element={<ViewContact />} />
          <Route path="/profile" element={<Userprofile />} />

          <Route path="/deliverables" element={<Deliverables />} />
          <Route path="/basis/design/:site_id" element={<BasicDesign />} />
          <Route path="/basis/design/create/:project_id/:site_id" element={<BasicDesignNew />} />

          <Route path="/basis" element={<BasisOfDesignFrame />}>
            <Route path="/basis/document/overview/:site_id/:design_id" element={<DocumentOverview />} />
            <Route path="/basis/site/discussion/:site_id/:design_id" element={<SiteDiscussion />} />
            <Route path="/basis/setback/asumption/:site_id/:design_id" element={<Setbackasumption />} />
            <Route path="/basis/electric/sizing/:site_id/:design_id" element={<ElectricSizing />} />
            <Route path="/basis/design/assupmtion/:site_id/:design_id" element={<DesignAssupmtion />} />
            <Route path="/basis/civil/design/:site_id/:design_id" element={<CivilDesign />} />
            <Route path="/basis/temperature/design/:site_id/:design_id" element={<TemperatureDesign />} />
            <Route path="/basis/design/deviations/:site_id/:design_id" element={<DesignDeviations />} />
            <Route path="/basis/monitoring/overview/:site_id/:design_id" element={<MonitoringOverview />} />
            <Route path="/basis/design/life/:site_id/:design_id" element={<DesignLife />} />
            <Route path="/basis/shading/analysis/:site_id/:design_id" element={<ShadingAnalysis />} />
          </Route>

          <Route path="/preview/doc/:site_id/:design_id" element={<Preview />} />
          <Route path="/view/doc/:site_id/:design_id" element={<EditDocuments />} />
          {/* <Route path="/edit/doc/:site_id/:design_id" element={<EditDocuments />} /> */}

          <Route path="/export/data" element={<ExportData />} />
          {/* <Route path="/export/data/:site_id" element={<ExportData />} /> */}

          <Route path="/view/battery/products" element={<ViewBatteryProducts />} />
          <Route path="/view/chargecontroller/products" element={<ViewChargecontrollerProducts />} />
          <Route path="/view/generator/products" element={<ViewGeneratorProducts />} />
          <Route path="/view/inverter/products" element={<ViewInverterProducts />} />
          <Route path="/view/mounting/products" element={<ViewMountingProducts />} />
          <Route path="/view/solar/products" element={<ViewSolarProducts />} />
          
          <Route path="/view/utilities" element={<ViewUtilities />} />
          
          <Route path="/preview/battery/single/product/:product_id" element={<PreviewBatterySingleProduct />} />
          <Route path="/preview/chargecontroller/single/product/:product_id" element={<PreviewChargecontrollerSingleProduct />} />
          <Route path="/preview/generator/single/product/:product_id" element={<PreviewGeneratorSingleProduct />} />
          <Route path="/preview/inverter/single/product/:product_id" element={<PreviewInverterSingleProduct />} />
          <Route path="/preview/mounting/single/product/:product_id" element={<PreviewMountingSingleProduct />} />
          <Route path="/preview/solar/single/product/:product_id" element={<PreviewSolarSingleProduct />} />
          
          {/* Dashboard pages routing ended */}
        </Routes>
      </Router>

    </ScenarioContext.Provider>
  );
}

export default App;

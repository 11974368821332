import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import { Listbox, Dialog, Transition } from "@headlessui/react";
import Dropdownproject from "./Dropwdownpro";
import Config from "../../utils/Config";

import Dilogbox from "../NewProjectForm/Dilogbox";
import UtilityDilogbox from "../NewProjectForm/UtilityDilogbox";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import swal from "sweetalert";

// Contact table code goes here
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import {
  FaSearch,
  FaChevronDown,
  FaCheck,
  FaChevronLeft,
  FaChevronRight,
  FaSortUp,
  FaSortDown,
} from "react-icons/fa";

export default function Projectinformation(props) {
  const navigate = useNavigate();
  const [project, setProject] = React.useState({ ...props.project });
  const [projectServices, setProjectServices] = React.useState([]);

  const [ editProjectInformationModel, setEditProjectInformationModel ] = useState(false);

  const [ projectName, setProjectName ] = React.useState("");
  const [ projectSummary, setProjectSummary ] = React.useState("");
  const [ projectContacts, setProjectContacts ] = React.useState([]);
  const [ projectType, setProjectType ] = React.useState("");
  // const [ utilityName, setUtilityName ] = React.useState("");
  const [ utilityId, setUtilityId ] = React.useState(0);
  const [ contractGFCDate, setContractGFCDate ] = React.useState("");
  const [ customerType, setCustomerType ] = React.useState("");
  const [ projectBudget, setProjectBudget ] = React.useState(0);
  const [ contactValue, setContactValue ] = React.useState(0);
  const [ contractDate, setContractDate ] = React.useState("");
  const [ projectNotes, setProjectNotes ] = React.useState("");
  const [ contractNumber, setContractNumber ] = React.useState("");
  const [ PMNumber, setPMNumber ] = React.useState("");
  const [ PONumber, setPONumber ] = React.useState("");
  const [ projectStatus, setProjectStatus ] = React.useState("");
  
  const [ projectUtilities, setProjectUtilities ] = React.useState([]);

  const cancelButtonRef = useRef(null);

  const [ loading, setLoading ] = React.useState(false);

  const user = useSelector((state) => state.user.profile);

  useEffect(() => {
    setProject(props.project);
    // console.log("project:", props.project)
    const services = props.project?.project_services
      ? [...props.project?.project_services]
      : [];
    for (let i = 0; i < services.length; i++) {
      let service = { ...services[i] };
      let key = Object.keys(service)[0];

      service.checked = service[key];
      service.title = key
        .replace(/_/g, " ")
        .replace(/(?: |\b)(\w)/g, (key) => key.toUpperCase());

      services[i] = service;
    }
    setProjectServices(services);

    setProjectName(props.project?.project_name);
    setProjectSummary(props.project?.project_summary);
    setProjectContacts(props.project?.contacts);
    setProjectType(props.project?.project_type);
    // setUtilityName(props.project?.utility_name);
    
    setUtilityId(props.project?.utility_id);
    setProjectUtilities([ props.project?.utility ]);

    // console.log('props.project?.utility:', props.project?.utility);

    setContractGFCDate(props.project?.contract_gfc_date ? props.project?.contract_gfc_date : '');
    setCustomerType(props.project?.customer_type);
    setProjectBudget(props.project?.project_budget);
    setContactValue(props.project?.contract_value);
    setContractDate(props.project?.contract_date);
    setProjectNotes(props.project?.project_notes);
    setContractNumber(props.project?.contract_number ? props.project?.contract_number : '');
    setPMNumber(props.project?.pm_number ? props.project?.pm_number : '');
    setPONumber(props.project?.po_number ? props.project?.po_number : '');
    setProjectStatus(props.project?.status);
  }, [props.project]);

  const editProjectInformation = () => {
    if (projectContacts.length && projectName.length && projectSummary.length) {
      setLoading(true);
      const project_contacts = [];
      for (let i = 0; i < projectContacts.length; i++) {
        const contact = projectContacts[i];
        project_contacts.push(contact.id);
      }
      const payload = {
        project_name: projectName,
        project_summary: projectSummary,
        project_contacts: project_contacts,
        // project_type: projectType,
        utility_id: utilityId,
        //utility_name: utilityName,
        contract_gfc_date: contractGFCDate,
        customer_type: customerType,
        project_budget: projectBudget,
        contract_value: contactValue,
        contract_date: contractDate,
        project_notes: projectNotes,

        contract_number: contractNumber,
        pm_number: PMNumber,
        po_number: PONumber,
        // status: projectStatus,
      };

      fetch(`${Config.API.URL}/projects/${project.id}`, {
        method: "PATCH",
        headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);
          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");

            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setEditProjectInformationModel(false);
            setProject(res.data);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };
  const _onReceiveContact = (contacts) => {
    setProjectContacts(contacts);
  };
  
  const _onReceiveUtility = (utilities) => {
    setProjectUtilities(utilities);
    setUtilityId(utilities?.length ? utilities[0]?.id : 0);
  };

  /************************************ Adding a new Project Contacts Table **********************************************/
  function Avatar({ src, alt = "avatar" }) {
    return (
      <img src={src} alt={alt} className="w-8 h-8 rounded-full object-cover" />
    );
  }
  const generateData = () =>
    project?.contacts
      ? [...Array(project?.contacts?.length)].map((contact, i) => ({
          name: `${project.contacts[i].first_name} ${project.contacts[i].last_name}`,
          image: `https://ui-avatars.com/api/?name=${project.contacts[i].first_name}+${project.contacts[i].last_name}`,
          title: project.contacts[i].title,
          company: project.contacts[i].company,
          type: project.contacts[i].type,
          email: project.contacts[i].email,
          phone: project.contacts[i].phone_number,
        }))
      : [];

  const getColumns = () => [
    {
      Header: "Name",
      accessor: "name",
      width: "300px",
      Cell: ({ row, value }) => {
        return (
          <div className="flex gap-2 items-center">
            <Avatar src={row.original.image} alt={`${value}'s Avatar`} />
            <div>{value}</div>
          </div>
        );
      },
    },
    {
      Header: "Job Title",
      accessor: "title",
    },
    {
      Header: "Company",
      accessor: "company",
    },
    {
      Header: "Contact Type",
      accessor: "type",
    },
    {
      Header: "Email Address",
      accessor: "email",
    },
    {
      Header: "Phone Number",
      accessor: "phone",
    },
  ];

  function InputGroup7({
    label,
    name,
    value,
    onChange,
    type = "text",
    decoration,
    className = "",
    inputClassName = "",
    decorationClassName = "",
    disabled,
  }) {
    return (
      <div
        className={`flex flex-row-reverse f-f-r items-stretch w-full rounded-xl overflow-hidden bg-white shadow-[0_4px_10px_rgba(0,0,0,0.03)] ${className}`}
      >
        <input
          id={name}
          name={name}
          value={value}
          type={type}
          placeholder={label}
          aria-label={label}
          onChange={onChange}
          className={`peer block w-full f-f-r p-3 text-gray-600 focus:outline-none focus:ring-0 appearance-none ${
            disabled ? "bg-gray-200" : ""
          } ${inputClassName}`}
          disabled={disabled}
        />
        <div
          className={`flex f-f-r items-center pl-3 py-3 text-gray-600 ${
            disabled ? "bg-gray-200" : ""
          } ${decorationClassName}`}
        >
          {decoration}
        </div>
      </div>
    );
  }

  function GlobalSearchFilter1({
    globalFilter,
    setGlobalFilter,
    className = "",
  }) {
    return (
      <InputGroup7
        name="search"
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        label="Search"
        decoration={<FaSearch size="1rem" className="text-gray-400 f-f-r" />}
        className={className}
      />
    );
  }

  function SelectMenu1({
    value,
    setValue,
    options,
    className = "cursor-pointer f-f-r",
    disabled,
  }) {
    const selectedOption = useMemo(
      () => options.find((o) => o.id === value),
      [options, value]
    );
    return (
      <Listbox value={value} onChange={setValue} disabled={disabled}>
        <div className={`relative f-f-r w-full cursor-pointer ${className}`}>
          <Listbox.Button
            className={`relative f-f-r w-full cursor-pointer  rounded-xl py-3 pl-3 pr-10 text-base text-gray-700 text-left shadow-[0_4px_10px_rgba(0,0,0,0.03)] focus:outline-none
            ${
              disabled
                ? "bg-gray-200 cursor-not-allowed"
                : "bg-white cursor-default"
            }
          
          `}
          >
            <span className="block f-f-r truncate">
              {selectedOption.caption}
            </span>
            <span className="pointer-events-none f-f-r absolute inset-y-0 right-0 flex items-center pr-2">
              <FaChevronDown
                size="0.80rem"
                className="text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute f-f-r cursor-pointer z-10 mt-1 max-h-60 w-full overflow-auto rounded-xl bg-white text-base shadow-[0_4px_10px_rgba(0,0,0,0.03)] focus:outline-none">
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) =>
                    `relative cursor-default f-f-r select-none py-3 pl-10 pr-4 ${
                      active ? "bg-red-100" : ""
                    }`
                  }
                  value={option.id}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.caption}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-red-400">
                          <FaCheck size="0.5rem" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    );
  }

  function Button2({ content, onClick, active, disabled }) {
    return (
      <button
        className={`flex flex-col f-f-r cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
        ${active ? "bg-red-500 text-white" : "text-red-500"}
        ${
          !disabled
            ? "bg-red hover:bg-red-500 hover:text-white"
            : "text-red-300 bg-white cursor-not-allowed"
        }
        `}
        onClick={onClick}
        disabled={disabled}
      >
        {content}
      </button>
    );
  }

  function PaginationNav1({
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
  }) {
    const renderPageLinks = useCallback(() => {
      if (pageCount === 0) return null;
      const visiblePageButtonCount = 3;
      let numberOfButtons =
        pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
      const pageIndices = [pageIndex];
      numberOfButtons--;
      [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
        const pageNumberBefore = pageIndices[0] - 1;
        const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
        if (
          pageNumberBefore >= 0 &&
          (itemIndex < numberOfButtons / 2 || pageNumberAfter > pageCount - 1)
        ) {
          pageIndices.unshift(pageNumberBefore);
        } else {
          pageIndices.push(pageNumberAfter);
        }
      });
      return pageIndices.map((pageIndexToMap) => (
        <li key={pageIndexToMap}>
          <Button2
            content={pageIndexToMap + 1}
            onClick={() => gotoPage(pageIndexToMap)}
            active={pageIndex === pageIndexToMap}
          />
        </li>
      ));
    }, [pageCount, pageIndex]);
    return (
      <ul className="flex gap-2">
        <li>
          <Button2
            content={
              <div className="flex ml-1">
                <FaChevronLeft size="0.6rem" />
                <FaChevronLeft size="0.6rem" className="-translate-x-1/2" />
              </div>
            }
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
        </li>
        {renderPageLinks()}
        <li>
          <Button2
            content={
              <div className="flex ml-1">
                <FaChevronRight size="0.6rem" />
                <FaChevronRight size="0.6rem" className="-translate-x-1/2" />
              </div>
            }
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </li>
      </ul>
    );
  }

  function TableComponent({
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
  }) {
    return (
      <div className="w-full f-f-r min-w-[30rem] flex justify-center p-4 bg-white rounded-xl shadow-[0_4px_10px_rgba(0,0,0,0.03)]">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-3 f-f-r text-start text-xs font-light uppercase cursor-pointer"
                    style={{ width: column.width }}
                  >
                    <div className="flex f-f-r gap-2 items-center">
                      <div className="text-gray-600">
                        {column.render("Header")}
                      </div>
                      <div className="flex f-f-r flex-col">
                        <FaSortUp
                          className={`text-sm translate-y-1/2 ${
                            column.isSorted && !column.isSortedDesc
                              ? "text-red-400"
                              : "text-gray-300"
                          }`}
                        />
                        <FaSortDown
                          className={`text-sm -translate-y-1/2 ${
                            column.isSortedDesc
                              ? "text-red-400"
                              : "text-gray-300"
                          }`}
                        />
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="hover:bg-gray-100 f-f-r">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="p-3 text-sm f-f-r font-normal text-gray-700 first:rounded-l-lg last:rounded-r-lg"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
  function Table1() {
    const data = useMemo(() => generateData(), []);
    const columns = useMemo(getColumns, []);
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      state,
      setGlobalFilter,
      page: rows,
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageSize: 5 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row justify-between gap-2">
          <GlobalSearchFilter1
            className="sm:w-64"
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <SelectMenu1
            className="sm:w-44"
            value={pageSize}
            setValue={setPageSize}
            options={[
              { id: 5, caption: "5 items per page" },
              { id: 10, caption: "10 items per page" },
              { id: 20, caption: "20 items per page" },
            ]}
          />
        </div>
        <TableComponent
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
        />

        <div className="flex justify-center">
          <PaginationNav1
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
          />
        </div>
      </div>
    );
  }

  /************************* End of new Project Contacts Table Code *********************/

  return (
    <>
      <div className=" bg-white rounded-[10px] shadow-lg p-[10px] mb-6 mt-0 ">
        <div className="grid grid-cols-12 mt-5 gap-4 xl:gap-2  ">
          <div className="   col-span-8  ">
            <ul className=" inline-flex my-2 ml-2 ">
              <li>
                <h3 className=" text-black text-2xl f-f-r ">
                  Project Information
                </h3>
              </li>
              {/* <li className=" ml-3 mt-3">
                <svg
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6.6326 7.10198C6.83058 7.31646 7.16942 7.31646 7.3674 7.10198L13 1"
                    stroke="#333333"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </li> */}
            </ul>
          </div>
          <div className="   col-span-4  ">
            <div className="relative">
              <Dropdownproject
                onEditProjectInformation={() =>
                  setEditProjectInformationModel(true)
                }
              />
            </div>
          </div>
        </div>

        {/* card section started */}
        <div className="grid grid-cols-12 mt-5 gap-4 xl:gap-2  ">
          {/* card one started */}
          <div className="   col-span-12 sm:col-span-6 xl:col-span-4 relative ">
            {/* <button onClick={() => setEditProjectInformationModel(true)} className="absolute top-1 right-1 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  ">
            Edit
          </button> */}
            <div className=" bg-grey-vlight rounded-[8px] border border-grey p-3 shadow-lg h-full ">
              <h2 className=" f-f-m text-tiny text-grey-vdark  ">
                Project Name :
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.project_name}
              </h3>
              <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                Project Summary:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.project_summary}
              </h3>
              <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                Client Name:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.utility?.utility_full_name}
              </h3>
              {/* <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                Project status:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.status}
              </h3> */}
              {/* 
                
                utility_name: utilityName,
                contract_gfc_date: contractGFCDate,
                status            : projectStatus

                contract_number   : contractNumber,
                pm_number         : PMNumber,
                po_number         : PONumber,
              */}
            </div>
          </div>
          {/* card one ended */}
          {/* card one started */}
          <div className="   col-span-12 sm:col-span-6 xl:col-span-4 relative ">
            <div className=" bg-grey-vlight rounded-[8px] border border-grey p-3 shadow-lg h-full ">
              {/* <h2 className=" f-f-m text-tiny text-grey-vdark ">
                Project Type:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.project_type}
              </h3> */}
              <h2 className=" f-f-m text-tiny text-grey-vdark ">
                Customer Type:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.customer_type}
              </h3>
              <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                Project Budget:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                ${project?.project_budget}
              </h3>
              <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                PM Number:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.pm_number}
              </h3>
              <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                PO Number:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.po_number}
              </h3>
              <h2 className=" f-f-m text-tiny text-grey-vdark  mt-4 ">
                Contract Value:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                ${project?.contract_value}
              </h3>
            </div>
          </div>
          {/* card one ended */}
          {/* card one started */}
          <div className="   col-span-12 sm:col-span-6 xl:col-span-4 relative ">
            <div className=" bg-grey-vlight rounded-[8px] border border-grey p-3 shadow-lg h-full ">
              
              <h2 className=" f-f-m text-tiny text-grey-vdark  ">
                Contract NTP date:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.contract_date}
              </h3>
              <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                Contract GFC Date:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.contract_gfc_date}
              </h3>
              <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                Contract number:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.contract_number}
              </h3>
              <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                Project Notes:
              </h2>
              <h3 className="f-f-r text-base text-black my-2 ">
                {project?.project_notes}
              </h3>
            </div>
          </div>
          {/* card one ended */}
          {/* card one started */}
          {/* card one ended */}
        </div>
        
        <div className="bg-grey-vlight">
          <div className="   col-span-8 py-8 ">
            <ul className=" flex justify-between my-2 ml-2">
              <li>
                <h3 className=" text-black text-2xl f-f-r ">
                  Project Contacts
                </h3>
              </li>
              <li className="h-[30px] rounded-[15px] f-f-r text-sm text-white">
                { project?.contacts ?
                  <Dilogbox 
                    onReceiveContact={_onReceiveContact} 
                    contacts={project?.contacts} 
                    editProject={true}
                    onEditContactSubmit={editProjectInformation}
                  />
                : null }
              </li>
            </ul>
          </div>
          <div className="flex flex-col overflow-auto mx-4 py-4 pt-8 sm:py-0 bg-grey-vlight">
            <Table1 />
          </div>
        </div>
        {/* card section ended */}
      </div>
      <Transition.Root show={editProjectInformationModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditProjectInformationModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-semibold leading-6 text-gray-900"
                        >
                          Edit Project Information
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project Name{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setProjectName(e.target.value)
                                  }
                                  value={projectName}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project Summary{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-2">
                                <textarea
                                  onChange={(e) =>
                                    setProjectSummary(e.target.value)
                                  }
                                  value={projectSummary}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 smbase sm:leading-6"
                                ></textarea>
                              </div>
                              {/* <div className="mt-0">
                                <input type="text" onChange={e => setProjectSummary(e.target.value)} value={projectSummary} className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                              </div> */}
                            </div>
                          </div>

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project Contacts{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                {project?.contacts?.length ? (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      width: "55%",
                                    }}
                                  >
                                    {(
                                      projectContacts || project?.contacts
                                    )?.map(
                                      (
                                        contact,
                                        i // projectContacts
                                      ) => (
                                        <span key={i}>
                                          {contact.first_name},{" "}
                                        </span>
                                      )
                                    )}
                                  </div>
                                ) : null}

                                <div
                                  style={{
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                    width: "45%",
                                  }}
                                >
                                  <Dilogbox
                                    onReceiveContact={_onReceiveContact}
                                    contacts={project?.contacts}
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project type
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setProjectType(e.target.value)
                                  }
                                  value={projectType}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Turnkey">Turnkey</option>
                                  <option value="Equipment">Equipment</option>
                                  <option value="Equipment + Consulting">
                                    Equipment + Consulting
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div> */}

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Client Name
                              </label>
                              <div className="mt-0">
                                { project?.utility ? (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      width: "55%",
                                    }}
                                  >
                                    { projectUtilities.length ? 
                                      projectUtilities.map((utility, i) => <span key={i}>{`${utility.utility_full_name} (${utility.utility_name})`} </span>) 
                                    :
                                      <span>{`${project?.utility?.utility_full_name} (${project?.utility?.utility_name})`} </span>
                                    }
                                  </div>
                                ) : null }

                                <div
                                  style={{
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                    width: "45%",
                                  }}
                                >
                                  <UtilityDilogbox
                                    onReceiveUtility={_onReceiveUtility}
                                    utilities={[ project?.utility ]}
                                  />
                                </div>
                              </div>
                              {/* <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setUtilityName(e.target.value)
                                  }
                                  value={utilityName}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="PG&E">PG&E</option>
                                  <option value="SCE">SCE</option>
                                  <option value="Liberty">Liberty</option>
                                  <option value="SDG&E">SDG&E</option>
                                  <option value="Pacificorp">Pacificorp</option>
                                  <option value="Puget Sound Energy">
                                    Puget Sound Energy
                                  </option>
                                  <option value="Holston Electric Cooperative">
                                    Holston Electric Cooperative
                                  </option>
                                  <option value="ATCO">ATCO</option>
                                </select>
                              </div> */}
                              {/*                               <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setUtilityName(e.target.value)
                                  }
                                  value={utilityName}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div> */}
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Contract GFC date
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="date"
                                  onChange={(e) =>
                                    setContractGFCDate(e.target.value)
                                  }
                                  value={contractGFCDate}
                                  className="p-3 easi-grid-datepicker pl-[30px] pr-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              {/*                               <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setContractGFCDate(e.target.value)
                                  }
                                  value={contractGFCDate}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div> */}
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Customer type
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setCustomerType(e.target.value)
                                  }
                                  value={customerType}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Utility">Utility</option>
                                  <option value="EV">EV</option>
                                  <option value="Telecom">Telecom</option>
                                </select>
                              </div>
                              {/*                               <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setCustomerType(e.target.value)
                                  }
                                  value={customerType}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div> */}
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project budget
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setProjectBudget(e.target.value)
                                  }
                                  value={projectBudget}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Contract value
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setContactValue(e.target.value)
                                  }
                                  value={contactValue}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Contract NTP date
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="date"
                                  onChange={(e) =>
                                    setContractDate(e.target.value)
                                  }
                                  value={contractDate}
                                  className="p-3 easi-grid-datepicker pl-[30px] pr-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Additional Project Notes
                              </label>
                              <div className="mt-0">
                                <textarea
                                  onChange={(e) =>
                                    setProjectNotes(e.target.value)
                                  }
                                  value={projectNotes}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 smbase sm:leading-6"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project Status
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setProjectStatus(e.target.value)
                                  }
                                  value={projectStatus}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Deployed">Deployed</option>
                                  <option value="Design Stage">
                                    Design Stage
                                  </option>
                                  <option value="Construction/Commissioning">
                                    Construction/Commissioning
                                  </option>
                                  <option value="Dead">Dead</option>
                                </select>
                              </div>
                            </div>
                          </div> */}
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Contract Number
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setContractNumber(e.target.value)
                                  }
                                  value={contractNumber}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                PM Number
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setPMNumber(e.target.value)}
                                  value={PMNumber}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                PO Number
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setPONumber(e.target.value)}
                                  value={PONumber}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editProjectInformation}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditProjectInformationModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

import React from "react";
import Dilogbox from "./Dilogbox";
import UtilityDilogbox from "./UtilityDilogbox";
import Config from "../../utils/Config";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const google = window.google;

export default function Hero() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.profile);
  // const { id } = useParams();

  const [projectName, setProjectName] = React.useState("");
  const [projectSummary, setProjectSummary] = React.useState("");
  const [projectContacts, setProjectContacts] = React.useState([]);
  const [projectType, setProjectType] = React.useState("");
  const [utilityName, setUtilityName] = React.useState("");
  const [utilityId, setUtilityId] = React.useState(0);
  const [customerType, setCustomerType] = React.useState("Utility");
  const [projectBudget, setProjectBudget] = React.useState(0);
  const [contactValue, setContactValue] = React.useState(0);
  const [contractDate, setContractDate] = React.useState("2024-01-01");
  const [projectNotes, setProjectNotes] = React.useState(
    "Add additional project notes here"
  );

  const [loading, setLoading] = React.useState(false);

  const [projectServices, setProjectServices] = React.useState([
    { title: "Foundation Design", key: "foundational_design", checked: true },
    {
      title: "Structural Calculations",
      key: "structural_calculations",
      checked: true,
    },
    { title: "Structural Drawings", key: "structural_drawings", checked: true },
    {
      title: "Equipment Elevations",
      key: "equipment_elevations",
      checked: true,
    },
    { title: "AC/DC Single Line Diagrams", key: "single_line", checked: true },
    { title: "Site Layout", key: "layout", checked: true },
    {
      title: "3D SketchUp Rendering Installation Training",
      key: "sketch_up",
      checked: false,
    },
    { title: "Installation Training", key: "install_training", checked: true },
    {
      title: "Installation Management",
      key: "install_management",
      checked: true,
    },
    {
      title: "Permit Submission (Turnkey only)",
      key: "permit_submission",
      checked: true,
    },
    { title: "Commissioning", key: "commissioning", checked: true },
  ]);
  
  const [ projectUtilities, setProjectUtilities ] = React.useState([]);

  const [location, setLocation] = React.useState({
    street_address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    latitude: 39.652239,
    longitude: -122.726481,
  });

  const [averageDailyUsage, setAverageDailyUsage] = React.useState(0);
  const [peakDemand, setPeakDemand] = React.useState(0);
  const [averageDemand, setAverageDemand] = React.useState(0);
  const [minimumTemperature, setMinimumTemperature] = React.useState(0);
  const [maximumTemperature, setMaximumTemperature] = React.useState(0);
  const [minimumHumidity, setMinimumHumidity] = React.useState(0);
  const [maximumHumidity, setMaximumHumidity] = React.useState(0);
  const [snowLoad, setSnowLoad] = React.useState(0);
  const [windSpeed, setWindSpeed] = React.useState(0);
  const [spectralResponseShort, setSpectralResponseShort] = React.useState(0);
  const [spectralResponseSecond, setSpectralResponseSecond] = React.useState(0);
  const [seismicImportanceFactor, setSeismicImportanceFactor] = React.useState(0);
  const [seismicSiteClass, setSeismicSiteClass] = React.useState("");
  const [designSpectralResponseDS, setDesignSpectralResponseDS] = React.useState(0);
  const [designSpectralResponseD1, setDesignSpectralResponseD1] = React.useState(0);
  const [seismicDesignCategory, setSeismicDesignCategory] = React.useState("");
  const [siteName, setSiteName] = React.useState("");
  const [siteContacts, setSiteContacts] = React.useState([]);
  const [siteAddress, setSiteAddress] = React.useState("");
  const [GPSCoordinates, setGPSCoordinates] = React.useState("");
  const [renewableFractionTarget, setRenewableFractionTarget] = React.useState("");
  const [foundationType, setFoundationType] = React.useState("");
  const [ additionalFoundationRequirements, setAdditionalFoundationRequirements ] = React.useState("");
  const [serviceVoltage, setServiceVoltage] = React.useState("");
  const [connectionType, setConnectionType] = React.useState("");
  const [PCCToTransformerType, setPCCToTransformerType] = React.useState("");
  const [PCCToTransformerInstall, setPCCToTransformerInstall] = React.useState("");
  const [PCCToTransformerDistanceFeet, setPCCToTransformerDistanceFeet] = React.useState(0);
  const [transformerToMeterType, setTransformerToMeterType] = React.useState("");
  const [transformerToMeterInstall, setTransformerToMeterInstall] = React.useState("");
  const [transformerToMeterDistanceFeet, setTransformerToMeterDistanceFeet] = React.useState(0);
  const [targetDeliveryDate, setTargetDeliveryDate] = React.useState("");
  const [equipment, setEquipment] = React.useState("");
  const [loadingMethod, setLoadingMethod] = React.useState("");
  const [shippingMethod, setShippingMethod] = React.useState("");
  const [unloadingMethod, setUnloadingMethod] = React.useState("");
  const [systemPlacement, setSystemPlacement] = React.useState("");
  const [preferredUnloadingMethod, setPreferredUnloadingMethod] = React.useState("");
  const [customerShippingMaxBudget, setCustomerShippingMaxBudget] = React.useState(0);
  const [miscellaneousItemsBeingShipped, setMiscellaneousItemsBeingShipped] = React.useState("");
  const [clickupSpaces, setClickupSpaces] = React.useState("https://example.com");
  const [PSAContact, setPSAContact] = React.useState("https://example.com");
  const [engineeringPhotos, setEngineeringPhotos] = React.useState("https://example.com");
  const [projectSharepoint, setProjectSharepoint] = React.useState("https://example.com");
  const [constructionPhotos, setConstructionPhotos] = React.useState("https://example.com");
  const [projectBOMWorksheet, setProjectBOMWorksheet] = React.useState("https://example.com");
  const [cableSchedule, setCableSchedule] = React.useState("https://example.com");
  const [projectSchedule, setProjectSchedule] = React.useState("https://example.com");
  const [ahjConfirmation, setAhjConfirmation] = React.useState("https://example.com");

  const [siteDistanceMiles, setSiteDistanceMiles] = React.useState("");
  const [siteDistanceDrive, setSiteDistanceDrive] = React.useState("");
  const [siteCounty, setSiteCounty] = React.useState("");

  const [ singleSite, setSingleSite ] = React.useState(true);

  
  // const { project_id, setProject_id } = React.useState("");

  const submitProject = () => {
    //if (projectName.length) {
      if (!singleSite && (
        !projectName.length ||
        !projectSummary.length ||
        !projectContacts.length
      )) {
        return swal("Oh no!", "Single site is disabled! So all project fields with asterisk(*) are required", "error");
      }

      if (singleSite && (
        !siteContacts.length ||
        // !siteName.length ||
        !siteAddress.length ||
        !GPSCoordinates.length ||
        !renewableFractionTarget.length ||
        !connectionType.length ||
        !projectType.length
      )) {
        return swal("Oh no!", "Single site is enabled! So all site fields with asterisk(*) are required", "error");
      }
      
      setLoading(true);
      const project_services = [];
      for (let i = 0; i < projectServices.length; i++) {
        const serv = projectServices[i];
        const item = {};

        item[serv.key] = serv.checked;

        project_services.push(item);
      }

      const project_contacts = [];
      for (let i = 0; i < projectContacts.length; i++) {
        const contact = projectContacts[i];
        project_contacts.push(contact.id);
      }
      const payload = {
        project_name: projectName.trim(),
        project_summary: singleSite ? projectName.trim() : projectSummary.trim(),
        project_contacts: project_contacts,
        // project_type: projectType,
        // utility_name: utilityName,
        utility_id: utilityId,
        contract_gfc_date: "2024-01-01",
        customer_type: customerType,
        project_budget: projectBudget,
        contract_value: contactValue,
        contract_date: contractDate,
        project_notes: projectNotes,
        project_services: project_services,
        contract_number: "",
        pm_number: "",
        po_number: "",
        status: "Design Stage",
        others: [],
      };

      //console.log('payload:', payload);
      //return false;

      fetch(`${Config.API.URL}/projects`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          // if 401 error (unauthenticated user, then log user out)
          setLoading(false);
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");

            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          // console.log('res:', res);
          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            if (singleSite) {
              submitSite(res.data.id);
            } else {
              navigate(`/project/view/${res.data.id}`);
            }
            // navigate("/dashboard");
          } else {
            swal("Oh no!", res.message, "error");
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    // } else {
    //   swal("Oh no!", "All fields with asterisk(*) are required", "error");
    // }
  };
  const _onReceiveContact = (contacts) => {
    setProjectContacts(contacts);
    setSiteContacts(contacts);
    // console.log('contacts:', contacts);
  };

  const _onReceiveUtility = (utilities) => {
    setProjectUtilities(utilities);
    setUtilityId(utilities?.length ? utilities[0]?.id : 0);
    // console.log('utilities:', utilities);
  };
  // const _onReceiveContact = (contacts) => {
  //   setSiteContacts(contacts);
  //   //console.log('contacts:', contacts);
  // };

  function convertToDecimalFormat(input) {
    // Normalize input: uppercase and remove unnecessary spaces
    input = input.replace(/\s+/g, " ").trim().toUpperCase();

    // Handle degree formats for both standard and reversed latitude-longitude entries
    const degreePattern = /(\d{1,3}\.\d+|\d{1,3})°?([NS]),?\s*(\d{1,3}\.\d+|\d{1,3})°?([EW])/i;
    const degreePatternReverse = /(\d{1,3}\.\d+|\d{1,3})°?([EW]),?\s*(\d{1,3}\.\d+|\d{1,3})°?([NS])/i;

    let match = input.match(degreePattern) || input.match(degreePatternReverse);

    if (match) {
      let latitude = parseFloat(match[1]);
      let longitude = parseFloat(match[3]);
      let latDirection = match[2];
      let longDirection = match[4];

      // Swap if the original input had longitude first
      if (degreePatternReverse.test(input)) {
        [latitude, longitude, latDirection, longDirection] = [
          longitude,
          latitude,
          longDirection,
          latDirection,
        ];
      }

      // Convert N/S to positive/negative latitude
      if (latDirection === "S") latitude = -latitude;

      // Convert E/W to positive/negative longitude
      if (longDirection === "W") longitude = -longitude;

      // Check for NaN values in coordinates
      if (isNaN(latitude) || isNaN(longitude)) {
        return input;
      }

      return `${latitude},${longitude}`;
    }

    // Handle normal decimal format, potentially reversing coordinates if necessary
    let parts = input.split(/,\s*|\s+/);
    if (parts.length === 2) {
      let lat = parseFloat(parts[0]);
      let lon = parseFloat(parts[1]);

      // Correct order based on usual latitude range
      if (Math.abs(lat) > 90) {
        [lat, lon] = [lon, lat];
      }

      // Check for NaN values in coordinates
      if (isNaN(lat) || isNaN(lon)) {
        return input;
      }

      return `${lat},${lon}`;
    }

    // Return the input as is if it is malformed or unrecognized
    return input;
  }

  const submitSite = (project_id) => {
    if (
      siteContacts.length &&
      // siteName.length &&
      siteAddress.length &&
      GPSCoordinates.length &&
      renewableFractionTarget.length &&
      connectionType.length &&
      projectType.length
    ) {
      setLoading(true);
      const site_contacts = [];
      for (let i = 0; i < siteContacts.length; i++) {
        const contact = siteContacts[i];
        site_contacts.push(contact.id);
      }
      const payload = {
        project_id: project_id,
        site_name: projectName.trim(), // siteName,
        site_address: siteAddress,
        site_gps: GPSCoordinates, // "39.2216489,-121.0130471",
        site_contacts: site_contacts,
        target_renewable: renewableFractionTarget,

        general_stage: projectType,

        county: siteCounty,
        ratepayer_type: "",
        landowner: "",
        critical_load: "",
        generator_use: "",
        weight: 0,
        others: [],

        // target_delivery_date: targetDeliveryDate,
        // equipment: equipment,
        // loading: loadingMethod,
        shipping: shippingMethod,
        unloading: unloadingMethod,
        system_placement: systemPlacement,
        // customer_shipping_max_budget: customerShippingMaxBudget,
        // miscellaneous_items_being_shipped: miscellaneousItemsBeingShipped,

        site_directions: "",
        site_distance_miles: siteDistanceMiles,
        site_distance_drive: siteDistanceDrive,
        access_road_flags: "",
        procurement_yard: "",

        location_minimum_temperature: minimumTemperature,
        location_maximum_temperature: maximumTemperature,
        location_minimum_humidity: minimumHumidity,
        location_maximum_humidity: maximumHumidity,
        wind_speed: windSpeed,
        snow_load: snowLoad,
        soil_type: "",
        seismic_spectral_response_short: spectralResponseShort,
        seismic_spectral_response_second: spectralResponseSecond,
        seismic_importance_factor: seismicImportanceFactor,
        seismic_site_class: seismicSiteClass,
        design_spectral_response_short: designSpectralResponseD1,
        design_spectral_response_second: designSpectralResponseDS,
        seismic_design_category: seismicDesignCategory,

        elevation: 0,
        wind_exposure_category: "",
        snow_height: 0,
        risk_category: "",
        geologic_hazard_zone: false,
        state_seismic_landslide_zone: false,
        state_seismic_liquefaction_zone: false,
        others: [],

        //average_daily_usage: averageDailyUsage,
        peak_demand: peakDemand,
        average_demand: averageDemand,

        foundation_type: foundationType,
        foundation_requirements: additionalFoundationRequirements,
        service_voltage: serviceVoltage,
        connection_type: connectionType,
        miles_line_removed: 0,
        pcc_to_transformer_type: PCCToTransformerType,
        pcc_to_transformer_install: PCCToTransformerInstall,
        pcc_to_transformer_distance: PCCToTransformerDistanceFeet,
        transformer_to_meter_type: transformerToMeterType,
        transformer_to_meter_install: transformerToMeterInstall,
        transformer_to_meter_distance: transformerToMeterDistanceFeet,
        additional_links: [
          { name: "Clickup Space", value: clickupSpaces },
          { name: "PSA Contract", value: PSAContact },
          { name: "Project Sharepoint", value: projectSharepoint },
          { name: "Major Equipment BOM", value: projectBOMWorksheet },
          { name: "Project Schedule", value: projectSchedule },
          { name: "Design Submissions", value: "https://example.com" },
          { name: "Subcontractor Sharepoint", value: "https://example.com" },
          { name: "Engineering Photos", value: engineeringPhotos },
          { name: "Construction Photos", value: constructionPhotos },
          {
            name: "Electrical - Cable & Conduit Schedule",
            value: cableSchedule,
          },
          {
            name: "AHJ confirmation of Design Requirements",
            value: ahjConfirmation,
          },
        ],
      };

      fetch(`${Config.API.URL}/sites`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);

          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");

            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            navigate(`/scenarios/view/${res.data.id}`);
            // navigate(`/project/view/${project_id}`);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };

  React.useLayoutEffect(() => {
    if (singleSite) {
      console.log('initMap...');
      initMap();
    }
  }, [singleSite]);

  const initMap = () => {
    // street_address
    const streetA = document.querySelector("#street_address");
    if (streetA) {
      // results frpm Google Maps for address autocomplete feature
      const autocomplete = new google.maps.places.Autocomplete(streetA, {
        fields: ["address_components", "geometry"],
        types: ["address"],
      });

      streetA.focus();

      // streetA.setAttribute("autoComplete", "nope");

      autocomplete.addListener("place_changed", async () => {
        let street_number = "";
        let route = "";
        const place = autocomplete.getPlace();
        const components = place.address_components;
        const position = JSON.parse(JSON.stringify(place.geometry.location));

        // setGPSCoordinates(`${position.lat}, ${position.lng}`);
        setGPSCoordinates(`${position.lat}, ${position.lng}`);

        for (let i = 0; i < components.length; i++) {
          const component = components[i];
          const type = component.types[0];
          //console.log("Full Geocode: ", component);

          switch (type) {
            case "street_number": {
              street_number = component.long_name + " ";
              break;
            }
            case "route": {
              route = component.long_name + ", ";
              break;
            }
            /*           case "postal_code_suffix": {
              location.zip = component.long_name;
              break;
            } */
            case "postal_code": {
              location.zip = component.long_name + ", ";
              break;
            }
            case "locality": {
              location.city = component.long_name + ", ";
              break;
            }
            case "administrative_area_level_1": {
              location.state = component.long_name + ", ";
              break;
            }
            case "country": {
              location.country = component.long_name;
              break;
            }
            case "administrative_area_level_2": {
              setSiteCounty(component.long_name);
              break;
            }
          }

          if (components.length === i + 1) {
            let destinations =
              street_number +
              route +
              location.city +
              location.state +
              location.zip +
              location.country;
            let origins = "12438 Loma Rica Drive, Grass Valley, CA 95945";
            setSiteAddress(destinations);

            try {
              // let distanceReq = await fetch(`https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=${destinations}&origins=${origins}&key=AIzaSyBWc5qs4BwCh-soN59z0cJmpwy3U26K0qI`);
              // let distanceRes = distanceReq.json();

              // let distanceReq = await axios.get(`https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=${destinations}&origins=${origins}&key=AIzaSyBWc5qs4BwCh-soN59z0cJmpwy3U26K0qI`);
              //console('distanceRes:', distanceReq);

              new google.maps.DistanceMatrixService().getDistanceMatrix(
                {
                  origins: [origins],
                  destinations: [destinations],
                  travelMode: "DRIVING",
                  unitSystem: google.maps.UnitSystem.IMPERIAL,
                },
                (response, status) => {
                  const distance = response?.rows[0]?.elements[0];

                  console.log("response:", response.rows[0].elements[0]);
                  setSiteDistanceMiles(distance.distance.text);
                  setSiteDistanceDrive(distance.duration.text);

                  // setSiteDistanceMiles(distance.distance.value / 1609);
                  // setSiteDistanceDrive(distance.duration.value / 3600);
                }
              );
            } catch (error) {
              console.log("error");
            }
          }
        }
      });
    }
  };

  const _onChangeGPSCoordinates = (e) => {
    const formattedGps = convertToDecimalFormat(e.target.value);
    setGPSCoordinates(formattedGps);

    // if coordinates are being updated
    const coordinates = formattedGps.split(",");

    const lat = parseFloat(coordinates[0]);
    const lng = parseFloat(coordinates[1]);
    //console.log("lat: ", lat);
    //console.log("lng: ", lng);

    // if latitude and longitude are provided
    if (lat && lng) {
      // location.coordinates = `${lat}, ${lng}`;

      // let lngRegex = new RegExp('/^-?(([-+]?)([\d]{1,3})((\.)(\d+))?)/g');
      // let latRegex = new RegExp('/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,15}/g');

      // console.log('Invalid lat', latRegex.test(lat))
      // console.log('Invalid lng', lngRegex.test(lng));

      let street_number = "";
      let route = "";

      // const url = `https://maps.googleapis.com/maps/api/js?latlng=${lat},${lng}&key=${Config.google_map_key}`;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${Config.google_map_key}`;
      // console.log("url new:", url);

      // retrieve formatted address from Google Maps API
      fetch(url, {
        method: "GET",
        headers: { Accept: "application/json" },
      })
        .then((res) => res.json())
        .then(async (res) => {
          console.log("coordinates-res:", res);

          // if successful response, fill address portion of form with results
          if (res.status === "OK") {
            const place = res.results[0]; // 51.5370938, -0.1833814
            const components = place.address_components;

            // location.street_address = place.formatted_address;

            for (let i = 0; i < components.length; i++) {
              const component = components[i];
              const type = component.types[0];

              // console.log("component-2:", component)

              switch (type) {
                case "street_number": {
                  street_number = component.long_name + " ";
                  break;
                }
                case "route": {
                  route = component.long_name + ", ";
                  break;
                }
                /*           case "postal_code_suffix": {
                location.zip = component.long_name;
                break;
              } */
                case "postal_code": {
                  location.zip = component.long_name + ", ";
                  break;
                }
                case "locality": {
                  location.city = component.long_name + ", ";
                  break;
                }
                case "administrative_area_level_1": {
                  location.state = component.long_name + ", ";
                  break;
                }
                case "country": {
                  location.country = component.long_name;
                  break;
                }
                case "administrative_area_level_2": {
                  setSiteCounty(component.long_name);
                  break;
                }
              }

              if (components.length === i + 1) {
                let destinations =
                  street_number +
                  route +
                  location.city +
                  location.state +
                  location.zip +
                  location.country;
                let origins = "12438 Loma Rica Drive, Grass Valley, CA 95945";
                setSiteAddress(destinations);

                try {
                  new google.maps.DistanceMatrixService().getDistanceMatrix(
                    {
                      origins: [origins],
                      destinations: [place.formatted_address],
                      travelMode: "DRIVING",
                      unitSystem: google.maps.UnitSystem.IMPERIAL,
                    },
                    (response, status) => {
                      const distance = response?.rows[0]?.elements[0];

                      console.log("response:", response.rows[0].elements[0]);
                      setSiteDistanceMiles(
                        distance?.distance?.text ? distance?.distance?.text : ""
                      );
                      setSiteDistanceDrive(
                        distance?.duration?.text ? distance?.duration?.text : ""
                      );
                    }
                  );
                } catch (error) {
                  console.log("error");
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
        });

      // initMap();
    }
  };

  return (
    <>
      <div className="bg-white rounded-[8px] p-6  mt-[90px] lg:mt-3 ">
        <h2 className=" f-f-sm text-3xl text-black text-center ">
          Create New Project{" "}
        </h2>
        <div className='text-center mt-7' >
          <ul className='inline-flex  ' >
            <li>
              <h2 className='text-primary text-3x1 mt-2' style={{ fontWeight: 'bold' }}>This project has a single site</h2>
            </li>
            <li className=' ml-4 mt-2 ' >
              <label className="flex items-center relative w-max cursor-pointer select-none">
                <input type="checkbox" checked={singleSite} onChange={() => setSingleSite(!singleSite)} className="appearance-none transition-colors cursor-pointer w-[61px] h-7 rounded-full focus:outline-none bg-gray-200" />
                <span className="absolute font-medium text-xs uppercase right-1 text-white">  </span>
                <span className="absolute font-medium text-xs uppercase right-9 text-white"> <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.67587 4.98419C1.48741 4.81543 1.24183 4.72386 0.988394 4.72786C0.734961 4.73186 0.492419 4.83113 0.309421 5.00575C0.126422 5.18037 0.0165037 5.41743 0.00171272 5.66938C-0.0130783 5.92133 0.0683524 6.16953 0.229671 6.36419L2.45922 8.69419C2.55257 8.79169 2.66474 8.86941 2.78899 8.92268C2.91325 8.97595 3.04704 9.00368 3.18232 9.00419C3.31689 9.00497 3.45024 8.97882 3.57446 8.92729C3.69867 8.87575 3.81122 8.79988 3.90542 8.70419L10.7146 1.70419C10.8069 1.60964 10.8796 1.49791 10.9286 1.37537C10.9775 1.25284 11.0018 1.12191 10.9999 0.990049C10.998 0.85819 10.9701 0.727988 10.9177 0.606876C10.8653 0.485765 10.7895 0.376117 10.6945 0.284192C10.5995 0.192266 10.4873 0.119864 10.3643 0.0711197C10.2412 0.0223752 10.1097 -0.00175758 9.97728 9.95945e-05C9.84486 0.00195677 9.7141 0.0297673 9.59246 0.0819434C9.47083 0.13412 9.36071 0.20964 9.26839 0.304192L3.19236 6.56419L1.67587 4.98419Z" fill="white" />
                </svg>
                </span>
                <span className="w-6 h-6 right-8 absolute rounded-full transform transition-transform bg-white" />
              </label>
            </li>
          </ul>
        </div>

        <div className="2xl:px-20 ">
          <div className="grid grid-cols-12 mt-10   ">
            {/* .....................  Electric input started ..............*/}

            {/* input section started */}
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Project Name <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                onChange={(e) => setProjectName(e.target.value)}
                value={projectName}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            {/* input section ended */}
            {/* input section started */}
            { !singleSite ?
              <>
                <div className=" col-span-12 lg:col-span-5 mt-5 ">
                  <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                    Project Summary <span className="text-primary">*</span>
                  </h2>
                </div>
                <div className=" col-span-12 lg:col-span-7 mt-5 ">
                  <textarea
                    onChange={(e) => setProjectSummary(e.target.value)}
                    value={projectSummary}
                    className=" w-full  border border-grey bg-white-dark rounded-[4px] p-4 outline-none  "
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
              </>
            : null }
            {/* input section ended */}
            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Average Demand (kW)
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <input onChange={e => setAverageDemand(e.target.value)} value={averageDemand} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
                        </div> */}
            {/* input section ended */}
            {/* input section started */}
            { !singleSite ?
              <>
                <div className=" col-span-12 lg:col-span-5 mt-5 ">
                  <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                    Project Contacts <span className="text-primary">*</span>
                  </h2>
                </div>
                <div className=" col-span-12 lg:col-span-7 mt-5 ">
                  {projectContacts.length ? (
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        width: "68%",
                      }}
                    >
                      {projectContacts.map((contact, i) => (
                        <span key={i}>{contact.first_name}, </span>
                      ))}
                    </div>
                  ) : null}
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      width: "30%",
                    }}
                  >
                    <Dilogbox onReceiveContact={_onReceiveContact} />
                  </div>
                </div>
              </>
            : null }
            {/* input section ended */}

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Client Name <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              {projectUtilities.length ? (
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    width: "68%",
                  }}
                >
                  {projectUtilities.map((utility, i) => (
                    <span key={i}>{`${utility.utility_full_name} (${utility.utility_name})`} </span>
                  ))}
                </div>
              ) : null}
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  width: "30%",
                }}
              >
                <UtilityDilogbox onReceiveUtility={_onReceiveUtility} />
              </div>
            </div>
            {/* <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <select
                onChange={(e) => setUtilityName(e.target.value)}
                value={utilityName}
                className="border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] "
              >
                <option value=""></option>
                <option value="PG&E">PG&E</option>
                <option value="SCE">SCE</option>
                <option value="Liberty">Liberty</option>
                <option value="SDG&E">SDG&E</option>
                <option value="Pacificorp">Pacificorp</option>
                <option value="Puget Sound Energy">Puget Sound Energy</option>
                <option value="Holston Electric Cooperative">
                  Holston Electric Cooperative
                </option>
                <option value="ATCO">ATCO</option>
              </select>
            </div> */}
            {/* input section ended */}
            {/* input section started */}
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
                <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                    Type of Customer
                </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
                <select onChange={e => setCustomerType(e.target.value)} value={customerType} className='border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] ' >
                    <option value=""></option>
                    <option value="Utility">Utility</option>
                    <option value="EV">EV</option>
                    <option value="Telecom">Telecom</option>
                </select>
            </div>
            {/* input section ended */}
            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Project Budget (in USD)
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <form>
                                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 12H2C2 13.08 3.37 14 5 14C6.63 14 8 13.08 8 12C8 10.9 6.96 10.5 4.76 9.97C2.64 9.44 0 8.78 0 6C0 4.21 1.47 2.69 3.5 2.18V0H6.5V2.18C8.53 2.69 10 4.21 10 6H8C8 4.92 6.63 4 5 4C3.37 4 2 4.92 2 6C2 7.1 3.04 7.5 5.24 8.03C7.36 8.56 10 9.22 10 12C10 13.79 8.53 15.31 6.5 15.82V18H3.5V15.82C1.47 15.31 0 13.79 0 12Z" fill="black" />
                                        </svg>
                                    </div>
                                    <input onChange={e => setProjectBudget(e.target.value)} value={projectBudget} type='number' className=' w-full z-10  h-[56px] border border-grey bg-white-dark rounded-[4px] pl-12 outline-none  ' required />
                                </div>
                            </form>
                        </div> */}
            {/* input section ended */}
            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Contact Value (in USD)
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <form>
                                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 12H2C2 13.08 3.37 14 5 14C6.63 14 8 13.08 8 12C8 10.9 6.96 10.5 4.76 9.97C2.64 9.44 0 8.78 0 6C0 4.21 1.47 2.69 3.5 2.18V0H6.5V2.18C8.53 2.69 10 4.21 10 6H8C8 4.92 6.63 4 5 4C3.37 4 2 4.92 2 6C2 7.1 3.04 7.5 5.24 8.03C7.36 8.56 10 9.22 10 12C10 13.79 8.53 15.31 6.5 15.82V18H3.5V15.82C1.47 15.31 0 13.79 0 12Z" fill="black" />
                                        </svg>
                                    </div>
                                    <input onChange={e => setContactValue(e.target.value)} value={contactValue} type='number' className=' w-full z-0 h-[56px] border border-grey bg-white-dark rounded-[4px] pl-12 outline-none  ' required />
                                </div>
                            </form>
                        </div> */}
            {/* input section ended */}

            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Target Delivery Date
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <form action="/action_page.php">
                                <input onChange={e => setContractDate(e.target.value)} value={contractDate} type="date" id="birthday" name="birthday" className=" w-full z-0 relative h-[56px] border border-grey bg-white-dark rounded-[4px] pl-[47px] pr-4 outline-none" placeholder="Select date" />
                            </form>
                        </div> */}

            {/* input section ended */}

            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Project Notes
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <textarea onChange={e => setProjectNotes(e.target.value)} value={projectNotes} className=' w-full  border border-grey bg-white-dark rounded-[4px] p-4 outline-none  ' id="w3review" name="w3review" rows="4" cols="50"></textarea>
                        </div> */}
            {/* input section ended */}

            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Project Services
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <ul>
                                { projectServices.map((service, i) => (
                                    <li key={i}>
                                        <div className="flex items-center mb-4">
                                            <input id={i} value={service.key} onChange={_onChangeProjectServices} type="checkbox" checked={service.checked} className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px]" />
                                            <label htmlFor={i} className="ml-4 f-f-r text-2xl text-black">{ service.title }</label>
                                        </div>
                                    </li>
                                )) }
                            </ul>
                        </div> */}
            {/* input section ended */}
          </div>
        </div>

        {/* Site Information */}
        { singleSite ?
          <div className="2xl:px-20 ">
            <div className="grid grid-cols-12 mt-10   ">
              <div className=" col-span-12 lg:col-span-5 "></div>
              <div className=" col-span-12 lg:col-span-7 ">
                <ul className="inline-flex w-full">
                  <li className=" w-full mt-2 ">
                    <h2 className=" f-f-sm text-lg capitalize  text-primary ">
                      Site Information
                    </h2>
                  </li>
                </ul>
              </div>
              {/* input section started */}
              {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                  Site Name <span className="text-primary">*</span>
                </h2>
              </div>
              <div className=" col-span-12 lg:col-span-7 mt-5 ">
                <input
                  onChange={(e) => setSiteName(e.target.value)}
                  value={siteName}
                  className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                />
              </div> */}
              {/* input section ended */}
              {/* input section started */}
              <div className=" col-span-12 lg:col-span-5 mt-5 ">
                <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                  Site Contacts <span className="text-primary">*</span>
                </h2>
              </div>
              <div className=" col-span-12 lg:col-span-7 mt-5 ">
                {siteContacts.length ? (
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      width: "68%",
                    }}
                  >
                    {siteContacts.map((contact, i) => (
                      <span key={i}>{contact.first_name}, </span>
                    ))}
                  </div>
                ) : null}
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    width: "30%",
                  }}
                >
                  <Dilogbox onReceiveContact={_onReceiveContact} />
                </div>
              </div>
              {/* input section ended */}

              {/* input section started */}
              <div className=" col-span-12 lg:col-span-5 mt-5 ">
                <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                  Site Address <span className="text-primary">*</span>
                </h2>
              </div>
              <div className=" col-span-12 lg:col-span-7 mt-5 ">
                {/** onChange={e => setSiteAddress(e.target.value)} value={siteAddress} */}
                <input
                  onChange={(e) => setSiteAddress(e.target.value)}
                  value={siteAddress}
                  placeholder="Enter a valid address only, No GPS coordinates"
                  autoComplete="nope"
                  id="street_address"
                  name="address"
                  className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                />
              </div>
              {/* input section ended */}

              {/* input section started */}
              <div className=" col-span-12 lg:col-span-5 mt-5 ">
                <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                  GPS Coordinates <span className="text-primary">*</span>
                </h2>
              </div>
              <div className=" col-span-12 lg:col-span-7 mt-5 ">
                <input
                  // onChange={(e) => setGPSCoordinates(e.target.value)}
                  onChange={_onChangeGPSCoordinates}
                  value={GPSCoordinates}
                  placeholder="Enter only valid GPS coordinates Eg: 39.3133154,-121.4059035"
                  className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                />
              </div>
              {/* input section ended */}
              {/* input section started */}
              <div className=" col-span-12 lg:col-span-5 mt-5 ">
                <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                  Renewable Fraction Target (%){" "}
                  <span className="text-primary">*</span>
                </h2>
              </div>
              <div className=" col-span-12 lg:col-span-7 mt-5 ">
                <input
                  type="number"
                  onChange={(e) => setRenewableFractionTarget(e.target.value)}
                  value={renewableFractionTarget}
                  className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                />
              </div>
              {/* input section ended */}

              <div className=" col-span-12 lg:col-span-5 mt-5 ">
                <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                  Connection Type
                  <span className="text-primary">*</span>
                </h2>
              </div>
              <div className=" col-span-12 lg:col-span-7 mt-5 ">
                <select onChange={e => setConnectionType(e.target.value)} value={connectionType} className='border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] ' >
                  <option value=""></option>
                  <option value="Off-grid">Off-grid</option>
                  <option value="Grid-tied">Grid-tied</option>
                </select>
              </div>

               {/* input section started */}
              <div className=" col-span-12 lg:col-span-5 mt-5 ">
                <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                  Project Type
                </h2>
              </div>
              <div className=" col-span-12 lg:col-span-7 mt-5 ">
                <select
                  onChange={(e) => setProjectType(e.target.value)}
                  value={projectType}
                  className="border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] "
                >
                  <option value=""></option>
                  <option value="Feasibility Study">Feasibility Study</option>
                  <option value="Turnkey">Turnkey</option>
                  <option value="O&M">O&M</option>

                  {/* <option value="Turnkey">Turnkey</option>
                  <option value="Equipment">Equipment</option>
                  <option value="Equipment + Consulting"> Equipment + Consulting</option> */}
                </select>
              </div>
            </div>
          </div>
        : null }

        {/* button started */}
        <div className=" mt-10 text-end  ">
          <ul className=" inline-flex  ">
            <li>
              {loading ? (
                <button className=" bg-gray text-lg f-f-r text-gray  w-[127px] h-[48px] rounded-[8px] captilize ">
                  Loading...
                </button>
              ) : (
                <button
                  onClick={submitProject}
                  className=" bg-primary text-lg f-f-r text-white  w-[127px] h-[48px] rounded-[8px] captilize "
                >
                  Done
                </button>
              )}
            </li>
            <li className=" ml-6 ">
              <button
                onClick={() => navigate(-1)}
                className=" border border-primary text-lg f-f-r text-primary  w-[127px] h-[48px] rounded-[8px] captilize "
              >
                Back
              </button>
            </li>
          </ul>
        </div>
        {/* button ended */}
      </div>
    </>
  );
}

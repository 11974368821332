import React, { useState, useEffect, useLayoutEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import Config from "../../utils/Config";
import { useSelector } from "react-redux";

import swal from "sweetalert";

export default function DialogBoxInverterProducts(props) {
    const user = useSelector((state) => state.user.profile);

    const [ showModal, setShowModal ] = useState(false);
    const [ products, setProducts ] = useState([]);
    const [ page, setPage ] = React.useState(1);
    const [ lastPage, setLastPage ] = React.useState(1);
    const [ currentPage, setCurrentPage ] = React.useState(1);

    const [ arrangeProducts, setArrangeProducts ] = React.useState(false);

    const [ loading, setLoading ] = useState("");

    const increasePage = () => {
        if (currentPage >= lastPage) {
            setPage(lastPage);
        } else {
            setPage(currentPage + 1);
        }
    };

    useLayoutEffect(() => {
        if (props?.products?.length && products?.length && arrangeProducts) {
            // console.log('inverter-props.products:', props.products);
            // console.log('inverter-products:', products);

            let data = [];
            let items = props.products?.map((x) => ({ 
                ...x, 
                inverter_product_quantity: 1,
                inverter_product_label: x.inverter_product_name,
                inverter_product_total_capacity: x.inverter_product_unit_capacity,
                checked: true 
            })) || [];

            for (let i = 0; i < products.length; i++) {
                const product = { ...products[i] };
                if (!props.products?.find((x) => x.id === product.id)) {
                    data.push({ 
                        ...product, 
                        inverter_product_quantity: 1,
                        inverter_product_label: product.inverter_product_name,
                        inverter_product_total_capacity: product.inverter_product_unit_capacity,
                        checked: false 
                    });
                }
            }

            //console.log("inverter-items:", items);
            //console.log("inverter-data:", data);

            const inverter_products = [ ...items, ...data ];

            //console.log('inverter_products:', inverter_products);

            setProducts(inverter_products);
        }
    
    }, [props.products, arrangeProducts]);

    useLayoutEffect(() => {
        // console.log('props.init_products', props.init_products);

        if (props.init_products) {
            let init_products = props.init_products;
            let data = [];
            let items = props.products?.map((x) => ({ 
                ...x, 
                solar_product_quantity: 1,
                solar_product_label: x.solar_product_name,
                solar_product_total_capacity: x.solar_product_unit_capacity,
                checked: true 
            })) || [];

            for (let i = 0; i < init_products.data.length; i++) {
                const product = { ...init_products.data[i] };
                if (!props.products?.find((x) => x.id === product.id)) {
                    data.push({ 
                        ...product, 
                        solar_product_quantity: 1,
                        solar_product_label: product.solar_product_name,
                        solar_product_total_capacity: product.solar_product_unit_capacity,
                        checked: false 
                    });
                }
            }

            setProducts([...items, ...data]);
            // setProducts([ ...init_products.data ]);
            setLastPage(init_products.last_page);
            setCurrentPage(init_products.current_page);
            setArrangeProducts(true);
        }
    }, [props.init_products]);

    // useEffect(() => {
    //     //console.log('props.products:', props.products);
    //     setLoading(true);
    //     fetch(`${Config.API.URL}/products/inverter-products?page=${page}`, {
    //         method: "GET",
    //         headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
    //     }).then((res) => {
    //         // if 401 error (unauthenticated user, then log user out)
    //         if (res.status === 401) {
    //             console.log("401 Error: Unauthenticated");
    //             // localStorage.removeItem('user');
    //             // history.push('/');
    //         }
    //         return res;
    //     }).then((res) => res.json()).then(async (res) => {
    //         // console.log('inverter-res:', res);
    //         setLoading(false);
    //         if (res.status) {
    //             let data = [];
    //             let items = props.products?.map((x) => ({ 
    //                 ...x, 
    //                 inverter_product_quantity: 1,
    //                 inverter_product_label: x.inverter_product_name,
    //                 inverter_product_total_capacity: x.inverter_product_unit_capacity,
    //                 checked: true 
    //             })) || [];

    //             for (let i = 0; i < res.data.data.length; i++) {
    //                 const product = { ...res.data.data[i] };
    //                 if (!props.products?.find((x) => x.id === product.id)) {
    //                     data.push({ 
    //                         ...product, 
    //                         inverter_product_quantity: 1,
    //                         inverter_product_label: product.inverter_product_name,
    //                         inverter_product_total_capacity: product.inverter_product_unit_capacity,
    //                         checked: false 
    //                     });
    //                 }
    //             }

    //             setProducts([ ...items, ...data ]);
    //             // setProducts([ ...data ]);
    //             setLastPage(res.data.last_page);
    //             setCurrentPage(res.data.current_page);
    //             setArrangeProducts(true);
    //         } else {
    //             swal("Oh no!", res.message, "error");
    //         }
    //     }).catch((error) => {
    //         setLoading(false);
    //         console.log("Error: ", error);

    //         swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
    //     });
    // }, [user]);

    // useEffect(() => {
    //     if (page > 1) {
    //         setLoading(true);
    //         fetch(`${Config.API.URL}/products/inverter-products?page=${page}`, {
    //             method: "GET",
    //             headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
    //         }).then((res) => {
    //             // if 401 error (unauthenticated user, then log user out)
    //             if (res.status === 401) {
    //                 console.log("401 Error: Unauthenticated");
    //             }
    //             return res;
    //         }).then((res) => res.json()).then(async (res) => {
    //             //console.log('res:', res);
    //             setLoading(false);
    //             if (res.status) {
    //                 let data = [];

    //                 for (let i = 0; i < res.data.data.length; i++) {
    //                     const product = { ...res.data.data[i] };
    //                     if (!props.products?.find((x) => x.id === product.id)) {
    //                         data.push({ 
    //                             ...product, 
    //                             inverter_product_quantity: 1,
    //                             inverter_product_label: product.inverter_product_name,
    //                             inverter_product_total_capacity: product.inverter_product_unit_capacity,
    //                             checked: false 
    //                         });
    //                     }
    //                 }
    //                 setProducts([ ...products, ...data ]);
    //                 setLastPage(res.data.last_page);
    //                 setCurrentPage(res.data.current_page);
    //             } else {
    //                 swal("Oh no!", res.message, "error");
    //             }
    //         }).catch((error) => {
    //             setLoading(false);
    //             console.log("Error: ", error);
                
    //             swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
    //         });
    //     }
    // }, [user, page]);

    const _onChangeProduct = (e) => {
        const data = [ ...products ];
        data[e.target.id].checked = !data[e.target.id].checked;
        setProducts(data);
    };

    const submitProducts = () => {
        setShowModal(false)
        const project_products = [];
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            if (product.checked) {
                project_products.push(product);
            }
        }

        props.onReceiveProducts(project_products);
    };

    return (
        <div>
            <button
                type="button"
                onClick={ () => setShowModal(true) }
                className="absolute top-3 left-3 border bg-default w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-primary  "
            >
                Add
            </button>

            { showModal ? (
                <>
                <div className="dilog-box" onClick={() => setShowModal(false)}></div>
                <div
                    id="defaultModal"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed top-[16%] lg:top-0  right-0 z-50  ml-auto w-[85%] md:w-[93%] lg:w-full  p-4 overflow-x-hidden overflow-y-auto inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                    <div className="relative w-full max-w-2xl max-h-full ml-auto mr-auto ">
                    <div className="relative bg-white rounded-lg shadow  p-10 ">
                        <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className=" absolute top-[19px] right-[19px]  text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                        data-modal-hide="defaultModal"
                        >
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.918759 13.0577C0.360088 13.6164 0.360088 14.5222 0.918759 15.0809C1.47743 15.6395 2.38321 15.6395 2.94189 15.0809L7.99981 10.0229L13.0579 15.0811C13.6166 15.6397 14.5224 15.6397 15.081 15.0811C15.6397 14.5224 15.6397 13.6166 15.081 13.0579L10.0229 7.99982L15.0806 2.94213C15.6393 2.38346 15.6393 1.47767 15.0806 0.919002C14.522 0.360332 13.6162 0.360333 13.0575 0.919003L7.99981 5.9767L2.9423 0.919193C2.38363 0.360523 1.47784 0.360523 0.919172 0.919193C0.360502 1.47786 0.360503 2.38365 0.919174 2.94232L5.97668 7.99982L0.918759 13.0577Z"
                            fill="#FA3030"
                            />
                        </svg>
                        </button>
                        
                        <>
                            {/*.................  add exisiting started ................*/}
                            
                            <h1 style={{ fontSize: '1.5rem', textAlign: 'center', fontWeight: 'bold' }}>Select inverter product(s) from the options below:</h1>
                            <div className=" mt-8 ">
                                {/* started */}
                                <h4 className=" text-black-vlight text-tiny f-f-r mt-2 ">{products.length} Results</h4>
                                <div className="overflow-y-scroll h-[400px]">
                                    <ul className=" mt-6">
                                    {/* li started */}
                                    { products.map((product, i) => (
                                        <li key={i}>
                                            <div className="flex items-center mb-4">
                                                <input 
                                                    id={i} 
                                                    type="checkbox" 
                                                    value="" 
                                                    onChange={_onChangeProduct}
                                                    checked={ product.checked ? product.checked : false }
                                                    className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                                                />
                                                <label htmlFor={i} className="ml-4 f-f-r text-lg text-black">
                                                    {`${product?.inverter_product_name ? product?.inverter_product_name : "Blank"} (${product?.inverter_product_manufacturer ? product?.inverter_product_manufacturer : "Blank"})`}{" "}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                    </ul>
                                    <div className="inline-flex w-full justify-center">
                                        { loading ? 
                                            ( "Loading...") 
                                        : currentPage < lastPage ? (
                                            <button
                                                onClick={increasePage}
                                                className=" bg-white text-base text-black border border-black  w-[127px] h-[40px] rounded-[20px] mr-1 captilize "
                                            >
                                                Load More
                                            </button>
                                        ) : null }
                                    </div>
                                </div>

                                {/* ended */}
                            </div>

                            {/* button started */}
                            <div className=" mt-3 text-end  ">
                                <button
                                    onClick={() => { 
                                        props.onAddBlankProduct();
                                        setShowModal(false);
                                    }}
                                    className=" bg-white text-base text-primary border border-primary w-[127px] h-[40px] rounded-[7px] mr-1 captilize "
                                >
                                    Blank Product
                                </button>
                                <button
                                    onClick={submitProducts}
                                    className=" bg-white text-base text-primary border border-primary w-[127px] h-[40px] rounded-[20px] ml-1 captilize "
                                >
                                    Done
                                </button>
                            </div>
                        </>
                        
                    </div>
                    </div>
                </div>
                </>
            ) : null }
        </div>
    );
}

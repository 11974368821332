import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import { Dialog, Transition, Listbox } from "@headlessui/react";
import { useSelector } from "react-redux";
import Config from "../../utils/Config";

import Dilogbox from "../NewProjectForm/Dilogbox";
import UtilityDilogbox from "../NewProjectForm/UtilityDilogbox";

import Dropdownproject from "../Projectview/Dropwdownpro";

import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import SiteMilestonesDialogBox from "./SiteMilestonesDialogBox";
import UniversalDataTable from "../Contacts/UniversalDataTable";

// Contact table code goes here
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import {
  FaSearch,
  FaChevronDown,
  FaCheck,
  FaChevronLeft,
  FaChevronRight,
  FaSortUp,
  FaSortDown,
} from "react-icons/fa";

export default function ElectricloadCrd(props) {
  // const { project, site } = props;
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.profile);

  const [project, setProject] = React.useState({ ...props.project });
  const [site, setSite] = useState({ ...props.site });
  const [editElectricLoadModel, setEditElectricLoadModel] = useState(false);
  const [editDirectoryLinksModel, setEditDirectoryLinksModel] = useState(false);
  const [addDirectoryLinksModel, setAddDirectoryLinksModel] = useState(false);
  const [editLogisticsModel, setEditLogisticsModel] = useState(false);
  const [
    editPowerDistributionSummaryModel,
    setEditPowerDistributionSummaryModel,
  ] = useState(false);

  const [editOthersModel, setEditOthersModel] = useState(false);
  const [addOthersModel, setAddOthersModel] = useState(false);

  const [collapseElectricLoad, setCollapseElectricLoad] = useState(false);
  const [collapseDirectoryLinks, setCollapseDirectoryLinks] = useState(false);
  const [collapseLogistics, setCollapseLogistics] = useState(false);
  const [
    collapsePowerDistributionSummary,
    setCollapsePowerDistributionSummary,
  ] = useState(false);

  const [collapseOthers, setCollapseOthers] = useState(false);

  const [averageDailyUsage, setAverageDailyUsage] = React.useState(
    site?.average_daily_usage
  );
  const [peakDemand, setPeakDemand] = React.useState(site?.peak_demand);
  const [averageDemand, setAverageDemand] = React.useState(
    site?.average_demand
  );
  const [apparentPowerContinuous, setApparentPowerContinuous] = React.useState(
    site?.apparent_power_continuous
  );
  const [realPowerContinuous, setRealPowerContinuous] = React.useState(
    site?.real_power_continuous
  );
  const [currentContinuous, setCurrentContinuous] = React.useState(
    site?.current_continuous
  );
  const [maxCurrentTransient, setMaxCurrentTransient] = React.useState(
    site?.max_current_transient
  );
  const [apparentPowerTransient, setApparentPowerTransient] = React.useState(
    site?.apparent_power_transient
  );

  const [maxTransientDuration, setMaxTransientDuration] = React.useState(
    site?.max_transient_duration
  );

  const [targetDeliveryDate, setTargetDeliveryDate] = React.useState("");
  const [equipment, setEquipment] = React.useState("");
  const [loadingMethod, setLoadingMethod] = React.useState("");
  const [shippingMethod, setShippingMethod] = React.useState("");
  const [unloadingMethod, setUnloadingMethod] = React.useState("");
  const [systemPlacement, setSystemPlacement] = React.useState("");
  const [preferredUnloadingMethod, setPreferredUnloadingMethod] =
    React.useState("");
  const [customerShippingMaxBudget, setCustomerShippingMaxBudget] =
    React.useState("");
  const [miscellaneousItemsBeingShipped, setMiscellaneousItemsBeingShipped] =
    React.useState("");

  const [site_directions, setSite_directions] = React.useState("");
  const [site_distance_miles, setSite_distance_miles] = React.useState("");
  const [site_distance_drive, setSite_distance_drive] = React.useState("");
  const [access_road_flags, setAccess_road_flags] = React.useState("");
  const [publicly_accessible, setPublicly_accessible] = React.useState("");
  const [locked_access_road, setLocked_access_road] = React.useState("");
  const [lock_access_code, setLock_access_code] = React.useState("");
  const [procurement_yard, setProcurement_yard] = React.useState("");

  const [PCCToTransformerType, setPCCToTransformerType] = React.useState("");
  const [PCCToTransformerInstall, setPCCToTransformerInstall] =
    React.useState("Existing");
  const [PCCToTransformerDistanceFeet, setPCCToTransformerDistanceFeet] =
    React.useState("");
  const [transformerToMeterType, setTransformerToMeterType] =
    React.useState("");
  const [transformerToMeterInstall, setTransformerToMeterInstall] =
    React.useState("Exist");
  const [transformerToMeterDistanceFeet, setTransformerToMeterDistanceFeet] =
    React.useState("");

  const [directory_of_links, setDirectory_of_links] = React.useState([]);
  const [additionalLinks, setAdditionalLinks] = React.useState([]);
  const [additionalLink, setAdditionalLink] = React.useState({
    name: "",
    value: "",
  });

  const [others, setOthers] = React.useState([]);
  const [additionalOthers, setAdditionalOthers] = React.useState([]);
  const [additionalOther, setAdditionalOther] = React.useState({
    label: "",
    value: "",
  });

  const [loadingEditElectricLoad, setLoadingEditElectricLoad] =
    React.useState(false);
  const [loadingEditLogistics, setLoadingEditLogistics] = React.useState(false);
  const [loadingEditDirectoryLinks, setLoadingEditDirectoryLinks] =
    React.useState(false);
  const [loadingAddDirectoryLink, setLoadingAddDirectoryLink] =
    React.useState(false);
  const [
    loadingEditPowerDistributionSummary,
    setLoadingEditPowerDistributionSummary,
  ] = React.useState(false);

  const [loadingEditOthers, setLoadingEditOthers] = React.useState(false);
  const [loadingAddOther, setLoadingAddOther] = React.useState(false);

  const [milestones, setMilestones] = useState([]);
  const [milestone, setMilestone] = useState(null);

  const [targetData, setTargetData] = useState("");
  const [dateAchieved, setDateAchieved] = useState("");
  const [milestoneName, setMilestoneName] = useState("");

  const [editMilestoneModel, setEditMilestoneModel] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [projectServices, setProjectServices] = React.useState([]);

  const [editProjectInformationModel, setEditProjectInformationModel] =
    useState(false);

  const [projectName, setProjectName] = React.useState("");
  const [projectSummary, setProjectSummary] = React.useState("");
  const [projectContacts, setProjectContacts] = React.useState([]);
  const [projectType, setProjectType] = React.useState("");
  // const [ utilityName, setUtilityName ] = React.useState("");
  const [utilityId, setUtilityId] = React.useState(0);
  const [contractGFCDate, setContractGFCDate] = React.useState("");
  const [customerType, setCustomerType] = React.useState("");
  const [projectBudget, setProjectBudget] = React.useState(0);
  const [contactValue, setContactValue] = React.useState(0);
  const [contractDate, setContractDate] = React.useState("");
  const [projectNotes, setProjectNotes] = React.useState("");
  const [contractNumber, setContractNumber] = React.useState("");
  const [PMNumber, setPMNumber] = React.useState("");
  const [PONumber, setPONumber] = React.useState("");
  const [projectStatus, setProjectStatus] = React.useState("");

  const [projectUtilities, setProjectUtilities] = React.useState([]);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setProject(props.project);
    // console.log("project:", props.project)
    const services = props.project?.project_services
      ? [...props.project?.project_services]
      : [];
    for (let i = 0; i < services.length; i++) {
      let service = { ...services[i] };
      let key = Object.keys(service)[0];

      service.checked = service[key];
      service.title = key
        .replace(/_/g, " ")
        .replace(/(?: |\b)(\w)/g, (key) => key.toUpperCase());

      services[i] = service;
    }
    setProjectServices(services);

    setProjectName(props.project?.project_name);
    setProjectSummary(props.project?.project_summary);
    setProjectContacts(props.project?.contacts);
    setProjectType(props.project?.project_type);
    // setUtilityName(props.project?.utility_name);

    setUtilityId(props.project?.utility_id);
    setProjectUtilities([props.project?.utility]);

    // console.log('props.project?.utility:', props.project?.utility);

    setContractGFCDate(
      props.project?.contract_gfc_date ? props.project?.contract_gfc_date : ""
    );
    setCustomerType(props.project?.customer_type);
    setProjectBudget(props.project?.project_budget);
    setContactValue(props.project?.contract_value);
    setContractDate(props.project?.contract_date);
    setProjectNotes(props.project?.project_notes);
    setContractNumber(
      props.project?.contract_number ? props.project?.contract_number : ""
    );
    setPMNumber(props.project?.pm_number ? props.project?.pm_number : "");
    setPONumber(props.project?.po_number ? props.project?.po_number : "");
    setProjectStatus(props.project?.status);
  }, [props.project]);

  useEffect(() => {
    setSite(props.site);

    setAverageDailyUsage(props.site?.average_daily_usage);
    setPeakDemand(props.site?.peak_demand);
    setAverageDemand(props.site?.average_demand);

    setApparentPowerContinuous(props.site?.apparent_power_continuous);
    setRealPowerContinuous(props.site?.real_power_continuous);
    setCurrentContinuous(props.site?.current_continuous);
    setMaxCurrentTransient(props.site?.max_current_transient);
    setApparentPowerTransient(props.site?.apparent_power_transient);
    setMaxTransientDuration(props.site?.max_transient_duration);

    // Temporary
    //setRealPowerContinuous(props.site?.average_demand);
    //setApparentPowerTransient(props.site?.peak_demand);
    //////////

    setTargetDeliveryDate(props.site?.target_delivery_date);
    setEquipment(props.site?.equipment);
    setLoadingMethod(props.site?.loading);
    setShippingMethod(props.site?.shipping);
    setUnloadingMethod(props.site?.unloading);
    setSystemPlacement(props.site?.system_placement);
    setPreferredUnloadingMethod(props.site?.preferred_unloading_method);
    setCustomerShippingMaxBudget(props.site?.customer_shipping_max_budget);
    setMiscellaneousItemsBeingShipped(
      props.site?.miscellaneous_items_being_shipped
    );

    setSite_directions(props.site?.site_directions);
    setSite_distance_miles(props.site?.site_distance_miles);
    setSite_distance_drive(props.site?.site_distance_drive);
    setAccess_road_flags(props.site?.access_road_flags);
    setPublicly_accessible(props.site?.publicly_accessible);
    setLocked_access_road(props.site?.locked_access_road);
    setLock_access_code(props.site?.lock_access_code);
    setProcurement_yard(props.site?.procurement_yard);

    setPCCToTransformerType(props.site?.pcc_to_transformer_type);
    setPCCToTransformerInstall(props.site?.pcc_to_transformer_install);
    setPCCToTransformerDistanceFeet(props.site?.pcc_to_transformer_distance);

    setTransformerToMeterType(props.site?.transformer_to_meter_type);
    setTransformerToMeterInstall(props.site?.transformer_to_meter_install);
    setTransformerToMeterDistanceFeet(
      props.site?.transformer_to_meter_distance
    );

    setAdditionalLinks(props.site?.additional_links);

    setAdditionalOthers(props.site?.others);

    if (props.site?.milestones) {
      const milestones_items = [];
      for (let i = 0; i < props.site?.milestones.length; i++) {
        const item = { ...props.site?.milestones[i] };

        if (!item?.achieved_date) {
          item.achieved_date = "Not Added Yet";
        }

        milestones_items.push(item);
      }

      setMilestones(milestones_items);
    }

    // console.log("props.site:", props.site);
  }, [props.site]);

  useEffect(() => {
    const links = props.site?.additional_links;

    sortAndRefreshAdditionalLinks(links);
  }, [props.site?.additional_links]);

  const sortAndRefreshAdditionalLinks = (links) => {
    const data = [];
    for (let i = 0; i < links?.length; i++) {
      const link = links[i];
      const count = data?.length;

      if (count <= 0) {
        data.push([link]);
      } else {
        if (data[count - 1]?.length >= 0 && data[count - 1]?.length <= 9) {
          data[count - 1].push(link);
        } else {
          data.push([link]);
        }
      }

      if (i === links.length - 1) {
        // console.log('data:', data);
        setDirectory_of_links(data);
      }
    }
  };

  useEffect(() => {
    const items = props.site?.others;

    sortAndRefreshAdditionalOthers(items);
  }, [props.site?.others]);

  const sortAndRefreshAdditionalOthers = (links) => {
    const data = [];
    for (let i = 0; i < links?.length; i++) {
      const link = links[i];
      const count = data?.length;

      if (count <= 0) {
        data.push([link]);
      } else {
        if (data[count - 1]?.length >= 0 && data[count - 1]?.length <= 1) {
          data[count - 1].push(link);
        } else {
          data.push([link]);
        }
      }

      if (i === links.length - 1) {
        setOthers(data);
      }
    }
  };

  useEffect(() => {
    if (props.siteDistance) {
      //console.log('props.siteDistance:', props.siteDistance);
      setSite_distance_miles(props.siteDistance?.distance?.text);
      setSite_distance_drive(props.siteDistance?.duration?.text);

      // setSite_distance_miles(props.siteDistance?.distance?.value / 1609);
      // setSite_distance_drive(props.siteDistance?.duration?.value / 3600);
    }
  }, [props.siteDistance]);

  /******************* Code to shorten URL Links *****************************/
  const [shortLinks, setShortLinks] = useState(false);

  const shortenLink = (url) => {
    return new Promise((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          Authorization: "sk_lA9XnijUhqlpMDoU",
        },
        body: JSON.stringify({
          domain: "go.boxpower.cloud",
          allowDuplicates: false,
          originalURL: url,
        }),
      };

      if (
        url.startsWith("https://go.boxpower.cloud") ||
        url.startsWith("https://example.com")
      ) {
        // The URL is already shortened, return it as is
        resolve(url);
      } else {
        fetch(
          "https://web-production-30c4.up.railway.app/https://api.short.io/links",
          options
        )
          .then((response) => response.json())
          .then((data) => {
            resolve(data.shortURL); // Assuming the API response contains the shortened URL in `shortURL`
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  };

  const updateLinksWithShortenedUrls = () => {
    setShortLinks(true);
    setLoadingEditDirectoryLinks(true);
    setLoadingAddDirectoryLink(true);
    // Map each link to a promise to shorten it
    const shorteningPromises = additionalLinks.map((link) => {
      return shortenLink(link.value)
        .then((shortenedUrl) => {
          return { ...link, value: shortenedUrl }; // Return a new link object with the shortened URL
        })
        .catch(() => link); // In case of error, keep the original link
    });

    // Wait for all promises to resolve
    Promise.all(shorteningPromises).then((updatedLinks) => {
      // console.log('updatedLinks:', updatedLinks);
      setAdditionalLinks(updatedLinks); // Update state with new links
      setLoadingEditDirectoryLinks(false);
      setLoadingAddDirectoryLink(false);
      sortAndRefreshAdditionalLinks(updatedLinks);
      setAdditionalLink({
        name: "",
        value: "",
      });
      if (editDirectoryLinksModel) {
        setEditDirectoryLinksModel(false);
      }
      if (addDirectoryLinksModel) {
        setAddDirectoryLinksModel(false);
      }
    });
  };

  const addDirectoryLinks = () => {
    const payload = {
      // ...site,
      // service_voltage: site?.service_voltage ? site?.service_voltage : "0",
      // preferred_unloading_method: site?.preferred_unloading_method
      // ? site?.preferred_unloading_method
      // : "Nill",
      additional_links: additionalLinks,
    };

    if (additionalLink.name.length && additionalLink.value.length) {
      payload.additional_links.push(additionalLink);
    }
    // console.log("payload:", payload);
    setAdditionalLinks(payload.additional_links);
    updateLinksWithShortenedUrls();

    /*     fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setAddDirectoryLinksModel(false);
          setSite(res.data);
          
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      }); */
  };

  const editDirectoryLinks = () => {
    //setLoadingEditDirectoryLinks(true);
    const payload = {
      // ...site,
      // service_voltage: site?.service_voltage ? site?.service_voltage : "0",
      // preferred_unloading_method: site?.preferred_unloading_method
      // ? site?.preferred_unloading_method
      // : "Nill",
      additional_links: additionalLinks,
    };

    // console.log("payload:", payload);

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setShortLinks(false);
        //setLoadingEditDirectoryLinks(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          swal("You must be logged in to perform this action");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        //setLoadingEditDirectoryLinks(false);
        // console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          //setEditDirectoryLinksModel(false);
          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        //setLoadingEditDirectoryLinks(false);
        setShortLinks(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  useEffect(() => {
    if (additionalLinks?.length > 0 && shortLinks) {
      editDirectoryLinks();
    }
  }, [additionalLinks]);

  /****************************************************************************/

  const addOthers = () => {
    const payload = {
      others: additionalOthers,
    };

    console.log("additionalOther:", additionalOther);

    if (additionalOther?.label?.length && additionalOther?.value?.length) {
      payload.others.push(additionalOther);
    }
    console.log("payload:", payload);

    setLoadingEditOthers(true);
    setLoadingAddOther(true);

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        console.log("addOthers-res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setAdditionalOthers(res.data.others); // Update state with new links
          sortAndRefreshAdditionalOthers(res.data.others);

          setAdditionalOther({ label: "", value: "" });
          setAddOthersModel(false);
          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }

        setLoadingEditOthers(false);
        setLoadingAddOther(false);
      })
      .catch((error) => {
        console.log("Error: ", error);

        setLoadingEditOthers(false);
        setLoadingAddOther(false);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const editOthers = () => {
    setLoadingEditOthers(true);
    const payload = { others: additionalOthers };

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        // setShortLinks(false);
        setLoadingEditOthers(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          swal("You must be logged in to perform this action");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditOthers(false);
        // console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setEditOthersModel(false);
          setAdditionalOthers(res.data.others); // Update state with new links
          sortAndRefreshAdditionalOthers(res.data.others);
          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditOthers(false);
        // setShortLinks(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const editPowerDistributionSummary = () => {
    setLoadingEditPowerDistributionSummary(true);
    const payload = {
      // ...site,
      // service_voltage: site?.service_voltage ? site?.service_voltage : "0",
      // additional_links: site?.additional_links ? site?.additional_links : [],
      // preferred_unloading_method: site?.preferred_unloading_method
      // ? site?.preferred_unloading_method
      // : "Nill",
      pcc_to_transformer_type: PCCToTransformerType,
      pcc_to_transformer_install: PCCToTransformerInstall,
      pcc_to_transformer_distance: PCCToTransformerDistanceFeet,
      transformer_to_meter_type: transformerToMeterType,
      transformer_to_meter_install: transformerToMeterInstall,
      transformer_to_meter_distance: transformerToMeterDistanceFeet,
    };

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditPowerDistributionSummary(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditPowerDistributionSummary(false);
        //console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setEditPowerDistributionSummaryModel(false);

          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditPowerDistributionSummary(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const editLogistics = () => {
    setLoadingEditLogistics(true);
    const payload = {
      // ...site,
      //service_voltage: site?.service_voltage ? site?.service_voltage : "0",
      //additional_links: site?.additional_links ? site?.additional_links : [],
      //target_delivery_date: targetDeliveryDate,
      //equipment: equipment,
      //loading: loadingMethod,
      shipping: shippingMethod,
      unloading: unloadingMethod,
      system_placement: systemPlacement,
      //preferred_unloading_method: preferredUnloadingMethod,
      //customer_shipping_max_budget: customerShippingMaxBudget,
      //miscellaneous_items_being_shipped: miscellaneousItemsBeingShipped,

      site_directions: site_directions,
      site_distance_miles: site_distance_miles,
      site_distance_drive: site_distance_drive,
      access_road_flags: access_road_flags,
      publicly_accessible: publicly_accessible,
      locked_access_road: locked_access_road,
      lock_access_code: lock_access_code,
      procurement_yard: procurement_yard,
    };

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditLogistics(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditLogistics(false);
        // console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setEditLogisticsModel(false);
          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditLogistics(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const editElectricLoad = () => {
    setLoadingEditElectricLoad(true);
    const payload = {
      // ...site,
      // service_voltage: site?.service_voltage ? site?.service_voltage : "0",
      // additional_links: site?.additional_links ? site?.additional_links : [],
      // preferred_unloading_method: site?.preferred_unloading_method
      // ? site?.preferred_unloading_method
      // : "Nill",
      average_daily_usage: averageDailyUsage,
      peak_demand: peakDemand,
      average_demand: averageDemand,

      apparent_power_continuous: apparentPowerContinuous,
      real_power_continuous: realPowerContinuous,
      current_continuous: currentContinuous,
      max_current_transient: maxCurrentTransient,
      apparent_power_transient: apparentPowerTransient,
      max_transient_duration: maxTransientDuration,
    };

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditElectricLoad(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditElectricLoad(false);
        // console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setEditElectricLoadModel(false);
          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditElectricLoad(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const _onDeleteMilestone = (milestone) => {
    swal({
      title: `Are you sure?`,
      text: `You want to delete "${milestone.title}"? because once deleted, you will not be able to recover this milestone!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${Config.API.URL}/site-milestones`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify([{ id: milestone.id }]),
        })
          .then((res) => {
            // if 401 error (unauthenticated user, then log user out)

            console.log("res.ok", res.ok);
            console.log("res.status", res.status);
            if (res.status === 401) {
              console.log("401 Error: Unauthenticated");
              localStorage.removeItem("user");
              //navigate("/");
            }
            return res;
          })
          //.then((res) => res.json())
          .then(async (res) => {
            // if request is succesful, alert user that project has been cloned

            if (res.ok) {
              let index = milestones.findIndex((x) => x.id === milestone.id);
              let data = [...milestones];

              data.splice(index, 1);
              setMilestones(data);

              swal(
                `The "${milestone.title}" milestone has been successfully deleted!`,
                { icon: "success" }
              );
            } else {
              swal("Oh no!", res.message, "error");
            }
          })
          .catch((error) => {
            console.log("Error: ", error);

            swal(
              "Oh no!",
              "An error occured!!! while connecting to server. Please try again",
              "error"
            );
          });
      }
    });
  };
  const _onEditMilestone = (milestone) => {
    setMilestone(milestone);

    setEditMilestoneModel(true);

    setTargetData(milestone.target_date ? milestone.target_date : "");
    setDateAchieved(milestone.achieved_date ? milestone.achieved_date : "");
    setMilestoneName(milestone.title ? milestone.title : "");
  };
  const submitMilestone = () => {
    if (milestoneName.length && dateAchieved.length) {
      setLoading(true);
      const payload = {
        target_date: targetData,
        achieved_date: dateAchieved,
        title: milestoneName,
      };

      fetch(`${Config.API.URL}/site-milestones/${milestone?.id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);

          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");
            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setEditMilestoneModel(false);

            let index = milestones.findIndex((x) => x.id === milestone.id);
            let data = [...milestones];

            data[index] = res.data;

            setMilestones(data);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);

          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };

  /************************************ Adding a new Project Contacts Table **********************************************/
  function Avatar({ src, alt = "avatar" }) {
    return (
      <img src={src} alt={alt} className="w-8 h-8 rounded-full object-cover" />
    );
  }
  const generateData = () =>
    project?.contacts
      ? [...Array(project?.contacts?.length)].map((contact, i) => ({
          name: `${project.contacts[i].first_name} ${project.contacts[i].last_name}`,
          image: `https://ui-avatars.com/api/?name=${project.contacts[i].first_name}+${project.contacts[i].last_name}`,
          title: project.contacts[i].title,
          company: project.contacts[i].company,
          type: project.contacts[i].type,
          email: project.contacts[i].email,
          phone: project.contacts[i].phone_number,
        }))
      : [];

  const getColumns = () => [
    {
      Header: "Name",
      accessor: "name",
      width: "300px",
      Cell: ({ row, value }) => {
        return (
          <div className="flex gap-2 items-center">
            <Avatar src={row.original.image} alt={`${value}'s Avatar`} />
            <div>{value}</div>
          </div>
        );
      },
    },
    {
      Header: "Job Title",
      accessor: "title",
    },
    {
      Header: "Company",
      accessor: "company",
    },
    {
      Header: "Contact Type",
      accessor: "type",
    },
    {
      Header: "Email Address",
      accessor: "email",
    },
    {
      Header: "Phone Number",
      accessor: "phone",
    },
  ];

  function InputGroup7({
    label,
    name,
    value,
    onChange,
    type = "text",
    decoration,
    className = "",
    inputClassName = "",
    decorationClassName = "",
    disabled,
  }) {
    return (
      <div
        className={`flex flex-row-reverse f-f-r items-stretch w-full rounded-xl overflow-hidden bg-white shadow-[0_4px_10px_rgba(0,0,0,0.03)] ${className}`}
      >
        <input
          id={name}
          name={name}
          value={value}
          type={type}
          placeholder={label}
          aria-label={label}
          onChange={onChange}
          className={`peer block w-full f-f-r p-3 text-gray-600 focus:outline-none focus:ring-0 appearance-none ${
            disabled ? "bg-gray-200" : ""
          } ${inputClassName}`}
          disabled={disabled}
        />
        <div
          className={`flex f-f-r items-center pl-3 py-3 text-gray-600 ${
            disabled ? "bg-gray-200" : ""
          } ${decorationClassName}`}
        >
          {decoration}
        </div>
      </div>
    );
  }

  function GlobalSearchFilter1({
    globalFilter,
    setGlobalFilter,
    className = "",
  }) {
    return (
      <InputGroup7
        name="search"
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value)}
        label="Search"
        decoration={<FaSearch size="1rem" className="text-gray-400 f-f-r" />}
        className={className}
      />
    );
  }

  function SelectMenu1({
    value,
    setValue,
    options,
    className = "cursor-pointer f-f-r",
    disabled,
  }) {
    const selectedOption = useMemo(
      () => options.find((o) => o.id === value),
      [options, value]
    );
    return (
      <Listbox value={value} onChange={setValue} disabled={disabled}>
        <div className={`relative f-f-r w-full cursor-pointer ${className}`}>
          <Listbox.Button
            className={`relative f-f-r w-full cursor-pointer  rounded-xl py-3 pl-3 pr-10 text-base text-gray-700 text-left shadow-[0_4px_10px_rgba(0,0,0,0.03)] focus:outline-none
            ${
              disabled
                ? "bg-gray-200 cursor-not-allowed"
                : "bg-white cursor-default"
            }
          
          `}
          >
            <span className="block f-f-r truncate">
              {selectedOption.caption}
            </span>
            <span className="pointer-events-none f-f-r absolute inset-y-0 right-0 flex items-center pr-2">
              <FaChevronDown
                size="0.80rem"
                className="text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute f-f-r cursor-pointer z-10 mt-1 max-h-60 w-full overflow-auto rounded-xl bg-white text-base shadow-[0_4px_10px_rgba(0,0,0,0.03)] focus:outline-none">
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) =>
                    `relative cursor-default f-f-r select-none py-3 pl-10 pr-4 ${
                      active ? "bg-red-100" : ""
                    }`
                  }
                  value={option.id}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.caption}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-red-400">
                          <FaCheck size="0.5rem" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    );
  }

  function Button2({ content, onClick, active, disabled }) {
    return (
      <button
        className={`flex flex-col f-f-r cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
        ${active ? "bg-red-500 text-white" : "text-red-500"}
        ${
          !disabled
            ? "bg-red hover:bg-red-500 hover:text-white"
            : "text-red-300 bg-white cursor-not-allowed"
        }
        `}
        onClick={onClick}
        disabled={disabled}
      >
        {content}
      </button>
    );
  }

  function PaginationNav1({
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
  }) {
    const renderPageLinks = useCallback(() => {
      if (pageCount === 0) return null;
      const visiblePageButtonCount = 3;
      let numberOfButtons =
        pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
      const pageIndices = [pageIndex];
      numberOfButtons--;
      [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
        const pageNumberBefore = pageIndices[0] - 1;
        const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
        if (
          pageNumberBefore >= 0 &&
          (itemIndex < numberOfButtons / 2 || pageNumberAfter > pageCount - 1)
        ) {
          pageIndices.unshift(pageNumberBefore);
        } else {
          pageIndices.push(pageNumberAfter);
        }
      });
      return pageIndices.map((pageIndexToMap) => (
        <li key={pageIndexToMap}>
          <Button2
            content={pageIndexToMap + 1}
            onClick={() => gotoPage(pageIndexToMap)}
            active={pageIndex === pageIndexToMap}
          />
        </li>
      ));
    }, [pageCount, pageIndex]);
    return (
      <ul className="flex gap-2">
        <li>
          <Button2
            content={
              <div className="flex ml-1">
                <FaChevronLeft size="0.6rem" />
                <FaChevronLeft size="0.6rem" className="-translate-x-1/2" />
              </div>
            }
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          />
        </li>
        {renderPageLinks()}
        <li>
          <Button2
            content={
              <div className="flex ml-1">
                <FaChevronRight size="0.6rem" />
                <FaChevronRight size="0.6rem" className="-translate-x-1/2" />
              </div>
            }
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </li>
      </ul>
    );
  }

  function TableComponent({
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
  }) {
    return (
      <div className="w-full f-f-r min-w-[30rem] flex justify-center p-4 bg-white rounded-xl shadow-[0_4px_10px_rgba(0,0,0,0.03)]">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-3 f-f-r text-start text-xs font-light uppercase cursor-pointer"
                    style={{ width: column.width }}
                  >
                    <div className="flex f-f-r gap-2 items-center">
                      <div className="text-gray-600">
                        {column.render("Header")}
                      </div>
                      <div className="flex f-f-r flex-col">
                        <FaSortUp
                          className={`text-sm translate-y-1/2 ${
                            column.isSorted && !column.isSortedDesc
                              ? "text-red-400"
                              : "text-gray-300"
                          }`}
                        />
                        <FaSortDown
                          className={`text-sm -translate-y-1/2 ${
                            column.isSortedDesc
                              ? "text-red-400"
                              : "text-gray-300"
                          }`}
                        />
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="hover:bg-gray-100 f-f-r">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="p-3 text-sm f-f-r font-normal text-gray-700 first:rounded-l-lg last:rounded-r-lg"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
  function Table1() {
    const data = useMemo(() => generateData(), []);
    const columns = useMemo(getColumns, []);
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      state,
      setGlobalFilter,
      page: rows,
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageSize: 5 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row justify-between gap-2">
          <GlobalSearchFilter1
            className="sm:w-64"
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <SelectMenu1
            className="sm:w-44"
            value={pageSize}
            setValue={setPageSize}
            options={[
              { id: 5, caption: "5 items per page" },
              { id: 10, caption: "10 items per page" },
              { id: 20, caption: "20 items per page" },
            ]}
          />
        </div>
        <TableComponent
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
        />

        <div className="flex justify-center">
          <PaginationNav1
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
          />
        </div>
      </div>
    );
  }

  /************************* End of new Project Contacts Table Code *********************/

  const editProjectInformation = () => {
    if (projectContacts.length && projectName.length && projectSummary.length) {
      setLoading(true);
      const project_contacts = [];
      for (let i = 0; i < projectContacts.length; i++) {
        const contact = projectContacts[i];
        project_contacts.push(contact.id);
      }
      const payload = {
        project_name: projectName,
        project_summary: projectSummary,
        project_contacts: project_contacts,
        // project_type: projectType,
        utility_id: utilityId,
        //utility_name: utilityName,
        contract_gfc_date: contractGFCDate,
        customer_type: customerType,
        project_budget: projectBudget,
        contract_value: contactValue,
        contract_date: contractDate,
        project_notes: projectNotes,

        contract_number: contractNumber,
        pm_number: PMNumber,
        po_number: PONumber,
        // status: projectStatus,
      };

      fetch(`${Config.API.URL}/projects/${project.id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);
          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");

            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setEditProjectInformationModel(false);
            setProject(res.data);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };
  const _onReceiveContact = (contacts) => {
    setProjectContacts(contacts);
  };

  const _onReceiveUtility = (utilities) => {
    setProjectUtilities(utilities);
    setUtilityId(utilities?.length ? utilities[0]?.id : 0);
  };

  return (
    <>
      <div className=" w-11/12 lg:w-full ml-auto mr-auto ">
        {/* crd started */}
        <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] mt-6  relative ">
          <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
            Electric Load
          </h2>
          {/* <div
            className="absolute top-3 left-3 h-[50px] col-span-12 sm:col-span-4 xl:col-span-3 bg-white rounded-[10px] shadow-lg flex items-center justify-between px-4 cursor-pointer"
            onClick={() =>
              window.open(
                "https://design.boxpower.cloud/auth/project/management/92/overview",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            <button className="text-primary f-f-r text-lg">Load Profile</button>
          </div> */}
          {/* button started */}
          <button
            onClick={() => setEditElectricLoadModel(true)}
            className="absolute top-3 left-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
          >
            Edit
          </button>

          <button
            onClick={() => setCollapseElectricLoad(!collapseElectricLoad)}
            className="absolute top-3 right-3 bg-white w-[50px] text-center h-[30px] f-f-r text-black text-lg "
            style={{ fontSize: 30 }}
          >
            {collapseElectricLoad ? (
              <ion-icon name="add-circle-outline"></ion-icon>
            ) : (
              <ion-icon name="remove-circle-outline"></ion-icon>
            )}
          </button>
          {/* buttons ended */}
          {!collapseElectricLoad ? (
            <>
              <h3 className="  f-f-r text-lg text-black-vlight mt-14  ">
                Average Daily Usage (kWh/day){" "}
                <span className=" float-right text-black ">
                  {site?.average_daily_usage?.toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              {/*           <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Peak Demand (kW)
                <span className=" float-right text-black ">
                  {site?.peak_demand?.toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              {/* 
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Average Demand (kW)
                <span className=" float-right text-black ">
                  {site?.average_demand?.toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div> */}

              {/* start */}
              {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Max Continuous Load - Apparent Power (kVA)
                <span className=" float-right text-black ">
                  {site?.apparent_power_continuous?.toFixed(2)}
                </span>{" "}
              </h3> 
              <div className=" border border-white-vlight my-1 "></div>*/}
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Max Continuous Load - Real Power (kW)
                <span className=" float-right text-black ">
                  {site?.real_power_continuous?.toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Max Continuous Load - Current (Amps)
                <span className=" float-right text-black ">
                  {site?.current_continuous?.toFixed(2)}
                </span>{" "}
              </h3> 
              <div className=" border border-white-vlight my-1 "></div>*/}
              {/* start */}
              {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Max Transient Current (A)
                <span className=" float-right text-black ">
                  {site?.max_current_transient?.toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div> */}
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Max Transient Apparent Power (kVA)
                <span className=" float-right text-black ">
                  {site?.apparent_power_transient?.toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Max Transient Duration (mSec)
                <span className=" float-right text-black ">
                  {site?.max_transient_duration?.toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div> */}
            </>
          ) : null}
        </div>
        {/* card ended */}

        {/* crd started */}
        <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] mt-6  relative ">
          <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
            Site Milestones
          </h2>
          {/* button started */}
          <SiteMilestonesDialogBox
            site={site}
            onReceiveMilestone={(items) => {
              let item = { ...items[0] };
              item.achieved_date = "Not Added Yet";
              setMilestones([...milestones, item]);
            }}
          />
          {milestones.length ? (
            <UniversalDataTable
              table_label={null}
              header_format={[
                // { column: "contacts", label: "Project Contacts" },
                { column: "title", label: "Milestone Name" },
                { column: "target_date", label: "Target Date" },
                { column: "achieved_date", label: "Achieved Date" },
              ]}
              data={milestones}
              data_format={[
                // { column: "contacts", keys: ["contacts"] },
                { column: "title", keys: ["title"] },
                { column: "target_date", keys: ["target_date"] },
                {
                  column: "achieved_date",
                  keys: ["achieved_date"],
                  button: true,
                },
              ]}
              setPage={() => console.log("Hi")}
              lastPage={1}
              currentPage={1}
              onCreateItem={false}
              onViewItem={false}
              onDeleteItem={_onDeleteMilestone}
              onEditItem={_onEditMilestone}
              onSearchData={false}
            />
          ) : (
            <p style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
              No milestones have been added to this site yet
            </p>
          )}
          {/* <button
            //onClick={() => setEditElectricLoadModel(true)}
            className="absolute top-3 right-3 border bg-primary w-[120px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
          >
            Add Milestones
          </button> */}
          {/* buttons ended */}

          {/* <h3 className="  f-f-r text-lg text-black-vlight mt-14  ">
            Average Daily Usage (kWh/day){" "}
            <span className=" float-right text-black ">
              {site?.average_daily_usage?.toFixed(2)}
            </span>{" "}
          </h3>
          <div className=" border border-white-vlight my-1 "></div> */}
          {/* start */}
          {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Max Continuous Load - Real Power (kW)
            <span className=" float-right text-black ">
              {site?.real_power_continuous?.toFixed(2)}
            </span>
          </h3>
          <div className=" border border-white-vlight my-1 "></div> */}
          {/* start */}
          {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Max Transient Apparent Power (kVA)
            <span className=" float-right text-black ">
              {site?.apparent_power_transient?.toFixed(2)}
            </span>{" "}
          </h3>
          <div className=" border border-white-vlight my-1 "></div> */}
        </div>
        {/* card ended */}

        <div className="grid grid-cols-12 mt-5 gap-4  ">
          <div className="   col-span-12 sm:col-span-6">
            {/* crd started */}
            <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] mt-6  relative ">
              <div className="absolute top-3 left-3">
                <button
                  type="button"
                  onClick={() => setAddDirectoryLinksModel(true)}
                  className="border bg-default w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-primary mr-2 "
                >
                  Add
                </button>

                <button
                  onClick={() => setEditDirectoryLinksModel(true)}
                  className="border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white "
                >
                  Edit
                </button>
              </div>
              {/* <button
                type="button"
                onClick={() => setEditDirectoryLinksModel(true)}
                className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
              >
                Edit
              </button> */}
              <button
                onClick={() =>
                  setCollapseDirectoryLinks(!collapseDirectoryLinks)
                }
                className="absolute top-3 right-3 bg-white w-[50px] text-center h-[30px] f-f-r text-black ml-1 text-lg "
                style={{ fontSize: 30 }}
              >
                {collapseDirectoryLinks ? (
                  <ion-icon name="add-circle-outline"></ion-icon>
                ) : (
                  <ion-icon name="remove-circle-outline"></ion-icon>
                )}
              </button>
              <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                Directory of Links
              </h2>
              {!collapseDirectoryLinks ? (
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={15}
                  slidesPerView={1}
                  //navigation={{ clickable: true }}
                  pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                  // onSwiper={(swiper) => console.log('swiper:',swiper)}
                  // onSlideChange={() => console.log('slide change')}
                  style={{ height: 500 }}
                >
                  {directory_of_links.map((links, i) => (
                    <SwiperSlide key={i}>
                      {links?.map((link, j) => (
                        <div key={j}>
                          <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                            {link.name}:{" "}
                            <span className=" float-right text-black ">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={link.value}
                              >
                                {link.value}
                              </a>
                            </span>{" "}
                          </h3>
                          <div className=" border border-white-vlight my-1 "></div>
                        </div>
                      ))}
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : null}

              {/* <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Clickup Spaces <span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >PSA Contact<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Consulting Contact<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Project Sharepoint<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Invoices<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Project  BOM Worksheet<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Project Pricing Worksheet<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Project Schedule<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Assumptions  & Exclusions<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div> */}
            </div>
            {/* card ended */}
          </div>
          <div className="   col-span-12 sm:col-span-6">
            {/* crd started */}
            <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] mt-6  relative ">
              <button
                type="button"
                onClick={() => setEditLogisticsModel(true)}
                className="absolute top-3 left-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white"
              >
                Edit
              </button>
              <button
                onClick={() => setCollapseLogistics(!collapseLogistics)}
                className="absolute top-3 right-3 bg-white w-[50px] text-center h-[30px] f-f-r text-black text-lg "
                style={{ fontSize: 30 }}
              >
                {collapseLogistics ? (
                  <ion-icon name="add-circle-outline"></ion-icon>
                ) : (
                  <ion-icon name="remove-circle-outline"></ion-icon>
                )}
              </button>
              <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                Logistics{" "}
              </h2>
              {!collapseLogistics ? (
                <>
                  {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Target Delivery Date
                    <span className=" float-right text-black ">
                      {site?.target_delivery_date}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div> */}
                  {/* start */}
                  {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Equipment
                    <span className=" float-right text-black ">
                      {site?.equipment}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div> */}
                  {/* start */}
                  {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Loading Method
                    <span className=" float-right text-black ">
                      {site?.loading}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div> */}
                  {/* start */}
                  <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Shipping Method
                    <span className=" float-right text-black ">
                      {site?.shipping}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div>
                  {/* start */}
                  <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Unloading Method
                    <span className=" float-right text-black ">
                      {site?.unloading}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div>
                  {/* start */}
                  <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    System Placement
                    <span className=" float-right text-black ">
                      {site?.system_placement}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div>
                  {/* start */}
                  {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Preferred Unloading Method
                    <span className=" float-right text-black ">
                      {site?.preferred_unloading_method}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div> */}
                  {/* start */}
                  {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Customer Shipping Max Budget
                    <span className=" float-right text-black ">
                      ${site?.customer_shipping_max_budget}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div> */}
                  {/* start */}
                  {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Miscellaneous Items Being Shipped
                    <span className=" float-right text-black ">
                      {site?.miscellaneous_items_being_shipped}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div> */}
                  {/* New */}
                  {/* start */}
                  <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Site Directions
                    <span className=" float-right text-black ">
                      {site?.site_directions}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div>
                  {/* start */}
                  <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Site Distance from HQ (miles)
                    <span className=" float-right text-black ">
                      {site?.site_distance_miles}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div>
                  {/* start */}
                  <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Site Distance from HQ (drive time)
                    <span className=" float-right text-black ">
                      {site?.site_distance_drive}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div>
                  {/* start */}
                  <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Access Road Flags or Cautions
                    <span className=" float-right text-black ">
                      {site?.access_road_flags}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div>
                  {/* start */}
                  <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Publicly Accessible?
                    <span className=" float-right text-black ">
                      {site?.publicly_accessible}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div>
                  {/* start */}
                  <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Locked Gated Access Road?
                    <span className=" float-right text-black ">
                      {site?.locked_access_road}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div>
                  {/* start */}
                  <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Key/Lock Access Code
                    <span className=" float-right text-black ">
                      {site?.lock_access_code}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div>
                  {/* start */}
                  <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                    Procurement Holding Yard
                    <span className=" float-right text-black ">
                      {site?.procurement_yard}
                    </span>{" "}
                  </h3>
                  <div className=" border border-white-vlight my-1 "></div>
                </>
              ) : null}
            </div>
            {/* card ended */}
          </div>
        </div>

        {/* table started */}
        <div className="grid grid-cols-12 mt-5 gap-4  ">
          <div className="   col-span-12 sm:col-span-6">
            {/* crd started */}
            <div
              className={` bg-white  shadow-lg p-[20px] rounded-[8px] my-6 relative ${
                !collapsePowerDistributionSummary ? "h-[200px]" : ""
              } `}
            >
              <button
                type="button"
                onClick={() => setEditPowerDistributionSummaryModel(true)}
                className="absolute top-3 left-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white"
              >
                Edit
              </button>
              <button
                onClick={() =>
                  setCollapsePowerDistributionSummary(
                    !collapsePowerDistributionSummary
                  )
                }
                className="absolute top-3 right-3 bg-white w-[50px] text-center h-[30px] f-f-r text-black text-lg "
                style={{ fontSize: 30 }}
              >
                {collapsePowerDistributionSummary ? (
                  <ion-icon name="add-circle-outline"></ion-icon>
                ) : (
                  <ion-icon name="remove-circle-outline"></ion-icon>
                )}
              </button>
              <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                {" "}
                Power Distribution Summary{" "}
              </h2>
              {!collapsePowerDistributionSummary ? (
                <div className="overflow-x-auto">
                  <table className=" w-full text-left power-table ">
                    <thead>
                      <tr>
                        <th className="f-f-r text-lg text-primary">
                          Service Conductor Path
                        </th>
                        <th className="f-f-r text-lg text-primary">Type</th>
                        <th className="f-f-r text-lg text-primary">
                          New/Existing
                        </th>
                        <th className="f-f-r text-lg text-primary">Distance</th>
                        <th className="f-f-r text-lg text-primary">Unit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="f-f-r text-lg text-black-vlight">
                          PCC to Transformer
                        </td>
                        <td className="f-f-r text-lg text-black-vlight">
                          {site?.pcc_to_transformer_type}
                        </td>
                        <td className="f-f-r text-lg text-black-vlight">
                          {site?.pcc_to_transformer_install}
                        </td>
                        <td className="f-f-r text-lg text-black-vlight">
                          {site?.pcc_to_transformer_distance}
                        </td>
                        <td className="f-f-r text-lg text-black-vlight">
                          Feet
                        </td>
                      </tr>
                      <tr>
                        <td className="f-f-r text-lg text-black-vlight">
                          Transformer to Meter
                        </td>
                        <td className="f-f-r text-lg text-black-vlight">
                          {site?.transformer_to_meter_type}
                        </td>
                        <td className="f-f-r text-lg text-black-vlight">
                          {site?.transformer_to_meter_install}
                        </td>
                        <td className="f-f-r text-lg text-black-vlight">
                          {site?.transformer_to_meter_distance}
                        </td>
                        <td className="f-f-r text-lg text-black-vlight">
                          Feet
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            {/* card ended */}
          </div>
          <div className="   col-span-12 sm:col-span-6">
            {/* crd started */}
            <div className="bg-white  shadow-lg p-[20px] rounded-[8px] my-6 relative ">
              <div className="absolute top-3 left-3">
                <button
                  type="button"
                  onClick={() => setAddOthersModel(true)}
                  className="border bg-default w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-primary mr-2 "
                >
                  Add
                </button>

                {others.length ? (
                  <button
                    onClick={() => setEditOthersModel(true)}
                    className="border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white "
                  >
                    Edit
                  </button>
                ) : null}
              </div>
              {/* <button
                type="button"
                onClick={() => setEditDirectoryLinksModel(true)}
                className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
              >
                Edit
              </button> */}
              <button
                onClick={() => setCollapseOthers(!collapseOthers)}
                className="absolute top-3 right-3 bg-white w-[50px] text-center h-[30px] f-f-r text-black ml-1 text-lg "
                style={{ fontSize: 30 }}
              >
                {collapseOthers ? (
                  <ion-icon name="add-circle-outline"></ion-icon>
                ) : (
                  <ion-icon name="remove-circle-outline"></ion-icon>
                )}
              </button>
              <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                Additional Site Data
              </h2>
              {!collapseOthers ? (
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={15}
                  slidesPerView={1}
                  //navigation={{ clickable: true }}
                  pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                  // onSwiper={(swiper) => console.log('swiper:',swiper)}
                  // onSlideChange={() => console.log('slide change')}
                  style={{ height: 120 }}
                >
                  {others.map((other_items, i) => (
                    <SwiperSlide key={i}>
                      {other_items?.map((other, j) => (
                        <div key={j}>
                          <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                            {other.label}:{" "}
                            <span className=" float-right text-black ">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={other.value}
                              >
                                {other.value}
                              </a>
                            </span>{" "}
                          </h3>
                          <div className=" border border-white-vlight my-1 "></div>
                        </div>
                      ))}
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : null}
            </div>
          </div>
        </div>

        {/* table ended */}
        {project?.sites?.length === 1 ? (
          <div className=" bg-white rounded-[10px] shadow-lg p-[10px] mb-6 mt-0 ">
            <div className="grid grid-cols-12 mt-5 gap-4 xl:gap-2  ">
              <div className="   col-span-8  ">
                <ul className=" inline-flex my-2 ml-2 ">
                  <li>
                    <h3 className=" text-black text-2xl f-f-r ">
                      Project Information
                    </h3>
                  </li>
                  {/* <li className=" ml-3 mt-3">
                    <svg
                      width="14"
                      height="9"
                      viewBox="0 0 14 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6.6326 7.10198C6.83058 7.31646 7.16942 7.31646 7.3674 7.10198L13 1"
                        stroke="#333333"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </li> */}
                </ul>
              </div>
              <div className="   col-span-4  ">
                <div className="relative">
                  <Dropdownproject
                    onEditProjectInformation={() =>
                      setEditProjectInformationModel(true)
                    }
                  />
                </div>
              </div>
            </div>

            {/* card section started */}
            <div className="grid grid-cols-12 mt-5 gap-4 xl:gap-2 mb-5 ">
              {/* card one started */}
              <div className="   col-span-12 sm:col-span-6 xl:col-span-4 relative ">
                {/* <button onClick={() => setEditProjectInformationModel(true)} className="absolute top-1 right-1 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  ">
                Edit
              </button> */}
                <div className=" bg-grey-vlight rounded-[8px] border border-grey p-3 shadow-lg h-full ">
                  <h2 className=" f-f-m text-tiny text-grey-vdark  ">
                    Project Name :
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.project_name}
                  </h3>
                  <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                    Project Summary:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.project_summary}
                  </h3>
                  <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                    Client Name:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.utility?.utility_full_name}
                  </h3>
                  {/* <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                    Project status:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.status}
                  </h3> */}
                  {/* 
                    
                    utility_name: utilityName,
                    contract_gfc_date: contractGFCDate,
                    status            : projectStatus

                    contract_number   : contractNumber,
                    pm_number         : PMNumber,
                    po_number         : PONumber,
                  */}
                </div>
              </div>
              {/* card one ended */}
              {/* card one started */}
              <div className="   col-span-12 sm:col-span-6 xl:col-span-4 relative ">
                <div className=" bg-grey-vlight rounded-[8px] border border-grey p-3 shadow-lg h-full ">
                  {/* <h2 className=" f-f-m text-tiny text-grey-vdark ">
                    Project Type:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.project_type}
                  </h3> */}
                  <h2 className=" f-f-m text-tiny text-grey-vdark ">
                    Customer Type:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.customer_type}
                  </h3>
                  <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                    Project Budget:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    ${project?.project_budget}
                  </h3>
                  <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                    PM Number:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.pm_number}
                  </h3>
                  <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                    PO Number:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.po_number}
                  </h3>
                  <h2 className=" f-f-m text-tiny text-grey-vdark  mt-4">
                    Contract Value:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    ${project?.contract_value}
                  </h3>
                </div>
              </div>
              {/* card one ended */}
              {/* card one started */}
              <div className="   col-span-12 sm:col-span-6 xl:col-span-4 relative ">
                <div className=" bg-grey-vlight rounded-[8px] border border-grey p-3 shadow-lg h-full ">
                  <h2 className=" f-f-m text-tiny text-grey-vdark   ">
                    Contract NTP date:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.contract_date}
                  </h3>
                  <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                    Contract GFC Date:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.contract_gfc_date}
                  </h3>
                  <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                    Contract number:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.contract_number}
                  </h3>
                  <h2 className=" f-f-m text-tiny text-grey-vdark  mt-5 ">
                    Project Notes:
                  </h2>
                  <h3 className="f-f-r text-base text-black my-2 ">
                    {project?.project_notes}
                  </h3>
                </div>
              </div>
              {/* card one ended */}
              {/* card one started */}
              {/* card one ended */}
            </div>

            <div className="bg-grey-vlight mb-7">
              <div className="   col-span-8 py-8 ">
                <ul className=" flex justify-between my-2 ml-2">
                  <li>
                    <h3 className=" text-black text-2xl f-f-r ">
                      Project Contacts
                    </h3>
                  </li>
                  <li className="h-[30px] rounded-[15px] f-f-r text-sm text-white">
                    {project?.contacts ? (
                      <Dilogbox
                        onReceiveContact={_onReceiveContact}
                        contacts={project?.contacts}
                        editProject={true}
                        onEditContactSubmit={editProjectInformation}
                      />
                    ) : null}
                  </li>
                </ul>
              </div>
              <div className="flex flex-col overflow-auto mx-4 py-4 pt-8 sm:py-0 bg-grey-vlight">
                <Table1 />
              </div>
            </div>
            {/* card section ended */}
          </div>
        ) : null}
      </div>
      <Transition.Root show={editPowerDistributionSummaryModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditPowerDistributionSummaryModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Power Distribution Summary
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <h3 className=" f-f-r text-lg text-black-vlight text-center ">
                            Service Conductor Path <br />
                            (PCC To Transformer)
                          </h3>

                          <div className="mt-0 sm:col-span-12">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Type
                            </label>
                            <div className="mt-0">
                              <select
                                onChange={(e) =>
                                  setPCCToTransformerType(e.target.value)
                                }
                                value={PCCToTransformerType}
                                className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option value=""></option>
                                <option value="Underground">Underground</option>
                                <option value="Overhead">Overhead</option>
                              </select>
                            </div>
                          </div>

                          <div className="mt-3 space-y-0">
                            <div className="flex items-center gap-x-3">
                              <input
                                type="radio"
                                checked={PCCToTransformerInstall === "New"}
                                onChange={(e) =>
                                  setPCCToTransformerInstall(e.target.value)
                                }
                                value="New"
                                name="PCCToTransformerInstall"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                New
                              </label>
                            </div>

                            <div className="mt-0 flex items-center gap-x-3">
                              <input
                                type="radio"
                                checked={PCCToTransformerInstall === "Existing"}
                                onChange={(e) =>
                                  setPCCToTransformerInstall(e.target.value)
                                }
                                value="Existing"
                                name="PCCToTransformerInstall"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Existing
                              </label>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Distance (Feet)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setPCCToTransformerDistanceFeet(
                                      e.target.value
                                    )
                                  }
                                  value={PCCToTransformerDistanceFeet}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <h3 className="mt-10 f-f-r text-lg text-black-vlight text-center ">
                            (Transformer To Meter)
                          </h3>

                          <div className="mt-0 sm:col-span-12">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Type
                            </label>
                            <div className="mt-0">
                              <select
                                onChange={(e) =>
                                  setTransformerToMeterType(e.target.value)
                                }
                                value={transformerToMeterType}
                                className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option value=""></option>
                                <option value="Underground">Underground</option>
                                <option value="Overhead">Overhead</option>
                              </select>
                            </div>
                          </div>

                          <div className="mt-3 space-y-0">
                            <div className="flex items-center gap-x-3">
                              <input
                                type="radio"
                                checked={transformerToMeterInstall === "New"}
                                onChange={(e) =>
                                  setTransformerToMeterInstall(e.target.value)
                                }
                                value="New"
                                name="transformerToMeterInstall"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                New
                              </label>
                            </div>

                            <div className="mt-0 flex items-center gap-x-3">
                              <input
                                type="radio"
                                checked={
                                  transformerToMeterInstall === "Existing"
                                }
                                onChange={(e) =>
                                  setTransformerToMeterInstall(e.target.value)
                                }
                                value="Existing"
                                name="transformerToMeterInstall"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Existing
                              </label>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Distance (Feet)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setTransformerToMeterDistanceFeet(
                                      e.target.value
                                    )
                                  }
                                  value={transformerToMeterDistanceFeet}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditPowerDistributionSummary ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editPowerDistributionSummary}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() =>
                        setEditPowerDistributionSummaryModel(false)
                      }
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={addDirectoryLinksModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setAddDirectoryLinksModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Add Additional Links
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Link name
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    let link = { ...additionalLink };
                                    link.name = e.target.value;
                                    setAdditionalLink(link);
                                  }}
                                  value={additionalLink.name}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Link value
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    let link = { ...additionalLink };
                                    link.value = e.target.value;
                                    setAdditionalLink(link);
                                  }}
                                  value={additionalLink.value}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingAddDirectoryLink ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={addDirectoryLinks}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setAddDirectoryLinksModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editDirectoryLinksModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditDirectoryLinksModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Directory Links
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          {additionalLinks?.map((link, i) => (
                            <div
                              key={i}
                              className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                            >
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  {link.name}
                                </label>
                                <div className="grid grid-cols-12 gap-0  ">
                                  <div className="col-span-12 md:col-span-11">
                                    <div className="mt-0 relative">
                                      <input
                                        type="text"
                                        id={i}
                                        onChange={(e) => {
                                          let links = [...additionalLinks];
                                          links[i].value = e.target.value;
                                          setAdditionalLinks(links);
                                        }}
                                        value={link.value}
                                        className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-span-12 md:col-span-1">
                                    <button
                                      onClick={(e) => {
                                        let links = [...additionalLinks];

                                        links.splice(i, 1);
                                        setAdditionalLinks(links);
                                      }}
                                      //className=" inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                                    >
                                      <FaRegTrashAlt
                                        size="1.8rem"
                                        className="inline-flex text-gray-500 text-center f-f-r pl-2 pt-2"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditDirectoryLinks ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={updateLinksWithShortenedUrls}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditDirectoryLinksModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editLogisticsModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditLogisticsModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Logistics
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Target Delivery Date
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="date"
                                  id="birthday"
                                  name="birthday"
                                  onChange={(e) =>
                                    setTargetDeliveryDate(e.target.value)
                                  }
                                  value={targetDeliveryDate}
                                  className="p-3 easi-grid-datepicker block w-full pl-[30px] pr-4 relative rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Equipment
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setEquipment(e.target.value)}
                                  value={equipment}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Loading Method
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setLoadingMethod(e.target.value)
                                  }
                                  value={loadingMethod}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Shipping Method
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setShippingMethod(e.target.value)
                                  }
                                  value={shippingMethod}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Unloading Method
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setUnloadingMethod(e.target.value)
                                  }
                                  value={unloadingMethod}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                System Placement
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setSystemPlacement(e.target.value)
                                  }
                                  value={systemPlacement}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Preferred Unloading Method
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setPreferredUnloadingMethod(e.target.value)
                                  }
                                  value={preferredUnloadingMethod}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Customer Shipping Max Budget
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setCustomerShippingMaxBudget(e.target.value)
                                  }
                                  value={customerShippingMaxBudget}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Miscellaneous Items Being Shipped
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setMiscellaneousItemsBeingShipped(
                                      e.target.value
                                    )
                                  }
                                  value={miscellaneousItemsBeingShipped}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          {/* New */}

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Site Directions
                              </label>
                              <div className="mt-0">
                                <textarea
                                  onChange={(e) =>
                                    setSite_directions(e.target.value)
                                  }
                                  value={site_directions}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 smbase sm:leading-6"
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Site Distance from HQ (miles)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setSite_distance_miles(e.target.value)
                                  }
                                  value={site_distance_miles}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Site Distance from HQ (drive time)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setSite_distance_drive(e.target.value)
                                  }
                                  value={site_distance_drive}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Access Road Flags or Cautions
                              </label>
                              <div className="mt-0">
                                <textarea
                                  onChange={(e) =>
                                    setAccess_road_flags(e.target.value)
                                  }
                                  value={access_road_flags}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 smbase sm:leading-6"
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Publicly Accessible?
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setPublicly_accessible(e.target.value)
                                  }
                                  value={publicly_accessible}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Locked Gated Access Road?
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setLocked_access_road(e.target.value)
                                  }
                                  value={locked_access_road}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Key/Lock Access Code
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setLock_access_code(e.target.value)
                                  }
                                  value={lock_access_code}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Procurement Holding Yard
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setProcurement_yard(e.target.value)
                                  }
                                  value={procurement_yard}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditLogistics ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editLogistics}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditLogisticsModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editElectricLoadModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditElectricLoadModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Electric Load
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Average Daily Usage (kWh/day)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setAverageDailyUsage(e.target.value)
                                  }
                                  value={averageDailyUsage}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          {/*                           <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Peak Demand
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setPeakDemand(e.target.value)
                                  }
                                  value={peakDemand}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Average Demand
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setAverageDemand(e.target.value)
                                  }
                                  value={averageDemand}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Continuous Load - Apparent Power (kVA)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setApparentPowerContinuous(e.target.value)
                                  }
                                  value={apparentPowerContinuous}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Continuous Load - Real Power (kW)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setRealPowerContinuous(e.target.value)
                                  }
                                  value={realPowerContinuous}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Continuous Load - Current (Amps)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setCurrentContinuous(e.target.value)
                                  }
                                  value={currentContinuous}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Transient Current (A)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setMaxCurrentTransient(e.target.value)
                                  }
                                  value={maxCurrentTransient}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Transient Apparent Power (kVA)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setApparentPowerTransient(e.target.value)
                                  }
                                  value={apparentPowerTransient}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Transient Duration (mSec)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setMaxTransientDuration(e.target.value)
                                  }
                                  value={maxTransientDuration}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditElectricLoad ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editElectricLoad}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditElectricLoadModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editMilestoneModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditMilestoneModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Milestone Date Achieved
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Milestone Name
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setMilestoneName(e.target.value)
                                  }
                                  value={milestoneName}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Target Date
                              </label>
                              <div className="mt-0">
                                <input
                                  type="date"
                                  onChange={(e) =>
                                    setTargetData(e.target.value)
                                  }
                                  value={targetData}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Date Achieved
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  type="date"
                                  onChange={(e) =>
                                    setDateAchieved(e.target.value)
                                  }
                                  value={dateAchieved}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={submitMilestone}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditMilestoneModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editProjectInformationModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditProjectInformationModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-semibold leading-6 text-gray-900"
                        >
                          Edit Project Information
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project Name{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setProjectName(e.target.value)
                                  }
                                  value={projectName}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project Summary{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-2">
                                <textarea
                                  onChange={(e) =>
                                    setProjectSummary(e.target.value)
                                  }
                                  value={projectSummary}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 smbase sm:leading-6"
                                ></textarea>
                              </div>
                              {/* <div className="mt-0">
                                <input type="text" onChange={e => setProjectSummary(e.target.value)} value={projectSummary} className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                              </div> */}
                            </div>
                          </div>

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project Contacts{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                {project?.contacts?.length ? (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      width: "55%",
                                    }}
                                  >
                                    {(
                                      projectContacts || project?.contacts
                                    )?.map(
                                      (
                                        contact,
                                        i // projectContacts
                                      ) => (
                                        <span key={i}>
                                          {contact.first_name},{" "}
                                        </span>
                                      )
                                    )}
                                  </div>
                                ) : null}

                                <div
                                  style={{
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                    width: "45%",
                                  }}
                                >
                                  <Dilogbox
                                    onReceiveContact={_onReceiveContact}
                                    contacts={project?.contacts}
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project type
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setProjectType(e.target.value)
                                  }
                                  value={projectType}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Turnkey">Turnkey</option>
                                  <option value="Equipment">Equipment</option>
                                  <option value="Equipment + Consulting">
                                    Equipment + Consulting
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div> */}

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Client Name
                              </label>
                              <div className="mt-0">
                                {project?.utility ? (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      width: "55%",
                                    }}
                                  >
                                    {projectUtilities.length ? (
                                      projectUtilities.map((utility, i) => (
                                        <span key={i}>
                                          {`${utility.utility_full_name} (${utility.utility_name})`}{" "}
                                        </span>
                                      ))
                                    ) : (
                                      <span>
                                        {`${project?.utility?.utility_full_name} (${project?.utility?.utility_name})`}{" "}
                                      </span>
                                    )}
                                  </div>
                                ) : null}

                                <div
                                  style={{
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                    width: "45%",
                                  }}
                                >
                                  <UtilityDilogbox
                                    onReceiveUtility={_onReceiveUtility}
                                    utilities={[project?.utility]}
                                  />
                                </div>
                              </div>
                              {/* <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setUtilityName(e.target.value)
                                  }
                                  value={utilityName}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="PG&E">PG&E</option>
                                  <option value="SCE">SCE</option>
                                  <option value="Liberty">Liberty</option>
                                  <option value="SDG&E">SDG&E</option>
                                  <option value="Pacificorp">Pacificorp</option>
                                  <option value="Puget Sound Energy">
                                    Puget Sound Energy
                                  </option>
                                  <option value="Holston Electric Cooperative">
                                    Holston Electric Cooperative
                                  </option>
                                  <option value="ATCO">ATCO</option>
                                </select>
                              </div> */}
                              {/*                               <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setUtilityName(e.target.value)
                                  }
                                  value={utilityName}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div> */}
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Contract GFC date
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="date"
                                  onChange={(e) =>
                                    setContractGFCDate(e.target.value)
                                  }
                                  value={contractGFCDate}
                                  className="p-3 easi-grid-datepicker pl-[30px] pr-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              {/*                               <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setContractGFCDate(e.target.value)
                                  }
                                  value={contractGFCDate}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div> */}
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Customer type
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setCustomerType(e.target.value)
                                  }
                                  value={customerType}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Utility">Utility</option>
                                  <option value="EV">EV</option>
                                  <option value="Telecom">Telecom</option>
                                </select>
                              </div>
                              {/*                               <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setCustomerType(e.target.value)
                                  }
                                  value={customerType}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div> */}
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project budget
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setProjectBudget(e.target.value)
                                  }
                                  value={projectBudget}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Contract value
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setContactValue(e.target.value)
                                  }
                                  value={contactValue}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Contract NTP date
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="date"
                                  onChange={(e) =>
                                    setContractDate(e.target.value)
                                  }
                                  value={contractDate}
                                  className="p-3 easi-grid-datepicker pl-[30px] pr-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Additional Project Notes
                              </label>
                              <div className="mt-0">
                                <textarea
                                  onChange={(e) =>
                                    setProjectNotes(e.target.value)
                                  }
                                  value={projectNotes}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 smbase sm:leading-6"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Project Status
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setProjectStatus(e.target.value)
                                  }
                                  value={projectStatus}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Deployed">Deployed</option>
                                  <option value="Design Stage">
                                    Design Stage
                                  </option>
                                  <option value="Construction/Commissioning">
                                    Construction/Commissioning
                                  </option>
                                  <option value="Dead">Dead</option>
                                </select>
                              </div>
                            </div>
                          </div> */}
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Contract Number
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setContractNumber(e.target.value)
                                  }
                                  value={contractNumber}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                PM Number
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setPMNumber(e.target.value)}
                                  value={PMNumber}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                PO Number
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setPONumber(e.target.value)}
                                  value={PONumber}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editProjectInformation}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditProjectInformationModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={addOthersModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setAddOthersModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Add Others
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Label
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    let other = { ...additionalOther };
                                    other.label = e.target.value;
                                    setAdditionalOther(other);
                                  }}
                                  value={additionalOther.name}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Value
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    let other = { ...additionalOther };
                                    other.value = e.target.value;
                                    setAdditionalOther(other);
                                  }}
                                  value={additionalOther.value}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingAddOther ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={addOthers}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setAddOthersModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editOthersModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditOthersModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Others
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          {additionalOthers?.map((other, i) => (
                            <div
                              key={i}
                              className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                            >
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  {other.label}
                                </label>
                                <div className="grid grid-cols-12 gap-0  ">
                                  <div className="col-span-12 md:col-span-11">
                                    <div className="mt-0 relative">
                                      <input
                                        type="text"
                                        id={i}
                                        onChange={(e) => {
                                          let others = [...additionalOthers];
                                          others[i].label = e.target.value;
                                          setAdditionalOthers(others);
                                        }}
                                        value={other.label}
                                        className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-span-12 md:col-span-1">
                                    <button
                                      onClick={(e) => {
                                        let others = [...additionalOthers];

                                        others.splice(i, 1);
                                        setAdditionalOthers(others);
                                      }}
                                      //className=" inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                                    >
                                      <FaRegTrashAlt
                                        size="1.8rem"
                                        className="inline-flex text-gray-500 text-center f-f-r pl-2 pt-2"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditOthers ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editOthers}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditOthersModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

import React, { useState } from "react";
import "react-phone-number-input/style.css";
import Config from "../../utils/Config";
import { useSelector } from "react-redux";

import swal from "sweetalert";

export default function ViewDataDialogbox(props) {
  // const user = useSelector((state) => state.user.profile);

  const site = props.site;
  const scenario = props.scenario;

  const [ showModal, setShowModal ] = useState(false);
  
  return (
    <div>
        <button 
            onClick={() => setShowModal(true)}
            className="mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary"
        >
            View Data
        </button>
      

      { showModal ? (
        <>
          <div className="dilog-box" onClick={() => setShowModal(false)}></div>
          <div
            id="defaultModal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed top-[16%] lg:top-0  right-0 z-50  ml-auto w-[85%] md:w-[93%] lg:w-full  p-4 overflow-x-hidden overflow-y-auto inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative w-full max-w-4xl max-h-full ml-auto mr-auto ">
              <div className="relative bg-white rounded-lg shadow  p-10 ">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className=" absolute top-[19px] right-[19px]  text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-hide="defaultModal"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.918759 13.0577C0.360088 13.6164 0.360088 14.5222 0.918759 15.0809C1.47743 15.6395 2.38321 15.6395 2.94189 15.0809L7.99981 10.0229L13.0579 15.0811C13.6166 15.6397 14.5224 15.6397 15.081 15.0811C15.6397 14.5224 15.6397 13.6166 15.081 13.0579L10.0229 7.99982L15.0806 2.94213C15.6393 2.38346 15.6393 1.47767 15.0806 0.919002C14.522 0.360332 13.6162 0.360333 13.0575 0.919003L7.99981 5.9767L2.9423 0.919193C2.38363 0.360523 1.47784 0.360523 0.919172 0.919193C0.360502 1.47786 0.360503 2.38365 0.919174 2.94232L5.97668 7.99982L0.918759 13.0577Z"
                      fill="#FA3030"
                    />
                  </svg>
                </button>
                
                <>
                    {/*.................  add exisiting started ................*/}

                    {/* <div className=" mt-8 ">
                        

                        <h1>H1 Data here</h1>
                        
                    </div> */}


                    <div
                        className=" bg-white   "  //**shadow-lg p-[20px] rounded-[8px]
                        style={{ position: "relative" }}
                        >
                        
                        <h2 className=" text-center f-f-sm text-lg text-black my-2  ">Site Information</h2>
                        
                            <>
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Site Name
                                <span className=" float-right text-black ">
                                {site?.site_name}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px] overflow-auto ">
                                Site Contacts:
                                <span className=" float-right text-black break-words ">
                                {site?.contacts?.map((contact, i) => (
                                    <span key={i}>
                                    {contact.first_name} {contact.last_name},{" "}
                                    </span>
                                ))}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px] overflow-auto ">
                                Site Address{" "}
                                <span className=" float-right text-black break-words">
                                {site?.site_address}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                GPS Coordinates
                                <span className=" float-right text-black ">
                                {site?.site_gps}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                County:
                                <span className=" float-right text-black ">
                                {site?.county}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Ratepayer Type
                                <span className=" float-right text-black ">
                                {site?.ratepayer_type}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Landowner
                                <span className=" float-right text-black ">
                                {site?.landowner}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Critical Load
                                <span className=" float-right text-black ">
                                {site?.critical_load}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Generator Use
                                <span className=" float-right text-black ">
                                {site?.generator_use}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Container Weight
                                <span className=" float-right text-black ">
                                {site?.container_weight}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Building Code
                                <span className=" float-right text-black ">
                                {site?.building_code}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Site Fence
                                <span className=" float-right text-black ">
                                {site?.site_fence === 1 ? "True" : "False"}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Foundation Type
                                <span className=" float-right text-black ">
                                {site?.foundation_type}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Additional Foundation Requirements
                                <span className=" float-right text-black ">
                                {site?.foundation_requirements}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Voltage
                                <span className=" float-right text-black ">
                                {site?.service_voltage}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Phase
                                <span className=" float-right text-black ">
                                {site?.power_phase}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Number of Wires
                                <span className=" float-right text-black ">
                                {site?.number_of_wires}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Wiring Configuration
                                <span className=" float-right text-black ">
                                {site?.wiring_configuration}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Connection Type
                                <span className=" float-right text-black ">
                                {site?.connection_type}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                                Miles Line Removed
                                <span className=" float-right text-black ">
                                {site?.miles_line_removed}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>


                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Minimum Temperature (C){" "}
                                <span className=" float-right text-black ">
                                {Number(site?.location_minimum_temperature).toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Maximum Temperature (C){" "}
                                <span className=" float-right text-black ">
                                {Number(site?.location_maximum_temperature).toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Minimum Humidity (%){" "}
                                <span className=" float-right text-black ">
                                {Number(site?.location_minimum_humidity).toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Maximum Humidity (%){" "}
                                <span className=" float-right text-black ">
                                {Number(site?.location_maximum_humidity).toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Snow Load (lb/sqft)
                                <span className=" float-right text-black ">
                                {Number(site?.snow_load).toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Wind Speed (mph)
                                <span className=" float-right text-black ">
                                {Number(site?.wind_speed).toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Soil Type
                                <span className=" float-right text-black ">
                                {site?.soil_type}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Spectral Response (Short, S/S)
                                <span className=" float-right text-black ">
                                {Number(site?.seismic_spectral_response_short).toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Spectral Response (1 Sec, S/1)
                                <span className=" float-right text-black ">
                                {Number(site?.seismic_spectral_response_second).toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Seismic Importance Factor
                                <span className=" float-right text-black ">
                                {site?.seismic_importance_factor}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Seismic Site Class
                                <span className=" float-right text-black ">
                                {site?.seismic_site_class}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Design Spectral Response (S/DS)
                                <span className=" float-right text-black ">
                                {Number(site?.design_spectral_response_short).toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Design Spectral Response (S/D1)
                                <span className=" float-right text-black ">
                                {Number(site?.design_spectral_response_second).toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Seismic Design Category
                                <span className=" float-right text-black ">
                                {site?.seismic_design_category}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* New */}
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Elevation (ft.)
                                <span className=" float-right text-black ">
                                {site?.elevation}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Wind Exposure Category
                                <span className=" float-right text-black ">
                                {site?.wind_exposure_category}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Snow Height (ft.)
                                <span className=" float-right text-black ">
                                {Number(site?.snow_height).toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Risk Category
                                <span className=" float-right text-black ">
                                {site?.risk_category}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                Geologic Hazard Zone
                                <span className=" float-right text-black ">
                                {site?.geologic_hazard_zone === 1 ? "true" : "false"}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                State Seismic Landslide Zone
                                <span className=" float-right text-black ">
                                {site?.state_seismic_landslide_zone === 1 ? "true" : "false"}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                                State Seismic Liquefaction Zone
                                <span className=" float-right text-black ">
                                {site?.state_seismic_liquefaction_zone === 1
                                    ? "true"
                                    : "false"}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Project Type
                                <span className=" float-right text-black ">
                                {site?.general_stage}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Project Status
                                <span className=" float-right text-black ">
                                {site?.general_status}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            <h3 className="  f-f-r text-lg text-black-vlight  ">
                                Average Daily Usage (kWh/day){" "}
                                <span className=" float-right text-black ">
                                {site?.average_daily_usage?.toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            {/*           <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Peak Demand (kW)
                                <span className=" float-right text-black ">
                                {site?.peak_demand?.toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            {/* 
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Average Demand (kW)
                                <span className=" float-right text-black ">
                                {site?.average_demand?.toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div> */}

                            {/* start */}
                            {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Max Continuous Load - Apparent Power (kVA)
                                <span className=" float-right text-black ">
                                {site?.apparent_power_continuous?.toFixed(2)}
                                </span>{" "}
                            </h3> 
                            <div className=" border border-white-vlight my-1 "></div>*/}
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Max Continuous Load - Real Power (kW)
                                <span className=" float-right text-black ">
                                {site?.real_power_continuous?.toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Max Continuous Load - Current (Amps)
                                <span className=" float-right text-black ">
                                {site?.current_continuous?.toFixed(2)}
                                </span>{" "}
                            </h3> 
                            <div className=" border border-white-vlight my-1 "></div>*/}
                            {/* start */}
                            {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Max Transient Current (A)
                                <span className=" float-right text-black ">
                                {site?.max_current_transient?.toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div> */}
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Max Transient Apparent Power (kVA)
                                <span className=" float-right text-black ">
                                {site?.apparent_power_transient?.toFixed(2)}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Shipping Method
                                <span className=" float-right text-black ">
                                {site?.shipping}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Unloading Method
                                <span className=" float-right text-black ">
                                {site?.unloading}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                System Placement
                                <span className=" float-right text-black ">
                                {site?.system_placement}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Preferred Unloading Method
                                <span className=" float-right text-black ">
                                {site?.preferred_unloading_method}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div> */}
                            {/* start */}
                            {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Customer Shipping Max Budget
                                <span className=" float-right text-black ">
                                ${site?.customer_shipping_max_budget}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div> */}
                            {/* start */}
                            {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Miscellaneous Items Being Shipped
                                <span className=" float-right text-black ">
                                {site?.miscellaneous_items_being_shipped}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div> */}
                            {/* New */}
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Site Directions
                                <span className=" float-right text-black ">
                                {site?.site_directions}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Site Distance from HQ (miles)
                                <span className=" float-right text-black ">
                                {site?.site_distance_miles}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Site Distance from HQ (drive time)
                                <span className=" float-right text-black ">
                                {site?.site_distance_drive}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Access Road Flags or Cautions
                                <span className=" float-right text-black ">
                                {site?.access_road_flags}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Publicly Accessible?
                                <span className=" float-right text-black ">
                                {site?.publicly_accessible}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Locked Gated Access Road?
                                <span className=" float-right text-black ">
                                {site?.locked_access_road}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Key/Lock Access Code
                                <span className=" float-right text-black ">
                                {site?.lock_access_code}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                                Procurement Holding Yard
                                <span className=" float-right text-black ">
                                {site?.procurement_yard}
                                </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>

                            <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                            Solar PV Capacity (kW)
                            <span className=" float-right text-black ">
                                {scenario?.solar_pv_capacity}
                            </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                            Average PV Tilt
                            <span className=" float-right text-black ">
                                {scenario?.pv_tilt}
                            </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                            Average PV Azimuth
                            <span className=" float-right text-black ">
                                {scenario?.pv_azimuth}
                            </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                            Battery Storage Capacity (kWh)
                            <span className=" float-right text-black ">
                                {scenario?.battery_storage_capacity}
                            </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                            Battery Inverter Capacity (kW)
                            <span className=" float-right text-black ">
                                {scenario?.battery_inverter_capacity}
                            </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                            Generator Quantity
                            <span className=" float-right text-black ">
                                {scenario?.generator_quantity}
                            </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                            Generator Size (kW)
                            <span className=" float-right text-black ">
                                {scenario?.generator_size}
                            </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                            Renewable Fraction (%)
                            <span className=" float-right text-black ">
                                {scenario?.model_renewable_fraction}
                            </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                            Generator Runtime (hours)
                            <span className=" float-right text-black ">
                                {scenario?.generator_runtime}
                            </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                            Fuel Consumption (gallons)
                            <span className=" float-right text-black ">
                                {scenario?.fuel_consumption}
                            </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            {/* start */}
                            <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                            Fuel Tank Size (gallons)
                            <span className=" float-right text-black ">
                                {scenario?.fuel_tank_size}
                            </span>{" "}
                            </h3>
                            <div className=" border border-white-vlight my-1 "></div>
                            </>
                        
                        </div>

                    {/* button started */}
                    {/* 
                    <div className=" mt-3 text-end  ">
                        
                        <button
                            onClick={() => { 
                                setShowModal(false);

                                if (props.onEditContactSubmit) {
                                    props.onEditContactSubmit();
                                }
                            }}
                            className=" bg-white text-base text-primary border border-primary w-[127px] h-[40px] rounded-[20px] ml-1 captilize "
                        >
                            Done
                        </button>
                        
                    </div> 
                    */}

                    {/* .......................... addd existing ended ............... */}
                </>
                
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import Sidebardata from '../home/Sideebar'
import Header from '../home/header'
import Hero from './Hero'
import Actioncard from './Actioncard'
import SystemArchitecture from './SystemArchitecture'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Config from '../../utils/Config';

import useSWR from 'swr';
// import { fetcher } from '../../utils/fetcher'; // Custom fetcher function

import { setHomer } from "../../redux/slices/helperSlice";

import Loading from "../../Website/Loading";

import useFetchData from '../hooks/useFetchData';

// const fetcher = async ({ url, token }) => {

//     // console.log('url:', url);
//     // console.log('token:', token);
//     const response = await fetch(url, {
//         headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//         },
//     });

//     if (!response.ok) {
//         const error = new Error('An error occurred while fetching the data.');
//         error.status = response.status;
//         throw error;
//     }

//     return response.json();
// };

export default React.memo(function Index() {
    const dispatch = useDispatch();
    const { scenario_id } = useParams();
    const user = useSelector((state) => state.user.profile);
    const navigate = useNavigate();
    const [ scenario, setScenario ] = React.useState({});
    const [ site, setSite ] = React.useState({});
    // const [ homer, setHomer ] = React.useState(null);
    const [ loading, setLoading ] = React.useState(false);
    const [ products, setProducts ] = React.useState([]);

    const [ optimizationTab, setOptimizationTab ] = React.useState("microgrid-architecture");
    
    const { homer } = useSelector((state) => state.helper);

    React.useEffect(() => {
        return () => {
            console.log('Leaving screen')
            dispatch(setHomer(null));
        }
    }, []);

    const { data : productsData, error : productsError, isLoading: productsIsLoading } = useFetchData('/products?sort_by=id&sort_direction=asc');

    // const { data, error, isLoading } = useSWR({ url: `${Config.API.URL}/products?sort_by=id&sort_direction=asc`, token: user.token }, fetcher);
    
    // Update products state only when data changes
    React.useEffect(() => {
        // console.log('productsData', productsData);
        if (productsData?.status) {
            setProducts(productsData.data);
        }
    }, [productsData]);

    const { data : scenarioData, error: scenarioError, isLoading: scenarioIsLoading } = useFetchData(`/scenarios/${scenario_id}`);

    React.useEffect(() => {
        // console.log('scenarioData:', scenarioData);
        if (scenarioData?.status) {
            setScenario(scenarioData.data);
        }
    }, [scenarioData]);
    
    const { data : siteData, error: siteError, isLoading: siteIsLoading } = useFetchData(scenarioData?.data?.site_id ? `/sites/${scenarioData.data.site_id}` : null);

    React.useEffect(() => {
        // console.log('siteData', siteData);
        if (siteData?.status) {
            setSite(siteData.data);
        }
    }, [siteData]);

    const { data : homerData, error: homerError, isLoading: homerIsLoading } = useFetchData((!homer && (optimizationTab === 'optimization-charts' || optimizationTab === 'system-sizing')) ? `/system-optimization/homer-file-summary/${scenario_id}` : null);

    React.useEffect(() => {
        // console.log('homerData', homerData);
        if (homerData?.status) {
            dispatch(setHomer(homerData.data));
        }
    }, [homerData]);

    // React.useEffect(() => {
    //     setLoading(true);
    //     fetch(`${Config.API.URL}/scenarios/${scenario_id}`, {
    //         method: 'GET',
    //         headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }
    //     }).then((res) => {
    //         setLoading(false);
    //         // if 401 error (unauthenticated user, then log user out)
    //         if ((res.status) === 401) {
    //             console.log("401 Error: Unauthenticated");

    //             localStorage.removeItem('user');
    //             navigate('/');
    //         }
    //         return res;
    //     }).then((res) => res.json()).then( async (res) => {
            
    //         // console.log('scenarios-res:', res);

    //         // if request is succesful, alert user that project has been cloned
    //         if (res.status) {
    //             const data = res.data;
    //             setScenario(data);

    //             const siteReq = await fetch(`${Config.API.URL}/sites/${data.site_id}`, { headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }});
    //             const siteRes = await siteReq.json();

    //             if (siteRes.status) {
    //                 setSite(siteRes.data);
    //             } 

    //             const productsReq = await fetch(`${Config.API.URL}/products?sort_by=id&sort_direction=asc`, { headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }});
    //             const productsRes = await productsReq.json();

    //             if (productsRes.status) {
    //                 setProducts(productsRes.data);
    //             }
                
    //             // console.log('productsRes:', productsRes) 

    //             // console.log('homerRes 1:', homerRes);

    //             // const homersReq = await fetch(`${Config.API.URL}/system-optimization/homer-files-summaries/${scenario_id}`, { headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }});
    //             // const homersRes = await homersReq.json();

    //             // console.log('homersRes 2:', homersRes)

    //             // target_renewable
    //             // site_fence
    //         } else {
    //             //swal("Oh no!", res.message, "error");
    //         }

    //         setLoading(false);

    //     }).catch((error) => {
    //         setLoading(false);
    //         console.log('Error: ', error);
    //     });
        
    // }, [scenario_id, user, navigate]);

    // React.useEffect(() => {
    //     console.log('optimizationTab:', optimizationTab);

    //     async function fetchData() {
    //         try {
    //             const homerReq = await fetch(`${Config.API.URL}/system-optimization/homer-file-summary/${scenario_id}`, { headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }});
    //             const homerRes = await homerReq.json();
    
    //             if (homerRes.status) {
    //                 dispatch(setHomer(homerRes.data));
    //             }
    //         } catch (error) {
    //             console.log("Error fetching data:", error);
    //         }
    //     }

    //     if (optimizationTab === 'optimization-charts' || optimizationTab === 'system-sizing') {
    //         fetchData(); // Immediately invoke the async function
    //     }
    // }, [ optimizationTab ]);

    return (
        <>
            <div className='grid grid-cols-12  ' >
                <div className=' lg:col-span-2'>
                    <Sidebardata />
                </div>
                <div className=" transition-none col-span-12 lg:col-span-12 mr-3  ">
                    <div className=' w-[85%] sm:w-[95%] ml-auto ' >
                        <Header />
                        { scenarioIsLoading ?
                            <Loading />
                        :
                            <>
                                <Hero scenario={scenario} />
                                <Actioncard 
                                    scenario={scenario} 
                                    site={site} 
                                    homer={homer} 
                                    onReceiveScenario={setScenario} 
                                    products={products}
                                    setOptimizationTab={setOptimizationTab} 
                                /> 
                                {/* onReceiveHomer={setHomer} */}
                                {/* <SystemArchitecture scenario={scenario} /> */}
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
});

import { useAuth0 } from '@auth0/auth0-react';
import useSWR from "swr";

import Config from '../../utils/Config';

async function fetcher(key, token) {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };
      const res = await fetch(`${Config.API.URL}${key}`, config);
      const data = await res.json();
      // console.log('data:', data);
      return data;
}


export default function useFetchData(key) {
    
    const { getAccessTokenSilently } = useAuth0();
 
    const { data, isLoading, error, isValidating, mutate } = useSWR(
        key,
        async () => {
            const token = await getAccessTokenSilently();
            return fetcher(key, token);
        },
        { revalidateOnFocus: false },
    );
    
    return { data, isLoading, error, isValidating, mutate };
}
import React, { Fragment, useRef, useState, useEffect } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import Config from "../../utils/Config";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

import { Chart } from "react-google-charts";

import ExcelLogo from "../../assets/mse-excel-logo.png";
import UploadIcon from "../../assets/upload-icon.png";
import Spinner from "../../assets/spinner/spinner.gif";

import "./Actioncard.css";

import DialogBoxBatteryProducts from "./DialogBoxBatteryProducts";
import DialogBoxChargecontrollerProducts from "./DialogBoxChargecontrollerProducts";
import DialogBoxGeneratorProducts from "./DialogBoxGeneratorProducts";
import DialogBoxInverterProducts from "./DialogBoxInverterProducts";
import DialogBoxMountingProducts from "./DialogBoxMountingProducts";
import DialogBoxSolarProducts from "./DialogBoxSolarProducts";

import { ScenarioContext } from "../../utils/Context";

//Bar Chart
export const BarChartData = [
  [
    "Time - Month",
    "SUM of AC Primary Load",
    "SUM of Solar Energy",
    "SUM of AC Generator Energy Output",
    "SUM of Battery Energy",
  ],
  ["Jan", 1000, 400, 200, 150],
  ["Feb", 1170, 460, 250, 200],
  ["Mar", 660, 1120, 300, 250],
  ["Apr", 1030, 540, 350, 300],
  ["May", 1030, 540, 350, 300],
  ["Jun", 1030, 540, 350, 300],
  ["Jul", 1030, 540, 350, 300],
  ["Aug", 1030, 540, 350, 300],
  ["Sep", 1030, 540, 350, 300],
  ["Oct", 1030, 540, 350, 300],
  ["Nov", 1030, 540, 350, 300],
  ["Dec", 1030, 540, 350, 300],
];

export const BarChartOptions = {
  chart: {
    title: "Projected Monthly Energy Generation and Usage",
    legend: { position: "top" },
    //subtitle: "Sales, Expenses, and Profit: 2014-2017",
  },
};

// Line Chart
export const LineChartData = [
  [
    "Time - Hour",
    "SUM of AC Primary Load",
    "SUM of Solar Power Output",
    "SUM of AC Generator Power Output",
    "SUM of Battery Discharge Power",
  ],
  ["00:00", 1000, 800, 500, 400],
  ["02:00", 1170, 950, 640, 460],
  ["04:00", 200, 660, 800, 1120],
  ["06:00", 1030, 900, 700, 540],
  ["08:00", 1032, 900, 700, 540],
  ["10:00", 1034, 900, 700, 540],
  ["12:00", 1036, 900, 700, 540],
  ["14:00", 1038, 900, 700, 540],
  ["16:00", 1040, 900, 700, 540],
  ["18:00", 1042, 900, 700, 540],
  ["20:00", 1044, 900, 700, 540],
  ["22:00", 1046, 900, 700, 540],
];

export const LineChartOptions = {
  title: "Projected Hourly Load Summary",
  curveType: "function",
  legend: { position: "top" },
};

export default React.memo(function Actioncard(props) {
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const user = useSelector((state) => state.user.profile);

  const { uploadHomerAsyn } = React.useContext(ScenarioContext);

  const [currentInverterProductTab, setCurrentInverterProductTab] = useState(0);
  const [currentGeneratorProductTab, setCurrentGeneratorProductTab] =
    useState(0);
  const [currentSolarProductTab, setCurrentSolarProductTab] = useState(0);
  const [currentBatteryProductTab, setCurrentBatteryProductTab] = useState(0);
  const [currentMountingProductTab, setCurrentMountingProductTab] = useState(0);
  const [
    currentChargeControllerProductTab,
    setCurrentChargeControllerProductTab,
  ] = useState(0);

  const [editSiteInformationModel, setEditSiteInformationModel] =
    useState(false);
  const [editOptimizationSummaryModel, setEditOptimizationSummaryModel] =
    useState(false);

  const [editInverterProductModel, setEditInverterProductModel] =
    useState(false);
  const [editGeneratorProductModel, setEditGeneratorProductModel] =
    useState(false);
  const [editFenceProductModel, setEditFenceProductModel] = useState(false);
  const [editSolarProductModel, setEditSolarProductModel] = useState(false);
  const [editBatteryProductModel, setEditBatteryProductModel] = useState(false);
  const [editMountingProductModel, setEditMountingProductModel] =
    useState(false);
  const [
    editChargeControllerProductModel,
    setEditChargeControllerProductModel,
  ] = useState(false);

  const [fileUploadInstructionsModel, setFileUploadInstructionsModel] =
    useState(false);

  const [scenario, setScenario] = useState({ ...props.scenario });

  const [site, setSite] = React.useState({ ...props.site });

  const [PVTilt, setPVTilt] = React.useState("");
  const [PVAzimuth, setPVAzimuth] = React.useState("");

  const [solarPVCapacity, setSolarPVCapacity] = React.useState("");
  const [batteryStorageCapacity, setBatteryStorageCapacity] =
    React.useState("");
  const [batteryInverterCapacity, setBatteryInverterCapacity] =
    React.useState("");
  const [generatorQuantity, setGeneratorQuantity] = React.useState("");
  const [generatorSize, setGeneratorSize] = React.useState("");
  const [renewableFraction, setRenewableFraction] = React.useState("");
  const [generatorRuntime, setGeneratorRuntime] = React.useState("");
  const [fuelConsumption, setFuelConsumption] = React.useState("");
  const [fuelTankSize, setFuelTankSize] = React.useState("");

  const [loadingEditSiteInformation, setLoadingEditSiteInformation] =
    React.useState(false);
  const [loadingEditOptimizationSummary, setLoadingEditOptimizationSummary] =
    React.useState(false);

  const [loadingEditSolarProduct, setLoadingEditSolarProduct] =
    React.useState(false);
  const [loadingEditInverterProduct, setLoadingEditInverterProduct] =
    React.useState(false);
  const [loadingEditBatteryProduct, setLoadingEditBatteryProduct] =
    React.useState(false);
  const [loadingEditGeneratorProduct, setLoadingEditGeneratorProduct] =
    React.useState(false);
  const [loadingEditMountingProduct, setLoadingEditMountingProduct] =
    React.useState(false);
  const [
    loadingEditChargeControllerProduct,
    setLoadingEditChargeControllerProduct,
  ] = React.useState(false);
  const [loadingEditFenceProduct, setLoadingEditFenceProduct] =
    React.useState(false);

  const [solarProduct, setSolarProduct] = React.useState({
    solar_product_name: "",
    solar_product_quantity: 0,
    solar_product_unit_capacity: 0,
    solar_product_label: "",
    solar_product_notes: "",
    solar_product_total_capacity: 0,
    solar_product_manufacturer: "",
    solar_product_model: "",
    solar_product_part_number: "",
    solar_product_certifications: "",
    solar_product_tilt: 0,
    solar_product_azimuth: 0,
    solar_product_string_length: 0,
    solar_product_string_quantity: 0,
    solar_product_pmax: 0,
    solar_product_vmp: 0,
    solar_product_imp: 0,
    solar_product_voc: 0,
    solar_product_isc: 0,
    solar_product_voc_min_temp: 0,
    solar_product_isc_min_temp: 0,
    others: [],
  });
  const [batteryProduct, setBatteryProduct] = React.useState({
    battery_product_name: "",
    battery_product_quantity: 0,
    battery_product_unit_capacity: 0,
    battery_product_label: "",
    battery_product_notes: "",
    battery_product_total_capacity: 0,
    battery_product_manufacturer: "",
    battery_product_model: "",
    battery_product_part_number: "",
    battery_product_certifications: "",
    battery_product_chemistry: "",
    battery_product_dc_voltage: 0,
    battery_product_amp_hours: 0,
    battery_product_storage_capacity: 0,
    battery_product_max_discharge_rate: 0,
    battery_product_max_charge_rate: 0,
    battery_product_discharge_depth: 0,
    others: [],
  });
  const [inverterProduct, setInverterProduct] = React.useState({
    inverter_product_name: "",
    inverter_product_quantity: 0,
    inverter_product_unit_capacity: 0,
    inverter_product_label: "",
    inverter_product_notes: "",
    inverter_product_total_capacity: 0,
    inverter_product_manufacturer: "",
    inverter_product_model: "",
    inverter_product_part_number: "",
    inverter_product_certifications: "",
    inverter_product_output_power: 0,
    inverter_product_output_voltage: "",
    inverter_product_output_frequency: "",
    inverter_product_max_input_current: 0,
    inverter_product_max_output_current: 0,
    others: [],
  });
  const [mountingProduct, setMountingProduct] = React.useState({
    mounting_product_name: "",
    mounting_product_quantity: 0,
    mounting_product_unit_capacity: 0,
    mounting_product_label: "",
    mounting_product_notes: "",
    mounting_product_total_capacity: 0,
    mounting_product_manufacturer: "",
    mounting_product_model: "",
    mounting_product_part_number: "",
    mounting_product_certifications: "",
    mounting_min_clear_height: 0,
    others: [],
  });
  const [generatorProduct, setGeneratorProduct] = React.useState({
    generator_product_name: "",
    generator_product_quantity: 0,
    generator_product_unit_capacity: 0,
    generator_product_label: "",
    generator_product_notes: "",
    generator_product_total_capacity: 0,
    generator_product_manufacturer: "",
    generator_product_model: "",
    generator_product_part_number: "",
    generator_product_certifications: "",
    generator_frequency: "",
    generator_phase: "",
    generator_rated_voltage: "",
    generator_real_standby_power: 0,
    generator_apparent_standby_power: 0,
    generator_real_prime_power: 0,
    generator_apparent_prime_power: 0,
    generator_power_factor: 0,
    generator_efficiency: 0,
    generator_max_current: 0,
    generator_continuous_current: 0,
    generator_breaker_current: 0,
    others: [],
  });
  const [chargecontrollerProduct, setChargecontrollerProduct] = React.useState({
    chargecontroller_product_name: "",
    chargecontroller_product_quantity: 0,
    chargecontroller_product_unit_capacity: 0,
    chargecontroller_product_label: "",
    chargecontroller_product_notes: "",
    chargecontroller_product_total_capacity: 0,
    chargecontroller_product_manufacturer: "",
    chargecontroller_product_model: "",
    chargecontroller_product_part_number: "",
    chargecontroller_product_certifications: "",
    chargecontroller_max_pv_capacity: 0,
    chargecontroller_max_string_length: 0,
    others: [],
  });

  const [fenceProduct, setFenceProduct] = React.useState({
    fence_product_height: 0,
    fence_product_type: "",
    fence_product_topper: "",
    fence_product_sound_attenuation: "",
    fence_product_attenuation_spec: "",
    fence_product_manufacturer: "",
    fence_product_model: "",
    fence_product_part_number: "",
    others: [],
  });

  const [optimizationTab, setOptimizationTab] = React.useState(
    "microgrid-architecture"
  );

  const _setOptimizationTab = (value) => {
    setOptimizationTab(value);
    props.setOptimizationTab(value);
  };

  const [homer, setHomer] = React.useState(null);

  // const [ loading, setLoading ] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  // const [uploading, setUploading] = React.useState(false);
  // const [uploadProgress, setUploadProgress] = React.useState(0);
  // const [uploadReport, setUploadReport] = React.useState("");

  const { uploading, uploadProgress, uploadReport } = useSelector(
    (state) => state.helper
  );

  const [barChartData, setBarChartData] = React.useState([]);
  const [lineChartData, setLineChartData] = React.useState([]);

  const homer_file = React.useRef(null);

  React.useEffect(() => {
    setSite(props.site);
  }, [props.site]);

  React.useEffect(() => {
    setProducts(props.products);
  }, [props.products]);

  React.useEffect(() => {
    setHomer(props.homer);

    const barChartFormattedData = [
      [
        "Time - Month",
        "SUM of AC Primary Load",
        "SUM of Solar Energy",
        "SUM of AC Generator Energy Output",
        "SUM of Battery Energy",
      ],
    ];

    props.homer?.homer_summary?.forEach((item) => {
      barChartFormattedData.push([
        item.month.charAt(0).toUpperCase() + item.month.slice(1), // Capitalize the month name
        item.sum_of_ac_primary_load,
        item.sum_of_solar_power_output,
        item.sum_of_generator_power_output,
        item.sum_of_battery_discharge_power,
      ]);
    });

    const lineChartDataFormattedData = [
      [
        "Time - Hour",
        "SUM of AC Primary Load",
        "SUM of Solar Power Output",
        "SUM of AC Generator Power Output",
        "SUM of Battery Discharge Power",
      ],
    ];

    props.homer?.homer_hourly_summary?.forEach((item) => {
      lineChartDataFormattedData.push([
        `${item.hour}:00`,
        item.sum_of_ac_primary_load,
        item.sum_of_solar_power_output,
        item.sum_of_generator_power_output,
        item.sum_of_battery_discharge_power,
      ]);
    });

    setBarChartData(barChartFormattedData);
    setLineChartData(lineChartDataFormattedData);
  }, [props.homer]);

  React.useEffect(() => {
    setScenario(props.scenario);

    setPVTilt(props.scenario?.pv_tilt);
    setPVAzimuth(props.scenario?.pv_azimuth);

    setSolarPVCapacity(props.scenario?.solar_pv_capacity);
    setBatteryStorageCapacity(props.scenario?.battery_storage_capacity);
    setBatteryInverterCapacity(props.scenario?.battery_inverter_capacity);
    setGeneratorQuantity(props.scenario?.generator_quantity);
    setGeneratorSize(props.scenario?.generator_size);
    setRenewableFraction(props.scenario?.model_renewable_fraction);
    setGeneratorRuntime(props.scenario?.generator_runtime);
    setFuelConsumption(props.scenario?.fuel_consumption);
    setFuelTankSize(props.scenario?.fuel_tank_size);
  }, [props.scenario]);

  const fence_product = scenario?.fence_product
    ? scenario?.fence_product[0]
    : fenceProduct;

  const solar_products = scenario?.solar_product ? scenario?.solar_product : [];
  const battery_products = scenario?.battery_product
    ? scenario?.battery_product
    : [];
  const inverter_products = scenario?.inverter_product
    ? scenario?.inverter_product
    : [];
  const mounting_products = scenario?.mounting_product
    ? scenario?.mounting_product
    : [];
  const generator_products = scenario?.generator_product
    ? scenario?.generator_product
    : [];
  const chargecontroller_products = scenario?.chargecontroller_product
    ? scenario?.chargecontroller_product
    : [];

  const editSiteInformation = () => {
    setLoadingEditSiteInformation(true);
    const payload = { pv_tilt: PVTilt, pv_azimuth: PVAzimuth };

    fetch(`${Config.API.URL}/scenarios/${scenario.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditSiteInformation(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditSiteInformation(false);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setScenario(res.data);
          setEditSiteInformationModel(false);
        } else {
          //swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditSiteInformation(false);

        console.log("Error: ", error);
        /*swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );*/
      });
  };

  const editOptimizationSummary = () => {
    setLoadingEditOptimizationSummary(true);
    const payload = {
      pv_tilt: PVTilt,
      pv_azimuth: PVAzimuth,
      solar_pv_capacity: solarPVCapacity,
      battery_storage_capacity: batteryStorageCapacity,
      battery_inverter_capacity: batteryInverterCapacity,
      generator_quantity: generatorQuantity,
      generator_size: generatorSize,
      model_renewable_fraction: renewableFraction,
      generator_runtime: generatorRuntime,
      fuel_consumption: fuelConsumption,
      fuel_tank_size: fuelTankSize,
    };

    fetch(`${Config.API.URL}/scenarios/${scenario.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditOptimizationSummary(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditOptimizationSummary(false);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setScenario(res.data);
          setEditOptimizationSummaryModel(false);
        } else {
          //swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditOptimizationSummary(false);

        console.log("Error: ", error);
        /*swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );*/
      });
  };

  const editInverterProduct = (products) => {
    setLoadingEditInverterProduct(true);
    const payload = { inverter_product: inverter_products };

    if (products) {
      payload.inverter_product = products;
    } else {
      if (inverter_products?.length && inverter_products.length > 1) {
        let index = inverter_products.findIndex(
          (x) => x.id === inverterProduct.id
        );
        payload.inverter_product[index] = inverterProduct;
      } else {
        payload.inverter_product = [inverterProduct];
      }
    }

    fetch(`${Config.API.URL}/scenarios/${scenario.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditInverterProduct(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditInverterProduct(false);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setScenario(res.data);
          props.onReceiveScenario(res.data);
          setEditInverterProductModel(false);
        } else {
          //swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditInverterProduct(false);
        console.log("Error: ", error);
      });
  };
  const editGeneratorProduct = (products) => {
    setLoadingEditGeneratorProduct(true);
    const payload = { generator_product: generator_products };

    if (products) {
      payload.generator_product = products;
    } else {
      if (generator_products?.length && generator_products.length > 1) {
        let index = generator_products.findIndex(
          (x) => x.id === generatorProduct.id
        );
        payload.generator_product[index] = generatorProduct;
      } else {
        payload.generator_product = [generatorProduct];
      }
    }

    fetch(`${Config.API.URL}/scenarios/${scenario.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditGeneratorProduct(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditGeneratorProduct(false);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setScenario(res.data);
          setEditGeneratorProductModel(false);
        } else {
          //swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditGeneratorProduct(false);

        console.log("Error: ", error);
        /*swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );*/
      });
  };
  // Fence
  const editFenceProduct = () => {
    setLoadingEditFenceProduct(true);
    const payload = { fence_product: [fenceProduct] };

    fetch(`${Config.API.URL}/scenarios/${scenario.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditFenceProduct(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditFenceProduct(false);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setScenario(res.data);
          setEditFenceProductModel(false);
        } else {
          //swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditFenceProduct(false);

        console.log("Error: ", error);
        /*swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );*/
      });
  };
  const editSolarProduct = (products) => {
    setLoadingEditSolarProduct(true);
    const payload = { solar_product: solar_products };

    if (products) {
      payload.solar_product = products;
    } else {
      if (solar_products?.length && solar_products.length > 1) {
        let index = solar_products.findIndex((x) => x.id === solarProduct.id);
        payload.solar_product[index] = solarProduct;
      } else {
        payload.solar_product = [solarProduct];
      }
    }

    fetch(`${Config.API.URL}/scenarios/${scenario.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditSolarProduct(false);

        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditSolarProduct(false);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setScenario(res.data);
          setEditSolarProductModel(false);
        } else {
          //swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditSolarProduct(false);

        console.log("Error: ", error);
        /*swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );*/
      });
  };
  const editBatteryProduct = (products) => {
    setLoadingEditBatteryProduct(true);
    const payload = { battery_product: battery_products };

    if (products) {
      payload.battery_product = products;
    } else {
      if (battery_products?.length && battery_products.length > 1) {
        let index = battery_products.findIndex(
          (x) => x.id === batteryProduct.id
        );
        payload.battery_product[index] = batteryProduct;
      } else {
        payload.battery_product = [batteryProduct];
      }
    }

    fetch(`${Config.API.URL}/scenarios/${scenario.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditBatteryProduct(false);

        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditBatteryProduct(false);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setScenario(res.data);
          setEditBatteryProductModel(false);
        } else {
          //swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditBatteryProduct(false);

        console.log("Error: ", error);
        /*swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );*/
      });
  };
  const editMountingProduct = (products) => {
    setLoadingEditMountingProduct(true);
    const payload = { mounting_product: mounting_products };

    if (products) {
      payload.mounting_product = products;
    } else {
      if (mounting_products?.length && mounting_products.length > 1) {
        let index = mounting_products.findIndex(
          (x) => x.id === mountingProduct.id
        );
        payload.mounting_product[index] = mountingProduct;
      } else {
        payload.mounting_product = [mountingProduct];
      }
    }

    fetch(`${Config.API.URL}/scenarios/${scenario.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditMountingProduct(false);

        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditMountingProduct(false);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setScenario(res.data);
          setEditMountingProductModel(false);
        } else {
          //swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditMountingProduct(false);

        console.log("Error: ", error);
        /*swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );*/
      });
  };
  const editChargeControllerProduct = (products) => {
    setLoadingEditChargeControllerProduct(true);
    const payload = { chargecontroller_product: chargecontroller_products };

    if (products) {
      payload.chargecontroller_product = products;
    } else {
      if (
        chargecontroller_products?.length &&
        chargecontroller_products.length > 1
      ) {
        let index = chargecontroller_products.findIndex(
          (x) => x.id === chargecontrollerProduct.id
        );
        payload.chargecontroller_product[index] = chargecontrollerProduct;
      } else {
        payload.chargecontroller_product = [chargecontrollerProduct];
      }
    }

    fetch(`${Config.API.URL}/scenarios/${scenario.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditChargeControllerProduct(false);

        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditChargeControllerProduct(false);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setScenario(res.data);
          setEditChargeControllerProductModel(false);
        } else {
          //swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditChargeControllerProduct(false);

        console.log("Error: ", error);
        /*swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );*/
      });
  };

  const _onChangeInverterProduct = (e) => {
    const data = { ...inverterProduct };
    data[e.target.name] = e.target.value;
    if (e.target.name === "inverter_product_quantity") {
      data.inverter_product_total_capacity = (
        parseFloat(
          data?.inverter_product_unit_capacity
            ? data?.inverter_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.inverter_product_quantity ? data?.inverter_product_quantity : 1
        )
      ).toFixed(2);
    }

    if (e.target.name === "inverter_product_unit_capacity") {
      data.inverter_product_total_capacity = (
        parseFloat(
          data?.inverter_product_unit_capacity
            ? data?.inverter_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.inverter_product_quantity ? data?.inverter_product_quantity : 1
        )
      ).toFixed(2);
    }

    setInverterProduct(data);
  };
  const _onChangeGeneratorProduct = (e) => {
    const data = { ...generatorProduct };
    data[e.target.name] = e.target.value;
    if (e.target.name === "generator_product_quantity") {
      data.generator_product_total_capacity = (
        parseFloat(
          data?.generator_product_unit_capacity
            ? data?.generator_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.generator_product_quantity
            ? data?.generator_product_quantity
            : 1
        )
      ).toFixed(2);
    }

    if (e.target.name === "generator_product_unit_capacity") {
      data.generator_product_total_capacity = (
        parseFloat(
          data?.generator_product_unit_capacity
            ? data?.generator_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.generator_product_quantity
            ? data?.generator_product_quantity
            : 1
        )
      ).toFixed(2);
    }
    setGeneratorProduct(data);
  };
  const _onChangeFenceProduct = (e) => {
    const data = { ...fenceProduct };
    data[e.target.name] = e.target.value;
    setFenceProduct(data);
  };
  const _onChangeSolarProduct = (e) => {
    const data = { ...solarProduct };
    data[e.target.name] = e.target.value;
    if (e.target.name === "solar_product_quantity") {
      data.solar_product_total_capacity = (
        parseFloat(
          data?.solar_product_unit_capacity
            ? data?.solar_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.solar_product_quantity ? data?.solar_product_quantity : 1
        )
      ).toFixed(2);
    }

    if (e.target.name === "solar_product_unit_capacity") {
      data.solar_product_total_capacity = (
        parseFloat(
          data?.solar_product_unit_capacity
            ? data?.solar_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.solar_product_quantity ? data?.solar_product_quantity : 1
        )
      ).toFixed(2);
    }
    setSolarProduct(data);
  };
  const _onChangeBatteryProduct = (e) => {
    const data = { ...batteryProduct };
    data[e.target.name] = e.target.value;
    if (e.target.name === "battery_product_quantity") {
      data.battery_product_total_capacity = (
        parseFloat(
          data?.battery_product_unit_capacity
            ? data?.battery_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.battery_product_quantity ? data?.battery_product_quantity : 1
        )
      ).toFixed(2);
    }

    if (e.target.name === "battery_product_unit_capacity") {
      data.battery_product_total_capacity = (
        parseFloat(
          data?.battery_product_unit_capacity
            ? data?.battery_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.battery_product_quantity ? data?.battery_product_quantity : 1
        )
      ).toFixed(2);
    }
    setBatteryProduct(data);
  };
  const _onChangeMountingProduct = (e) => {
    const data = { ...mountingProduct };
    data[e.target.name] = e.target.value;
    if (e.target.name === "mounting_product_quantity") {
      data.mounting_product_total_capacity = (
        parseFloat(
          data?.mounting_product_unit_capacity
            ? data?.mounting_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.mounting_product_quantity ? data?.mounting_product_quantity : 1
        )
      ).toFixed(2);
    }

    if (e.target.name === "mounting_product_unit_capacity") {
      data.mounting_product_total_capacity = (
        parseFloat(
          data?.mounting_product_unit_capacity
            ? data?.mounting_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.mounting_product_quantity ? data?.mounting_product_quantity : 1
        )
      ).toFixed(2);
    }
    setMountingProduct(data);
  };
  const _onChangeChargeControllerProduct = (e) => {
    const data = { ...chargecontrollerProduct };
    data[e.target.name] = e.target.value;
    if (e.target.name === "chargecontroller_product_quantity") {
      data.chargecontroller_product_total_capacity = (
        parseFloat(
          data?.chargecontroller_product_unit_capacity
            ? data?.chargecontroller_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.chargecontroller_product_quantity
            ? data?.chargecontroller_product_quantity
            : 1
        )
      ).toFixed(2);
    }

    if (e.target.name === "chargecontroller_product_unit_capacity") {
      data.chargecontroller_product_total_capacity = (
        parseFloat(
          data?.chargecontroller_product_unit_capacity
            ? data?.chargecontroller_product_unit_capacity
            : 0
        ) *
        parseFloat(
          data?.chargecontroller_product_quantity
            ? data?.chargecontroller_product_quantity
            : 1
        )
      ).toFixed(2);
    }
    setChargecontrollerProduct(data);
  };

  const _onReceiveInverterProducts = (products) => {
    editInverterProduct(products);
    setCurrentInverterProductTab(0);
    // setInverterProduct(inverterProduct);
  };

  const _onReceiveGeneratorProducts = (products) => {
    editGeneratorProduct(products);
    setCurrentGeneratorProductTab(0);
    // setGeneratorProduct(generatorProduct);
  };

  const _onReceiveSolarProducts = (products) => {
    editSolarProduct(products);
    setCurrentSolarProductTab(0);
    // setSolarProduct(solarProduct);
  };

  const _onReceiveBatteryProducts = (products) => {
    editBatteryProduct(products);
    setCurrentBatteryProductTab(0);
    // setBatteryProduct(batteryProduct);
  };

  const _onReceiveMountingProducts = (products) => {
    editMountingProduct(products);
    setCurrentMountingProductTab(0);
    // setMountingProduct(mountingProduct);
  };
  const _onReceiveChargeControllerProducts = (products) => {
    editChargeControllerProduct(products);
    setCurrentChargeControllerProductTab(0);
    // setChargecontrollerProduct(chargecontrollerProduct);
  };

  const editCurrentSolarProductTab = () => {
    const solar_product = scenario?.solar_product[currentSolarProductTab];
    // console.log("solar_product:", solar_product);

    setSolarProduct(solar_product);

    setEditSolarProductModel(true);
  };

  const editCurrentBatteryProductTab = () => {
    const battery_product = scenario?.battery_product[currentBatteryProductTab];

    // console.log("battery_product:", battery_product);
    setBatteryProduct(battery_product);

    setEditBatteryProductModel(true);
  };

  const editCurrentInverterProductTab = () => {
    const inverter_product =
      scenario?.inverter_product[currentInverterProductTab];
    // console.log("inverter_product:", inverter_product);
    setInverterProduct(inverter_product);

    setEditInverterProductModel(true);
  };

  const editCurrentMountingProductTab = () => {
    const mounting_product =
      scenario?.mounting_product[currentMountingProductTab];
    // console.log("mounting_product:", mounting_product);

    setMountingProduct(mounting_product);

    setEditMountingProductModel(true);
  };

  const editCurrentGeneratorProductTab = () => {
    const generator_product =
      scenario?.generator_product[currentGeneratorProductTab];
    // console.log("generator_product:", generator_product);
    setGeneratorProduct(generator_product);

    setEditGeneratorProductModel(true);
  };

  const editCurrentChargeControllerProductTab = () => {
    const chargecontroller_product =
      scenario?.chargecontroller_product[currentChargeControllerProductTab];
    // console.log("chargecontroller_product:", chargecontroller_product);
    setChargecontrollerProduct(chargecontroller_product);

    setEditChargeControllerProductModel(true);
  };

  React.useEffect(() => {
    const fence_product = scenario?.fence_product
      ? props?.scenario?.fence_product[0]
      : fenceProduct;
    setFenceProduct(fence_product);
  }, [props.scenario]);

  const _onAddInverterBlankProduct = () => {
    let data = [
      {
        id: Date.now(),
        inverter_product_name: "",
        inverter_product_quantity: 1,
        inverter_product_label: "",
        inverter_product_unit_capacity: 0,
        inverter_product_total_capacity: 0,
        inverter_product_unit_cost: "",
        inverter_product_notes: "",
        inverter_product_manufacturer: "",
        inverter_product_model: "",
        inverter_product_part_number: "",
        inverter_product_certifications: "",
        inverter_product_output_power: "",
        inverter_product_output_voltage: "",
        inverter_product_output_frequency: "",
        inverter_product_max_input_current: "",
        inverter_product_max_output_current: "",
        others: [],
        created_at: Date.now(),
        updated_at: Date.now(),
      },
      ...inverter_products,
    ];
    editInverterProduct(data);
    // setCurrentInverterProductTab(0);
    // setInverterProduct(data[0]);
    // setEditInverterProductModel(true);
  };

  const _onAddGeneratorBlankProduct = () => {
    let data = [
      {
        id: Date.now(),
        generator_product_name: "",
        generator_product_quantity: 1,
        generator_product_label: "",
        generator_product_unit_capacity: 0,
        generator_product_total_capacity: 0,
        generator_product_unit_cost: 0,
        generator_product_notes: "",
        generator_product_manufacturer: "",
        generator_product_model: "",
        generator_product_part_number: "",
        generator_product_certifications: "",
        generator_frequency: "",
        generator_phase: "",
        generator_rated_voltage: "",
        generator_real_standby_power: 0,
        generator_apparent_standby_power: 0,
        generator_real_prime_power: 0,
        generator_apparent_prime_power: 0,
        generator_power_factor: 0,
        generator_efficiency: 0,
        generator_max_current: 0,
        generator_continuous_current: 0,
        generator_breaker_current: 0,
        others: [],
        created_at: Date.now(),
        updated_at: Date.now(),
      },
      ...generator_products,
    ];
    editGeneratorProduct(data);
    // setCurrentGeneratorProductTab(0);
    // setGeneratorProduct(data[0]);
    // setEditGeneratorProductModel(true);
  };

  const _onAddSolarBlankProduct = () => {
    let data = [
      {
        id: Date.now(),
        solar_product_name: "",
        solar_product_quantity: 1,
        solar_product_label: "",
        solar_product_unit_capacity: 0,
        solar_product_total_capacity: 0,
        solar_product_unit_cost: 0,
        solar_product_notes: "",
        solar_product_manufacturer: "",
        solar_product_model: "",
        solar_product_part_number: "",
        solar_product_certifications: "",
        solar_product_tilt: 0,
        solar_product_azimuth: 0,
        solar_product_string_length: 0,
        solar_product_string_quantity: 0,
        solar_product_pmax: 0,
        solar_product_vmp: 0,
        solar_product_imp: 0,
        solar_product_voc: 0,
        solar_product_isc: 0,
        solar_product_voc_min_temp: 0,
        solar_product_isc_min_temp: 0,
        others: [],
        created_at: Date.now(),
        updated_at: Date.now(),
      },
      ...solar_products,
    ];
    editSolarProduct(data);
    // setCurrentSolarProductTab(0);
    // setSolarProduct(data[0]);
    // setEditSolarProductModel(true);
  };

  const _onAddBatteryBlankProduct = () => {
    let data = [
      {
        id: Date.now(),
        battery_product_name: "",
        battery_product_quantity: 1,
        battery_product_label: "",
        battery_product_unit_capacity: 0,
        battery_product_total_capacity: 0,
        battery_product_unit_cost: 0,
        battery_product_notes: "",
        battery_product_manufacturer: "",
        battery_product_model: "",
        battery_product_part_number: "",
        battery_product_certifications: "",
        battery_product_chemistry: "",
        battery_product_dc_voltage: 0,
        battery_product_amp_hours: 0,
        battery_product_storage_capacity: 0,
        battery_product_max_discharge_rate: 0,
        battery_product_max_charge_rate: 0,
        battery_product_discharge_depth: 0,
        others: [],
        created_at: Date.now(),
        updated_at: Date.now(),
      },
      ...battery_products,
    ];
    editBatteryProduct(data);
    // setCurrentBatteryProductTab(0);
    // setBatteryProduct(data[0]);
    // setEditBatteryProductModel(true);
  };

  const _onAddMountingBlankProduct = () => {
    let data = [
      {
        id: Date.now(),
        mounting_product_name: "",
        mounting_product_quantity: 1,
        mounting_product_label: "",
        mounting_product_unit_capacity: 0,
        mounting_product_total_capacity: 0,
        mounting_product_unit_cost: 0,
        mounting_product_notes: "",
        mounting_product_manufacturer: "",
        mounting_product_model: "",
        mounting_product_part_number: "",
        mounting_product_certifications: "",
        mounting_min_clear_height: 0,
        others: [],
        created_at: Date.now(),
        updated_at: Date.now(),
      },
      ...mounting_products,
    ];
    editMountingProduct(data);
    // setCurrentMountingProductTab(0);
    // setMountingProduct(data[0]);
    // setEditMountingProductModel(true);
  };
  const _onAddChargeControllerBlankProduct = () => {
    let data = [
      {
        id: Date.now(),
        chargecontroller_product_name: "",
        chargecontroller_product_quantity: 1,
        chargecontroller_product_label: "",
        chargecontroller_product_unit_capacity: 0,
        chargecontroller_product_total_capacity: 0,
        chargecontroller_product_unit_cost: 0,
        chargecontroller_product_notes: "",
        chargecontroller_product_manufacturer: "",
        chargecontroller_product_model: "",
        chargecontroller_product_part_number: "",
        chargecontroller_product_certifications: "",
        chargecontroller_max_pv_capacity: 0,
        chargecontroller_max_string_length: 0,
        others: [],
        created_at: Date.now(),
        updated_at: Date.now(),
      },
      ...chargecontroller_products,
    ];
    editChargeControllerProduct(data);
    // setCurrentChargeControllerProductTab(0);
    // setChargecontrollerProduct(data[0]);
    // setEditChargeControllerProductModel(true);
  };

  const triggerUploadHomer = () => {
    //$('#upload-homer-file').trigger('click');
    document.getElementById("upload-homer-file").click();
  };

  /**
   * Handles upload of HOMER file to server
   */
  // const uploadHomer = (e) => {
  //   var formData   = new FormData();
  //   var homer_file = e.target.files[0]; // homer_file.current.files[0];

  //   console.log('homer_file:', homer_file);

  //   if (homer_file) {
  //     setUploading(true);
  //     setLoading(true);
  //     formData.append('scenario_id', scenario?.id);
  //     formData.append('file', homer_file);

  //     // this.setState({ upload: true }, () => {
  //     // send API request to upload HOMER file for this project
  //     fetch(Config.API.URL+'/system-optimization/upload-homer-file', {
  //       method: 'POST',
  //       headers: {Accept: 'application/json', Authorization: `Bearer ${user.token}` },
  //       body: formData,
  //     }).then((res) => {
  //       // if 401 error (unauthenticated user, then log user out)
  //       if ((res.status) === 401) {
  //         console.log("401 Error: Unauthenticated");

  //         localStorage.removeItem("user");
  //         navigate("/");
  //       }
  //       return res;
  //     }).then((res) => res.json()).then( async (res) => {
  //       console.log('upload-res:', res);
  //       if (res.status) {
  //         props.onReceiveHomer(res.data);
  //         // navigate(`/scenarios/view/${site_id}`);
  //         // this.setState({ homer: true, upload: false, homer_name: res.data });
  //       } else {
  //         // this.setState({ homer: false, upload: false });
  //         // alert(res.message);
  //         swal("Oh no!", res.message, "error");
  //       }
  //       setUploading(false);
  //       setLoading(false);
  //     }).catch((error) => {
  //       console.log('Error: ', error);
  //       setUploading(false);
  //       setLoading(false);
  //       //this.setState({ homer: false, upload: false });
  //       swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
  //     });
  //     //});
  //   } else {
  //     setUploading(false);
  //     setLoading(false);
  //     swal("Oh no!", "Please select homer file, to continue", "error");
  //   }
  // }

  const uploadHomer = (e) => {
    const formData = new FormData();
    const homer_file = e.target.files[0];

    console.log("homer_file:", homer_file);

    if (homer_file) {
      // setUploading(true);
      // setLoading(true);
      // setUploadReport("Uploading your HOMER file...");
      formData.append("scenario_id", scenario?.id);
      formData.append("file", homer_file);

      uploadHomerAsyn(formData, user);

      // Create a new XMLHttpRequest
      // const xhr = new XMLHttpRequest();

      // // Event listener for upload progress
      // xhr.upload.onprogress = (event) => {
      //   if (event.lengthComputable) {
      //     const percentComplete = Math.round(
      //       (event.loaded / event.total) * 100
      //     );
      //     console.log(`Upload progress: ${percentComplete}%`);
      //     // Update progress state if you want to display it in the UI
      //     setUploadProgress(percentComplete);

      //     // 0%: Uploading your HOMER file...
      //     // 30%: Extracting relevant data from the microgrid simulation results...
      //     // 60%: Aggregating data into hourly and monthly charts...
      //     // 95%: Almost done, finalizing the process.. (edited)

      //     if (percentComplete >= 0 && percentComplete < 30) {
      //       setUploadReport("Uploading your HOMER file...");
      //     } else if (percentComplete >= 30 && percentComplete < 60) {
      //       setUploadReport(
      //         "Extracting relevant data from the microgrid simulation results..."
      //       );
      //     } else if (percentComplete >= 60 && percentComplete < 95) {
      //       setUploadReport(
      //         "Aggregating data into hourly and monthly charts..."
      //       );
      //     } else {
      //       setUploadReport("Hang tight! finishing up now...");
      //     }
      //   }
      // };

      // // Event listener for upload completion
      // xhr.onload = () => {
      //   if (xhr.status === 200) {
      //     const res = JSON.parse(xhr.responseText);
      //     console.log("upload-res:", res);
      //     if (res.status) {
      //       props.onReceiveHomer(res.data);
      //     } else {
      //       //swal("Oh no!", res.message, "error");
      //     }
      //   } else if (xhr.status === 401) {
      //     console.log("401 Error: Unauthenticated");
      //     localStorage.removeItem("user");
      //     navigate("/");
      //   } else {
      //     //swal("Oh no!", "An error occurred during the upload.", "error");
      //   }
      //   setUploading(false);
      //   setLoading(false);
      // };

      // // Event listener for upload error
      // xhr.onerror = () => {
      //   console.log("Error: ", xhr.statusText);
      //   setUploading(false);
      //   setLoading(false);
      //   swal("Oh no!", "An error occurred while connecting to the server. Please try again", "error");
      // };

      // // Open the connection and send the formData
      // xhr.open(
      //   "POST",
      //   `${Config.API.URL}/system-optimization/upload-homer-file`,
      //   true
      // );
      // xhr.setRequestHeader("Accept", "application/json");
      // xhr.setRequestHeader("Authorization", `Bearer ${user.token}`);
      // xhr.send(formData);
    } else {
      // setUploading(false);
      // setLoading(false);
      swal("Oh no!", "Please select a HOMER file to continue", "error");
    }
  };

  const handleDownload = () => {
    // Create a link element, set the href to the file path in the public directory
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/sample-homer-optimization.csv`;
    link.setAttribute("download", "sample-homer-optimization.csv"); // Set the download attribute with the filename
    document.body.appendChild(link); // Append to the body
    link.click(); // Simulate click
    document.body.removeChild(link); // Remove the link element
  };

  return (
    <>
      <div className=" w-11/12 lg:w-full ml-auto mr-auto mb-20 ">
        <h3 className=" text-black text-2xl f-f-r mt-10 ">
          {scenario?.scenario_name}
        </h3>

        <div className="tabs-container">
          <div className="page-tabs">
            <div id="system-sizing">
              <button
                type="button"
                id="system-sizing"
                disabled={optimizationTab === "system-sizing"}
                onClick={(e) => _setOptimizationTab("system-sizing")}
                className="border border-primary bg-default w-[350px] text-center h-[50px] rounded-[25px] f-f-r text-lg text-gray captilize"
              >
                System Sizing
              </button>
            </div>
            <div id="microgrid-architecture">
              <button
                type="button"
                id="microgrid-architecture"
                disabled={optimizationTab === "microgrid-architecture"}
                onClick={(e) => _setOptimizationTab("microgrid-architecture")}
                className="border border-primary bg-default w-[350px] text-center h-[50px] rounded-[25px] text-lg f-f-r text-gray captilize"
              >
                Microgrid Architecture
              </button>
            </div>

            {scenario?.optimization_type !== "NREL" ? (
              <div id="optimization-charts">
                <button
                  type="button"
                  id="optimization-charts"
                  disabled={optimizationTab === "optimization-charts"}
                  onClick={(e) => _setOptimizationTab("optimization-charts")}
                  className="border border-primary bg-default w-[350px] text-center h-[50px] rounded-[25px] text-lg f-f-r text-gray captilize"
                >
                  Optimization Charts
                </button>
              </div>
            ) : null}
          </div>
          <div className="content">
            {optimizationTab === "system-sizing" ? (
              <div className="grid grid-cols-12 mt-5 gap-4  ">
                {/* second grid started */}
                <div
                  className={`   col-span-12 sm:col-span-${
                    scenario?.optimization_type !== "NREL" ? "6" : "12"
                  }`}
                >
                  {/* crd started */}
                  {/* <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] relative">
                    <button
                      onClick={() => setEditSiteInformationModel(true)}
                      className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
                    >
                      Edit
                    </button>
                    <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                      Site Information
                    </h2>
                    
                  </div> */}

                  {/* crd started */}
                  <div
                    className={` bg-white  shadow-lg p-[20px] rounded-[8px] mt-2 relative ${
                      scenario?.optimization_type !== "NREL" ? "" : "w-[700px]"
                    } `}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  >
                    <button
                      onClick={() => setEditOptimizationSummaryModel(true)}
                      className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
                    >
                      Edit
                    </button>
                    <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                      Optimization Summary
                    </h2>
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Solar PV Capacity (kW)
                      <span className=" float-right text-black ">
                        {scenario?.solar_pv_capacity}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Average PV Tilt
                      <span className=" float-right text-black ">
                        {scenario?.pv_tilt}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Average PV Azimuth
                      <span className=" float-right text-black ">
                        {scenario?.pv_azimuth}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Battery Storage Capacity (kWh)
                      <span className=" float-right text-black ">
                        {scenario?.battery_storage_capacity}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Battery Inverter Capacity (kW)
                      <span className=" float-right text-black ">
                        {scenario?.battery_inverter_capacity}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Generator Quantity
                      <span className=" float-right text-black ">
                        {scenario?.generator_quantity}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Generator Size (kW)
                      <span className=" float-right text-black ">
                        {scenario?.generator_size}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Renewable Fraction (%)
                      <span className=" float-right text-black ">
                        {scenario?.model_renewable_fraction}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Generator Runtime (hours)
                      <span className=" float-right text-black ">
                        {scenario?.generator_runtime}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Fuel Consumption (gallons)
                      <span className=" float-right text-black ">
                        {scenario?.fuel_consumption}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    {/* start */}
                    <h3 className="  f-f-r text-lg text-black-vlight mt-2   ">
                      Fuel Tank Size (gallons)
                      <span className=" float-right text-black ">
                        {scenario?.fuel_tank_size}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                  </div>
                </div>
                {/* second grid ended */}
                {scenario?.optimization_type !== "NREL" ? (
                  <div className="col-span-12 sm:col-span-6">
                    <>
                      <h2 className=" f-f-sm text-xl capitalize text-primary mt-10 text-center ">
                        Upload the Results of a HOMER Optimization
                      </h2>
                      {homer?.homer_hourly_summary?.length ? (
                        <>
                          <p className=" f-f-sm mt-10 text-center ">
                            Homer optimization file below
                          </p>

                          <div
                            className=" mt-10 text-center "
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <button
                              style={{
                                justifyContent: "center",
                                display: "flex",
                              }}
                              className={`${
                                false === "homer-optimization-results"
                                  ? "bg-blue-950 border-blue-950 text-white"
                                  : "bg-white border-primary text-gray"
                              } border text-lg f-f-r  w-[400px] h-[130px] rounded-[8px] captilize `}
                            >
                              <img
                                src={uploading ? Spinner : ExcelLogo}
                                className="w-[45px] h-auto 2xl:w-[96px] text-center"
                                style={{ alignSelf: "center" }}
                                alt=""
                              />
                            </button>

                            <button
                              onClick={
                                uploading
                                  ? () =>
                                      swal(
                                        "Please Wait!",
                                        "We're still uploading your HOMER file!",
                                        "info"
                                      )
                                  : triggerUploadHomer
                              }
                              className=" bg-primary text-lg f-f-r text-white  w-[400px] h-[48px] rounded-[8px] captilize mt-5"
                            >
                              {uploading ? `Uploading` : "Update"}
                            </button>

                            {uploading ? (
                              <p className=" f-f-sm text-center ">{`${uploadProgress}% - ${uploadReport}`}</p>
                            ) : null}

                            {/* <button
                              type="button"
                              onClick={ uploading ? () => swal("Please Wait!", "Uploading HOMER file is on progress!", "info") : triggerUploadHomer }
                              className="border border-primary bg-default w-[350px] text-center h-[50px] rounded-[15px] f-f-r text-sm text-primary mt-5 "
                            >
                              { uploading ? `${uploadProgress}% - ${uploadReport}` : "Update" }
                            </button> */}
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={
                              uploading
                                ? () =>
                                    swal(
                                      "Please Wait!",
                                      "We're still uploading your HOMER file!",
                                      "info"
                                    )
                                : triggerUploadHomer
                            }
                            className={`bg-blue-100 border-blue-950 text-black border text-lg f-f-r w-[100%] h-[300px] rounded-[8px] captilize mt-5 `}
                          >
                            <img
                              src={uploading ? Spinner : UploadIcon}
                              className="w-[45px] h-auto 2xl:w-[96px] text-center"
                              style={{ marginLeft: "auto", margin: "auto" }}
                              alt=""
                            />
                            <p className="mt-2">
                              {uploading
                                ? `${uploadProgress}% - ${uploadReport}`
                                : "Choose a file"}
                            </p>
                          </button>

                          <p
                            className=" f-f-sm mb-5 mt-5 text-center cursor-pointer "
                            onClick={handleDownload}
                          >
                            Click here to download a sample HOMER Optimization
                            file
                          </p>
                          <div style={{ textAlign: "center" }}>
                            <button
                              onClick={() =>
                                setFileUploadInstructionsModel(true)
                              }
                              className="border bg-primary w-[250px] h-[50px] rounded-[15px] f-f-r text-lg text-white text-center"
                            >
                              File Upload Instructions
                            </button>
                          </div>
                        </>
                      )}
                      <input
                        type="file"
                        id="upload-homer-file"
                        onChange={uploadHomer}
                        style={{ display: "none" }}
                        ref={homer_file}
                      />
                    </>
                  </div>
                ) : null}
              </div>
            ) : null}

            {optimizationTab === "microgrid-architecture" ? (
              <div className="grid grid-cols-12 mt-5 gap-4  ">
                <div className="   col-span-12 sm:col-span-6">
                  <div className=" bg-white mt-2 shadow-lg p-[20px] rounded-[8px] relative ">
                    <DialogBoxInverterProducts
                      products={inverter_products}
                      init_products={products["inverter-product"]}
                      onReceiveProducts={_onReceiveInverterProducts}
                      onAddBlankProduct={_onAddInverterBlankProduct}
                    />
                    {inverter_products.length ? (
                      <button
                        onClick={editCurrentInverterProductTab}
                        className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
                      >
                        Edit
                      </button>
                    ) : null}
                    {/* inner stated */}
                    <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                      Inverter Product(s)
                    </h2>

                    {!inverter_products.length ? (
                      <p
                        style={{
                          textAlign: "center",
                          paddingTop: 20,
                          paddingBottom: 20,
                          fontSize: 12,
                          color: "gray",
                        }}
                      >
                        Inverter products will display here
                      </p>
                    ) : (
                      <div className="tabs-container">
                        <div className="tabs">
                          {inverter_products.length &&
                          inverter_products.length > 1
                            ? inverter_products.map((inverter_product, i) => (
                                <button
                                  key={i}
                                  id={i}
                                  disabled={currentInverterProductTab === i}
                                  onClick={(e) =>
                                    setCurrentInverterProductTab(i)
                                  }
                                >
                                  {inverter_product.inverter_product_name
                                    ? inverter_product.inverter_product_name
                                    : "Blank"}
                                </button>
                              ))
                            : null}
                        </div>
                        <div className="content">
                          {inverter_products.map((inverter_product, i) => (
                            <div key={i}>
                              {currentInverterProductTab === i ? (
                                <div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Product Name:{" "}
                                    <span className=" float-right text-black ">
                                      {inverter_product?.inverter_product_name}{" "}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Product Quantity:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        inverter_product?.inverter_product_quantity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Unit Capacity:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        inverter_product?.inverter_product_unit_capacity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Product label:{" "}
                                    <span className=" float-right text-black ">
                                      {inverter_product?.inverter_product_label}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Comments or Notes:{" "}
                                    <span className=" float-right text-black ">
                                      {inverter_product?.inverter_product_notes}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Total Capacity:
                                    <span className=" float-right text-black ">
                                      {
                                        inverter_product?.inverter_product_total_capacity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Manufacturer:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        inverter_product?.inverter_product_manufacturer
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Model:{" "}
                                    <span className=" float-right text-black ">
                                      {inverter_product?.inverter_product_model}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Part Number:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        inverter_product?.inverter_product_part_number
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Product Certifications:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        inverter_product?.inverter_product_certifications
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Product output Power (W):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        inverter_product?.inverter_product_output_power
                                      }
                                    </span>
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Product output Voltage (V):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        inverter_product?.inverter_product_output_voltage
                                      }
                                    </span>
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Product output Frequency (Hz):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        inverter_product?.inverter_product_output_frequency
                                      }
                                    </span>
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Product Max Input DC Current (A):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        inverter_product?.inverter_product_max_input_current
                                      }
                                    </span>
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2 ">
                                    Product Max output Charge Current (A):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        inverter_product?.inverter_product_max_output_current
                                      }
                                    </span>
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                </div>
                              ) : // <div><p className='title'>{inverter_product?.inverter_product_name}</p><p>{inverter_product?.inverter_product_name}</p></div>
                              null}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/*  */}
                  </div>

                  <div className=" bg-white mt-2 shadow-lg p-[20px] rounded-[8px] relative ">
                    <DialogBoxMountingProducts
                      products={mounting_products}
                      init_products={products["mounting-product"]}
                      onReceiveProducts={_onReceiveMountingProducts}
                      onAddBlankProduct={_onAddMountingBlankProduct}
                    />
                    {mounting_products.length ? (
                      <button
                        onClick={editCurrentMountingProductTab}
                        className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
                      >
                        Edit
                      </button>
                    ) : null}
                    {/* inner stated */}
                    <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                      Mounting Product(s)
                    </h2>
                    {!mounting_products.length ? (
                      <p
                        style={{
                          textAlign: "center",
                          paddingTop: 20,
                          paddingBottom: 20,
                          fontSize: 12,
                          color: "gray",
                        }}
                      >
                        Mounting products will display here
                      </p>
                    ) : (
                      <div className="tabs-container">
                        <div className="tabs">
                          {mounting_products.length &&
                          mounting_products.length > 1
                            ? mounting_products.map((mounting_product, i) => (
                                <button
                                  key={i}
                                  id={i}
                                  disabled={currentMountingProductTab === i}
                                  onClick={(e) =>
                                    setCurrentMountingProductTab(i)
                                  }
                                >
                                  {mounting_product.mounting_product_name
                                    ? mounting_product.mounting_product_name
                                    : "Blank"}
                                </button>
                              ))
                            : null}
                        </div>
                        <div className="content">
                          {mounting_products.map((mounting_product, i) => (
                            <div key={i}>
                              {currentMountingProductTab === i ? (
                                <div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product Name:{" "}
                                    <span className=" float-right text-black ">
                                      {mounting_product?.mounting_product_name}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product Quantity:
                                    <span className=" float-right text-black ">
                                      {
                                        mounting_product?.mounting_product_quantity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Unit Capacity:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        mounting_product?.mounting_product_unit_capacity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product label:
                                    <span className=" float-right text-black ">
                                      {mounting_product?.mounting_product_label}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Comments or Notes:{" "}
                                    <span className=" float-right text-black ">
                                      {mounting_product?.mounting_product_notes}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Total Capacity:
                                    <span className=" float-right text-black ">
                                      {
                                        mounting_product?.mounting_product_total_capacity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Manufacturer:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        mounting_product?.mounting_product_manufacturer
                                      }
                                    </span>
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Model:{" "}
                                    <span className=" float-right text-black ">
                                      {mounting_product?.mounting_product_model}
                                    </span>
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Part Number:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        mounting_product?.mounting_product_part_number
                                      }
                                    </span>
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product Certifications:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        mounting_product?.mounting_product_certifications
                                      }
                                    </span>
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Clear Height Minimum (Ft):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        mounting_product?.mounting_min_clear_height
                                      }
                                    </span>
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className=" bg-white mt-2 shadow-lg p-[20px] rounded-[8px] relative ">
                    <DialogBoxChargecontrollerProducts
                      products={chargecontroller_products}
                      init_products={products["chargecontroller-product"]}
                      onReceiveProducts={_onReceiveChargeControllerProducts}
                      onAddBlankProduct={_onAddChargeControllerBlankProduct}
                    />
                    {chargecontroller_products.length ? (
                      <button
                        onClick={editCurrentChargeControllerProductTab}
                        className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
                      >
                        Edit
                      </button>
                    ) : null}
                    {/* inner stated */}
                    <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                      Charge Controller Product(s)
                    </h2>
                    {!chargecontroller_products.length ? (
                      <p
                        style={{
                          textAlign: "center",
                          paddingTop: 20,
                          paddingBottom: 20,
                          fontSize: 12,
                          color: "gray",
                        }}
                      >
                        Charge controller products will display here
                      </p>
                    ) : (
                      <div className="tabs-container">
                        <div className="tabs">
                          {chargecontroller_products.length &&
                          chargecontroller_products.length > 1
                            ? chargecontroller_products.map(
                                (chargecontroller_product, i) => (
                                  <button
                                    key={i}
                                    id={i}
                                    disabled={
                                      currentChargeControllerProductTab === i
                                    }
                                    onClick={(e) =>
                                      setCurrentChargeControllerProductTab(i)
                                    }
                                  >
                                    {chargecontroller_product.chargecontroller_product_name
                                      ? chargecontroller_product.chargecontroller_product_name
                                      : "Blank"}
                                  </button>
                                )
                              )
                            : null}
                        </div>
                        <div className="content">
                          {chargecontroller_products.map(
                            (chargecontroller_product, i) => (
                              <div key={i}>
                                {currentChargeControllerProductTab === i ? (
                                  <div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Product Name:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_product_name
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Product Quantity:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_product_quantity
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Unit Capacity:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_product_unit_capacity
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Product Label:
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_product_label
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Comments or Notes:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_product_notes
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Total Capacity:
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_product_total_capacity
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Manufacturer:
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_product_manufacturer
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Model:
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_product_model
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Part Number:
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_product_part_number
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Product Certifications:
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_product_certifications
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Max PV Capacity:
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_max_pv_capacity
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Max String Length:
                                      <span className=" float-right text-black ">
                                        {
                                          chargecontroller_product?.chargecontroller_max_string_length
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                  </div>
                                ) : null}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {site.site_fence ? (
                    <div className=" bg-white mt-2 shadow-lg p-[20px] rounded-[8px] relative ">
                      <button
                        onClick={() => setEditFenceProductModel(true)}
                        className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
                      >
                        Edit
                      </button>
                      {/* inner stated */}
                      <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                        Fence Product(s)
                      </h2>
                      <div>
                        <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                          Fence Height (ft):{" "}
                          <span className=" float-right text-black ">
                            {fence_product?.fence_product_height}
                          </span>{" "}
                        </h3>
                        <div className=" border border-white-vlight my-1 "></div>
                        <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                          Fence Type:{" "}
                          <span className=" float-right text-black ">
                            {fence_product?.fence_product_type}
                          </span>{" "}
                        </h3>
                        <div className=" border border-white-vlight my-1 "></div>

                        <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                          Fence Topper:{" "}
                          <span className=" float-right text-black ">
                            {fence_product?.fence_product_topper}
                          </span>{" "}
                        </h3>
                        <div className=" border border-white-vlight my-1 "></div>
                        <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                          Fence Sound Attenuation:{" "}
                          <span className=" float-right text-black ">
                            {fence_product?.fence_product_sound_attenuation}
                          </span>{" "}
                        </h3>
                        <div className=" border border-white-vlight my-1 "></div>

                        <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                          Sound Attenuation Product Spec:{" "}
                          <span className=" float-right text-black ">
                            {fence_product?.fence_product_attenuation_spec}
                          </span>{" "}
                        </h3>
                        <div className=" border border-white-vlight my-1 "></div>
                        <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                          Manufacturer:{" "}
                          <span className=" float-right text-black ">
                            {fence_product?.fence_product_manufacturer}
                          </span>{" "}
                        </h3>
                        <div className=" border border-white-vlight my-1 "></div>

                        <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                          Model:{" "}
                          <span className=" float-right text-black ">
                            {fence_product?.fence_product_model}
                          </span>{" "}
                        </h3>
                        <div className=" border border-white-vlight my-1 "></div>
                        <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                          Part Number:{" "}
                          <span className=" float-right text-black ">
                            {fence_product?.fence_product_part_number}
                          </span>{" "}
                        </h3>
                        <div className=" border border-white-vlight my-1 "></div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="   col-span-12 sm:col-span-6">
                  {/* crd started */}
                  <div className=" bg-white shadow-lg p-[20px] rounded-[8px] relative ">
                    <DialogBoxSolarProducts
                      products={solar_products}
                      init_products={products["solar-product"]}
                      onReceiveProducts={_onReceiveSolarProducts}
                      onAddBlankProduct={_onAddSolarBlankProduct}
                    />
                    {solar_products.length ? (
                      <button
                        onClick={editCurrentSolarProductTab}
                        className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
                      >
                        Edit
                      </button>
                    ) : null}
                    {/* inner stated */}
                    <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                      Solar Product(s)
                    </h2>

                    {!solar_products.length ? (
                      <p
                        style={{
                          textAlign: "center",
                          paddingTop: 20,
                          paddingBottom: 20,
                          fontSize: 12,
                          color: "gray",
                        }}
                      >
                        Solar products will display here
                      </p>
                    ) : (
                      <div className="tabs-container">
                        <div className="tabs">
                          {solar_products.length && solar_products.length > 1
                            ? solar_products.map((solar_product, i) => (
                                <button
                                  key={i}
                                  id={i}
                                  disabled={currentSolarProductTab === i}
                                  onClick={(e) => setCurrentSolarProductTab(i)}
                                >
                                  {solar_product.solar_product_name
                                    ? solar_product.solar_product_name
                                    : "Blank"}
                                </button>
                              ))
                            : null}
                        </div>
                        <div className="content">
                          {solar_products.map((solar_product, i) => (
                            <div key={i}>
                              {currentSolarProductTab === i ? (
                                <div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product Name:{" "}
                                    <span className=" float-right text-black  ">
                                      {solar_product?.solar_product_name}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product Quantity:{" "}
                                    <span className=" float-right text-black ">
                                      {solar_product?.solar_product_quantity}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Unit Capacity:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        solar_product?.solar_product_unit_capacity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product label:
                                    <span className=" float-right text-black ">
                                      {solar_product?.solar_product_label}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Comments or notes:{" "}
                                    <span className=" float-right text-black ">
                                      {solar_product?.solar_product_notes}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Total Capacity:
                                    <span className=" float-right text-black ">
                                      {
                                        solar_product?.solar_product_total_capacity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Manufacturer:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        solar_product?.solar_product_manufacturer
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Model:{" "}
                                    <span className=" float-right text-black ">
                                      {solar_product?.solar_product_model}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product Certifications:
                                    <span className=" float-right text-black ">
                                      {
                                        solar_product?.solar_product_certifications
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Degree Tilt:
                                    <span className=" float-right text-black ">
                                      {solar_product?.solar_product_tilt}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Azimuth:
                                    <span className=" float-right text-black ">
                                      {solar_product?.solar_product_azimuth}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Nominal Max Power (W):
                                    <span className=" float-right text-black ">
                                      {solar_product?.solar_product_pmax}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Voltage at Maximum Power (V):
                                    <span className=" float-right text-black ">
                                      {solar_product?.solar_product_vmp}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Current at Maximum Power (I):
                                    <span className=" float-right text-black ">
                                      {solar_product?.solar_product_imp}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Open Circuit Voltage (V):
                                    <span className=" float-right text-black ">
                                      {solar_product?.solar_product_voc}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Short Circuit Current (I):
                                    <span className=" float-right text-black ">
                                      {solar_product?.solar_product_isc}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Max Voc at Min Temp (C):
                                    <span className=" float-right text-black ">
                                      {
                                        solar_product?.solar_product_voc_min_temp
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Max Isc at Min Temp (C):
                                    <span className=" float-right text-black ">
                                      {
                                        solar_product?.solar_product_isc_min_temp
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className=" bg-white mt-2 shadow-lg p-[20px] rounded-[8px] relative ">
                    <DialogBoxBatteryProducts
                      products={battery_products}
                      init_products={products["battery-product"]}
                      onReceiveProducts={_onReceiveBatteryProducts}
                      onAddBlankProduct={_onAddBatteryBlankProduct}
                    />
                    {battery_products.length ? (
                      <button
                        onClick={editCurrentBatteryProductTab}
                        className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
                      >
                        Edit
                      </button>
                    ) : null}
                    {/* inner stated */}
                    <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                      Battery Product(s)
                    </h2>
                    {!battery_products.length ? (
                      <p
                        style={{
                          textAlign: "center",
                          paddingTop: 20,
                          paddingBottom: 20,
                          fontSize: 12,
                          color: "gray",
                        }}
                      >
                        Battery products will display here
                      </p>
                    ) : (
                      <div className="tabs-container">
                        <div className="tabs">
                          {battery_products.length &&
                          battery_products.length > 1
                            ? battery_products.map((battery_product, i) => (
                                <button
                                  key={i}
                                  id={i}
                                  disabled={currentBatteryProductTab === i}
                                  onClick={(e) =>
                                    setCurrentBatteryProductTab(i)
                                  }
                                >
                                  {battery_product.battery_product_name
                                    ? battery_product.battery_product_name
                                    : "Blank"}
                                </button>
                              ))
                            : null}
                        </div>
                        <div className="content">
                          {battery_products.map((battery_product, i) => (
                            <div key={i}>
                              {currentBatteryProductTab === i ? (
                                <div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product Name:{" "}
                                    <span className=" float-right text-black ">
                                      {battery_product?.battery_product_name}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product Quantity:
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_quantity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Unit Capacity:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_unit_capacity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product label:
                                    <span className=" float-right text-black ">
                                      {battery_product?.battery_product_label}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Comments or Notes:{" "}
                                    <span className=" float-right text-black ">
                                      {battery_product?.battery_product_notes}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Total Capacity:
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_total_capacity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Manufacturer:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_manufacturer
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Model:
                                    <span className=" float-right text-black ">
                                      {battery_product?.battery_product_model}
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Part Number:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_part_number
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Product Certifications:
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_certifications
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Battery Chemistry:{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_chemistry
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Dc Voltage - Nominal (V):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_dc_voltage
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Amp-Hours (Ah):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_amp_hours
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Storage Capacity (kWh):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_storage_capacity
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Max Continuous Discharge Rate (kW):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_max_discharge_rate
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Max Continuous Charge Rate (kW):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_max_charge_rate
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>

                                  <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                    Depth of Discharge (%):{" "}
                                    <span className=" float-right text-black ">
                                      {
                                        battery_product?.battery_product_discharge_depth
                                      }
                                    </span>{" "}
                                  </h3>
                                  <div className=" border border-white-vlight my-1 "></div>
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  {scenario?.model_renewable_fraction < 100 ? (
                    <div className=" bg-white mt-2 shadow-lg p-[20px] rounded-[8px] relative ">
                      <DialogBoxGeneratorProducts
                        products={generator_products}
                        init_products={products["generator-product"]}
                        onReceiveProducts={_onReceiveGeneratorProducts}
                        onAddBlankProduct={_onAddGeneratorBlankProduct}
                      />
                      {generator_products.length ? (
                        <button
                          onClick={editCurrentGeneratorProductTab}
                          className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
                        >
                          Edit
                        </button>
                      ) : null}
                      {/* inner stated */}
                      <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                        Generator Product(s)
                      </h2>
                      {!generator_products.length ? (
                        <p
                          style={{
                            textAlign: "center",
                            paddingTop: 20,
                            paddingBottom: 20,
                            fontSize: 12,
                            color: "gray",
                          }}
                        >
                          Generator products will display here
                        </p>
                      ) : (
                        <div className="tabs-container">
                          <div className="tabs">
                            {generator_products.length &&
                            generator_products.length > 1
                              ? generator_products.map(
                                  (generator_product, i) => (
                                    <button
                                      key={i}
                                      id={i}
                                      disabled={
                                        currentGeneratorProductTab === i
                                      }
                                      onClick={(e) =>
                                        setCurrentGeneratorProductTab(i)
                                      }
                                    >
                                      {generator_product.generator_product_name
                                        ? generator_product.generator_product_name
                                        : "Blank"}
                                    </button>
                                  )
                                )
                              : null}
                          </div>
                          <div className="content">
                            {generator_products.map((generator_product, i) => (
                              <div key={i}>
                                {currentGeneratorProductTab === i ? (
                                  <div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Product Name:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_product_name
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Product Quantity:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_product_quantity
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Unit Capacity:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_product_unit_capacity
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Product label:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_product_label
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Comments or Notes:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_product_notes
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Total Capacity:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_product_total_capacity
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Manufacturer:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_product_manufacturer
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Model:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_product_model
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Part Number:{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_product_part_number
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Product Certifications:
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_product_certifications
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Frequency (Hz):{" "}
                                      <span className=" float-right text-black ">
                                        {generator_product?.generator_frequency}
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Phase/Wire (A):
                                      <span className=" float-right text-black ">
                                        {generator_product?.generator_phase}
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Rated Voltage (V):{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_rated_voltage
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Real Standby Power (kW):
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_real_standby_power
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Apparent Standby Power (kVA):
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_apparent_standby_power
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Real Prime Power (kW):
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_real_prime_power
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Apparent Prime Power (kVA):{" "}
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_apparent_prime_power
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Power Factor:
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_power_factor
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Anticipated Efficiency (%):
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_efficiency
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Max Current (A):
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_max_current
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>

                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Continuous Current (A):
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_continuous_current
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                                      Breaker Current (A):
                                      <span className=" float-right text-black ">
                                        {
                                          generator_product?.generator_breaker_current
                                        }
                                      </span>{" "}
                                    </h3>
                                    <div className=" border border-white-vlight my-1 "></div>
                                  </div>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            {optimizationTab === "optimization-charts" ? (
              <>
                {/* 
                // <div className="grid grid-cols-12 mt-5 gap-4  ">
                //   <div className="col-span-12 sm:col-span-6">Optimization Charts 1</div>
                //   <div className="col-span-12 sm:col-span-6">Optimization Charts 2</div>
                // </div> 
                */}

                {barChartData.length <= 1 && lineChartData.length <= 1 ? (
                  <div style={{ textAlign: "center", marginBottom: 60 }}>
                    <h3 className=" text-black text-2x2 f-f-r mt-10 text-center ">
                      No homer file found!
                    </h3>
                    <button
                      onClick={() => _setOptimizationTab("system-sizing")}
                      className="border bg-primary w-[300px] text-center h-[50px] rounded-[15px] f-f-r text-sm text-white mt-5 "
                    >
                      Upload homer file
                    </button>
                  </div>
                ) : null}

                {barChartData.length > 1 ? (
                  <>
                    <h3 className=" text-black text-2xl f-f-r mt-10 text-center ">
                      Projected Monthly Energy Generation and Usage
                    </h3>
                    <div>
                      <Chart
                        // chartType="Bar" style={{  display: "flex", justifyContent: "center" }}
                        // width="1200px"
                        // height="400px"
                        chartType="ColumnChart"
                        width={"100%"}
                        height={400}
                        loader={<div>Loading Chart</div>}
                        data={barChartData}
                        //options={BarChartOptions}
                        options={{
                          chartArea: { width: "80%", height: "65%" },
                          hAxis: {
                            title: "Time - Month",
                            titleTextStyle: { italic: false },
                            textStyle: {
                              fontSize: 10,
                            },
                          },
                          vAxis: {
                            title: "Potential Energy Consumption (kWh)",
                            titleTextStyle: { italic: false },
                            textStyle: {
                              fontSize: 10,
                            },
                            minValue: 0,
                          },
                          legend: {
                            position: "top",
                            alignment: "center",
                            textStyle: {
                              fontSize: 10,
                            },
                            maxLines: 2,
                          },
                        }}
                      />
                    </div>
                    <h3 className=" text-slate-600 text-2x2 f-f-r mb-10 text-center ">
                      Graph showing the projected monthly energy generation and
                      consumption
                    </h3>
                  </>
                ) : null}

                {lineChartData.length > 1 ? (
                  <>
                    <h3 className=" text-black text-2xl f-f-r mt-10 text-center ">
                      Projected Hourly Load Summary
                    </h3>
                    <div>
                      <Chart
                        chartType="LineChart"
                        // width="1400px"
                        // height="400px"
                        width={"100%"}
                        height={400}
                        loader={<div>Loading Chart</div>}
                        data={lineChartData}
                        // options={LineChartOptions}
                        options={{
                          chartArea: { width: "80%", height: "70%" },
                          hAxis: {
                            title: "Time - Hour",
                            titleTextStyle: { italic: false },
                            textStyle: {
                              fontSize: 10,
                            },
                            showTextEvery: 2,
                          },
                          vAxis: {
                            title: "Potential Annual Consumption (kW)",
                            titleTextStyle: { italic: false },
                            textStyle: {
                              fontSize: 10,
                            },
                            minValue: 0,
                          },
                          legend: {
                            position: "top",
                            alignment: "center",
                            textStyle: {
                              fontSize: 10,
                            },
                            maxLines: 2,
                          },
                          series: {
                            0: { curveType: "function" },
                            1: { curveType: "function" },
                            2: { curveType: "function" },
                            3: { curveType: "function" },
                          },
                        }}
                      />
                    </div>
                    <h3 className=" text-slate-600 text-2x2 f-f-r mb-10 text-center ">
                      Graph showing the projected hourly load summary
                    </h3>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>

      <Transition.Root show={editSiteInformationModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditSiteInformationModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Site Information
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Average PV Tilt
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) => setPVTilt(e.target.value)}
                                    value={PVTilt}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Average PV Azimuth
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setPVAzimuth(e.target.value)
                                    }
                                    value={PVAzimuth}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditSiteInformation ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editSiteInformation}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditSiteInformationModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editOptimizationSummaryModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditOptimizationSummaryModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Optimization Summary
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Average PV Tilt
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) => setPVTilt(e.target.value)}
                                    value={PVTilt}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Average PV Azimuth
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setPVAzimuth(e.target.value)
                                    }
                                    value={PVAzimuth}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Solar PV Capacity (kW)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setSolarPVCapacity(e.target.value)
                                    }
                                    value={solarPVCapacity}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Battery Storage Capacity (kWh)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setBatteryStorageCapacity(e.target.value)
                                    }
                                    value={batteryStorageCapacity}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Battery Inverter Capacity (kW)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setBatteryInverterCapacity(e.target.value)
                                    }
                                    value={batteryInverterCapacity}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Generator Quantity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setGeneratorQuantity(e.target.value)
                                    }
                                    value={generatorQuantity}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Generator Size (kW)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setGeneratorSize(e.target.value)
                                    }
                                    value={generatorSize}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Renewable Fraction (%)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setRenewableFraction(e.target.value)
                                    }
                                    value={renewableFraction}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Generator Runtime (hours)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setGeneratorRuntime(e.target.value)
                                    }
                                    value={generatorRuntime}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Fuel Consumption (gallons)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setFuelConsumption(e.target.value)
                                    }
                                    value={fuelConsumption}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Fuel Tank Size (gallons)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setFuelTankSize(e.target.value)
                                    }
                                    value={fuelTankSize}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditOptimizationSummary ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editOptimizationSummary}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditOptimizationSummaryModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editInverterProductModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditInverterProductModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Inverter Product
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Name *:
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_name"
                                    value={
                                      inverterProduct?.inverter_product_name
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Quantity *:
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_quantity"
                                    value={
                                      inverterProduct?.inverter_product_quantity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Unit Capacity *:
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_unit_capacity"
                                    value={
                                      inverterProduct?.inverter_product_unit_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Label
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_label"
                                    value={
                                      inverterProduct?.inverter_product_label
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Comments or Notes
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_notes"
                                    value={
                                      inverterProduct?.inverter_product_notes
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Total Capacity (unit capacity * quantity)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    disabled
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_total_capacity"
                                    value={
                                      inverterProduct?.inverter_product_total_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Manufacturer
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_manufacturer"
                                    value={
                                      inverterProduct?.inverter_product_manufacturer
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Model
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_model"
                                    value={
                                      inverterProduct?.inverter_product_model
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Part Number
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_part_number"
                                    value={
                                      inverterProduct?.inverter_product_part_number
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Certifications
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_certifications"
                                    value={
                                      inverterProduct?.inverter_product_certifications
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product output Power (W)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_output_power"
                                    value={
                                      inverterProduct?.inverter_product_output_power
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div> */}

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product output Voltage (V)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_output_voltage"
                                    value={
                                      inverterProduct?.inverter_product_output_voltage
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product output Frequency (Hz)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_output_frequency"
                                    value={
                                      inverterProduct?.inverter_product_output_frequency
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product output Power (W)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_output_power"
                                    value={
                                      inverterProduct?.inverter_product_output_power
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Max Input DC Current (A)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_max_input_current"
                                    value={
                                      inverterProduct?.inverter_product_max_input_current
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Max output Charge Current (A)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeInverterProduct}
                                    name="inverter_product_max_output_current"
                                    value={
                                      inverterProduct?.inverter_product_max_output_current
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditInverterProduct ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={() => editInverterProduct()}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditInverterProductModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editGeneratorProductModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditGeneratorProductModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Generator Product
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Name
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_product_name"
                                    value={
                                      generatorProduct?.generator_product_name
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Quantity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_product_quantity"
                                    value={
                                      generatorProduct?.generator_product_quantity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Unit Capacity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_product_unit_capacity"
                                    value={
                                      generatorProduct?.generator_product_unit_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Label
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_product_label"
                                    value={
                                      generatorProduct?.generator_product_label
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Comments or Notes
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_product_notes"
                                    value={
                                      generatorProduct?.generator_product_notes
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Total Capacity (unit capacity * quantity)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    disabled
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_product_total_capacity"
                                    value={
                                      generatorProduct?.generator_product_total_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Manufacturer
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_product_manufacturer"
                                    value={
                                      generatorProduct?.generator_product_manufacturer
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Model
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_product_model"
                                    value={
                                      generatorProduct?.generator_product_model
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Part Number
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_product_part_number"
                                    value={
                                      generatorProduct?.generator_product_part_number
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Certifications
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_product_certifications"
                                    value={
                                      generatorProduct?.generator_product_certifications
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Frequency (Hz)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_frequency"
                                    value={
                                      generatorProduct?.generator_frequency
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Phase/Wire (A)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_phase"
                                    value={generatorProduct?.generator_phase}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Rated Voltage (V)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_rated_voltage"
                                    value={
                                      generatorProduct?.generator_rated_voltage
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Real Standby Power (kW)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_real_standby_power"
                                    value={
                                      generatorProduct?.generator_real_standby_power
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Apparent Standby Power (kVA)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_apparent_standby_power"
                                    value={
                                      generatorProduct?.generator_apparent_standby_power
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Real Prime Power (kW)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_real_prime_power"
                                    value={
                                      generatorProduct?.generator_real_prime_power
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Apparent Prime Power (kVA)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_apparent_prime_power"
                                    value={
                                      generatorProduct?.generator_apparent_prime_power
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Power Factor
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_power_factor"
                                    value={
                                      generatorProduct?.generator_power_factor
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Anticipated Efficiency (%)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_efficiency"
                                    value={
                                      generatorProduct?.generator_efficiency
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Max Current (A)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_max_current"
                                    value={
                                      generatorProduct?.generator_max_current
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Continuous Current (A)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_continuous_current"
                                    value={
                                      generatorProduct?.generator_continuous_current
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Breaker Current (A)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeGeneratorProduct}
                                    name="generator_breaker_current"
                                    value={
                                      generatorProduct?.generator_breaker_current
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditGeneratorProduct ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={() => editGeneratorProduct()}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditGeneratorProductModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editFenceProductModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditFenceProductModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Fence Product
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Fence Height (ft)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeFenceProduct}
                                    name="fence_product_height"
                                    value={fenceProduct?.fence_product_height}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Fence Type
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeFenceProduct}
                                    name="fence_product_type"
                                    value={fenceProduct?.fence_product_type}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Fence Topper
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeFenceProduct}
                                    name="fence_product_topper"
                                    value={fenceProduct?.fence_product_topper}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Fence Sound Attenuation
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeFenceProduct}
                                    name="fence_product_sound_attenuation"
                                    value={
                                      fenceProduct?.fence_product_sound_attenuation
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Sound Attenuation Product Spec
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeFenceProduct}
                                    name="fence_product_attenuation_spec"
                                    value={
                                      fenceProduct?.fence_product_attenuation_spec
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Manufacturer
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeFenceProduct}
                                    name="fence_product_manufacturer"
                                    value={
                                      fenceProduct?.fence_product_manufacturer
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Model
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeFenceProduct}
                                    name="fence_product_model"
                                    value={fenceProduct?.fence_product_model}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Part Number
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeFenceProduct}
                                    name="fence_product_part_number"
                                    value={
                                      fenceProduct?.fence_product_part_number
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditFenceProduct ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editFenceProduct}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditFenceProductModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editSolarProductModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditSolarProductModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Solar Product
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Name
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_name"
                                    value={solarProduct?.solar_product_name}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Quantity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_quantity"
                                    value={solarProduct?.solar_product_quantity}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Unit Capacity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_unit_capacity"
                                    value={
                                      solarProduct?.solar_product_unit_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Label
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_label"
                                    value={solarProduct?.solar_product_label}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Comments or notes
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_notes"
                                    value={solarProduct?.solar_product_notes}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Total Capacity (unit capacity * quantity)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    disabled
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_total_capacity"
                                    value={
                                      solarProduct?.solar_product_total_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Manufacturer
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_manufacturer"
                                    value={
                                      solarProduct?.solar_product_manufacturer
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Model
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_model"
                                    value={solarProduct?.solar_product_model}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Part Number
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_part_number"
                                    value={
                                      solarProduct?.solar_product_part_number
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Certifications
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_certifications"
                                    value={
                                      solarProduct?.solar_product_certifications
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Degree Tilt
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_tilt"
                                    value={solarProduct?.solar_product_tilt}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Azimuth
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_azimuth"
                                    value={solarProduct?.solar_product_azimuth}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  PV String Length
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_string_length"
                                    value={
                                      solarProduct?.solar_product_string_length
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Number of PV strings
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_string_quantity"
                                    value={
                                      solarProduct?.solar_product_string_quantity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Nominal Max Power (W)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_pmax"
                                    value={solarProduct?.solar_product_pmax}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Voltage at Maximum Power (V)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_vmp"
                                    value={solarProduct?.solar_product_vmp}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Current at Maximum Power (I)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_imp"
                                    value={solarProduct?.solar_product_imp}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Open Circuit Voltage (V)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_voc"
                                    value={solarProduct?.solar_product_voc}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Short Circuit Current (I)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_isc"
                                    value={solarProduct?.solar_product_isc}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Max Voc at Min Temp (C)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_voc_min_temp"
                                    value={
                                      solarProduct?.solar_product_voc_min_temp
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Module VMP
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeSolarProduct}
                                    name="solar_product_isc_min_temp"
                                    value={
                                      solarProduct?.solar_product_isc_min_temp
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditSolarProduct ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={() => editSolarProduct()}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditSolarProductModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editBatteryProductModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditBatteryProductModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Battery Product
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Name
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_name"
                                    value={batteryProduct?.battery_product_name}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Quantity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_quantity"
                                    value={
                                      batteryProduct?.battery_product_quantity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Unit Capacity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_unit_capacity"
                                    value={
                                      batteryProduct?.battery_product_unit_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Label
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_label"
                                    value={
                                      batteryProduct?.battery_product_label
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Comments or Notes
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_notes"
                                    value={
                                      batteryProduct?.battery_product_notes
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Total Capacity (unit capacity * quantity)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    disabled
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_total_capacity"
                                    value={
                                      batteryProduct?.battery_product_total_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Manufacturer
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_manufacturer"
                                    value={
                                      batteryProduct?.battery_product_manufacturer
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Model
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_model"
                                    value={
                                      batteryProduct?.battery_product_model
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Part Number
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_part_number"
                                    value={
                                      batteryProduct?.battery_product_part_number
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Certifications
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_certifications"
                                    value={
                                      batteryProduct?.battery_product_certifications
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Battery Chemistry
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_chemistry"
                                    value={
                                      batteryProduct?.battery_product_chemistry
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Dc Voltage - Nominal (V)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_dc_voltage"
                                    value={
                                      batteryProduct?.battery_product_dc_voltage
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Amp-Hours (Ah)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_amp_hours"
                                    value={
                                      batteryProduct?.battery_product_amp_hours
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Storage Capacity (kWh)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_storage_capacity"
                                    value={
                                      batteryProduct?.battery_product_storage_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Max Continuous Discharge Rate (kW)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_max_discharge_rate"
                                    value={
                                      batteryProduct?.battery_product_max_discharge_rate
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Max Continuous Charge Rate (kW)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_max_charge_rate"
                                    value={
                                      batteryProduct?.battery_product_max_charge_rate
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Depth of Discharge (%)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeBatteryProduct}
                                    name="battery_product_discharge_depth"
                                    value={
                                      batteryProduct?.battery_product_discharge_depth
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditBatteryProduct ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={() => editBatteryProduct()}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditBatteryProductModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editMountingProductModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditMountingProductModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Mounting Product
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Name
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeMountingProduct}
                                    name="mounting_product_name"
                                    value={
                                      mountingProduct?.mounting_product_name
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Quantity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeMountingProduct}
                                    name="mounting_product_quantity"
                                    value={
                                      mountingProduct?.mounting_product_quantity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Unit Capacity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeMountingProduct}
                                    name="mounting_product_unit_capacity"
                                    value={
                                      mountingProduct?.mounting_product_unit_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Label
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeMountingProduct}
                                    name="mounting_product_label"
                                    value={
                                      mountingProduct?.mounting_product_label
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Comments or Notes
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeMountingProduct}
                                    name="mounting_product_notes"
                                    value={
                                      mountingProduct?.mounting_product_notes
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Total Capacity (unit capacity * quantity)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    disabled
                                    onChange={_onChangeMountingProduct}
                                    name="mounting_product_total_capacity"
                                    value={
                                      mountingProduct?.mounting_product_total_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Manufacturer
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeMountingProduct}
                                    name="mounting_product_manufacturer"
                                    value={
                                      mountingProduct?.mounting_product_manufacturer
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Model
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeMountingProduct}
                                    name="mounting_product_model"
                                    value={
                                      mountingProduct?.mounting_product_model
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Part Number
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeMountingProduct}
                                    name="mounting_product_part_number"
                                    value={
                                      mountingProduct?.mounting_product_part_number
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Certifications
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeMountingProduct}
                                    name="mounting_product_certifications"
                                    value={
                                      mountingProduct?.mounting_product_certifications
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Clear Height Minimum (Ft)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeMountingProduct}
                                    name="mounting_min_clear_height"
                                    value={
                                      mountingProduct?.mounting_min_clear_height
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditMountingProduct ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={() => editMountingProduct()}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditMountingProductModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editChargeControllerProductModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditChargeControllerProductModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Charge Controller Product
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Name
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_product_name"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_product_name
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Quantity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_product_quantity"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_product_quantity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Unit Capacity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_product_unit_capacity"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_product_unit_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Label
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_product_label"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_product_label
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Comments or Notes
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_product_notes"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_product_notes
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Total Capacity (unit capacity * quantity)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    disabled
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_product_total_capacity"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_product_total_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Manufacturer
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_product_manufacturer"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_product_manufacturer
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Model
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_product_model"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_product_model
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Part Number
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_product_part_number"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_product_part_number
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Product Certifications
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_product_certifications"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_product_certifications
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Max PV Capacity
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_max_pv_capacity"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_max_pv_capacity
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Max String Length
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={_onChangeChargeControllerProduct}
                                    name="chargecontroller_max_string_length"
                                    value={
                                      chargecontrollerProduct?.chargecontroller_max_string_length
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditChargeControllerProduct ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={() => editChargeControllerProduct()}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditChargeControllerProductModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={fileUploadInstructionsModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setFileUploadInstructionsModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        {/* <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Optimization Summary
                        </Dialog.Title> */}
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-lg font-medium leading-6 text-gray-900">
                                  To ensure accurate extraction of optimization
                                  result from your HOMER file, rename the
                                  components using the following mapping before
                                  uploading;
                                </label>
                                <div className="mt-5 pl-10 mb-5">
                                  <ul className="list-disc">
                                    <li>PV components {"->"} Solar </li>
                                    <li>
                                      Generator component {"->"} Generator{" "}
                                    </li>
                                    <li>Converter component {"->"} Inverter</li>
                                    <li>Storage component {"->"} Battery</li>
                                  </ul>
                                </div>

                                <label className="block text-lg font-medium leading-6 text-gray-900">
                                  This step helps avoid confusion, especially
                                  when components from the same manufacturer
                                  have similar names.
                                </label>
                              </div>
                            </div>
                          </form>

                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setFileUploadInstructionsModel(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
});

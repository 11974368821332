import React from "react";
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function MyDropdown({ columns, onEditColumns }) {
  return (
    <>
      <Menu>
        <Menu.Button className=" cursor-pointer">
          <button className=" f-f-r text-lg text-black w-[169px] h-[44px] shadow-lg bg-white rounded-[4px]  ">
            <ul className=" inline-flex ">
              <li className=" mt-2 ">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.0002 2.66667H9.3335"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.66667 2.66667H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 8H8"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.33333 8H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.9998 13.3333H10.6665"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 13.3333H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.3335 1.33333V4"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.3335 6.66667V9.33333"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.6665 12V14.6667"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </li>
              <li className=" ml-3 mt-1 ">Edit Columns</li>
            </ul>
          </button>
        </Menu.Button>
        <Menu.Items>
          <div
            id="dropdown"
            className="z-10 px-3 absolute w-[243px] bg-white divide-y divide-gray-100 rounded-lg shadow overflow-y-scroll h-[300px]"
          >
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <li className=" profilebrdr2">
                {/* inner ul started */}
                <ul
                  className=" inline-flex mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "project_name",
                          value: !columns.project_name,
                        })
                      }
                      checked={columns.project_name}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Project Name
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>
              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex cursor-pointer mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "project_code", value: !columns.project_code })
                      }
                      checked={columns.project_code}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r">Project Code</h2>
                  </li>
                </ul>
              </li>
              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "project_notes",
                          value: !columns.project_notes,
                        })
                      }
                      checked={columns.project_notes}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Project Notes
                    </h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "project_summary",
                          value: !columns.project_summary,
                        })
                      }
                      checked={columns.project_summary}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Project Summary</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "project_type",
                          value: !columns.project_type,
                        })
                      }
                      checked={columns.project_type}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Project Type
                    </h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "status", value: !columns.status })
                      }
                      checked={columns.status}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Status</h2>
                  </li>
                </ul>
              </li>

               
              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "utility", value: !columns.utility })
                      }
                      checked={columns.utility}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Client Name
                    </h2>
                  </li>
                </ul>
              </li> 

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "project_budget", value: !columns.project_budget })
                      }
                      checked={columns.project_budget}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Project Budget
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "customer_type", value: !columns.customer_type })
                      }
                      checked={columns.customer_type}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Customer Type
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "contract_value", value: !columns.contract_value })
                      }
                      checked={columns.contract_value}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Contract Value
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "contract_number", value: !columns.contract_number })
                      }
                      checked={columns.contract_number}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Contract Number
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "contract_gfc_date", value: !columns.contract_gfc_date })
                      }
                      checked={columns.contract_gfc_date}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Contract GFC Date
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "contract_date", value: !columns.contract_date })
                      }
                      checked={columns.contract_date}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Contract Date
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              {/* <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "project_contacts", value: !columns.project_contacts })
                      }
                      checked={columns.project_contacts}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Project Contacts
                    </h2>
                  </li>
                </ul>
              </li> */}

              {/* <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "project_services", value: !columns.project_services })
                      }
                      checked={columns.project_services}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Project Services
                    </h2>
                  </li>
                </ul>
              </li> */}

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "pm_number", value: !columns.pm_number })
                      }
                      checked={columns.pm_number}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      PM Number
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "po_number", value: !columns.po_number })
                      }
                      checked={columns.po_number}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      PO Number
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>
              
            </ul>
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    params_projects  : [],
    params_sites     : [],
    params_scenarios : [],
    uploading        : false,
    uploadProgress   : 0,
    uploadReport     : "",
    homer            : null
}

export const helperSlice = createSlice({
    name: 'helper',
    initialState,
    reducers: {
        setParamsProjects: (state, action) => {
            state.params_projects = action.payload;
        },
        setParamsSites: (state, action) => {
            state.params_sites = action.payload;
        },
        setParamsScenarios: (state, action) => {
            state.params_scenarios = action.payload;
        },
        setUploading: (state, action) => {
            state.uploading = action.payload;
        },
        setUploadProgress: (state, action) => {
            state.uploadProgress = action.payload;
        },
        setUploadReport: (state, action) => {
            state.uploadReport = action.payload;
        },
        setHomer: (state, action) => {
            state.homer = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setParamsProjects, setParamsSites, setParamsScenarios, setUploading, setUploadProgress, setUploadReport, setHomer } = helperSlice.actions

export default helperSlice.reducer;